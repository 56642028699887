import { Box } from '@chakra-ui/react';
import React, {
  FunctionComponent as FC,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { MCustomSelectProps } from '~app/types/mCustomSelectTypes';
import { ensureArray } from '../../../api/utils';
import { MCustomSelect } from './MCustomSelect';

export interface MCustomMultiSelectRef {
  updateItem(item: any, newValue: boolean): void;
  updateItems(items: any[]): void;
  clearAll(): void;
}

interface MCustomMultiSelectProps
  extends Omit<MCustomSelectProps, 'onChange' | 'value'> {
  value?: any;
  selectionLimit?: number;
  inputWidth?: string;
  showTagsInInput?: boolean;
  showCheckCount?: boolean;
  onChange?: null | ((val: any[]) => void);
  onRemove?: null | ((val: any, allItems?: any[]) => void);
  onAdd?: null | ((val: any, allItems?: any[]) => void);
}

export const MCustomMultiSelect: FC<MCustomMultiSelectProps> = React.forwardRef<
  any,
  MCustomMultiSelectProps
>(
  (
    {
      value: propValue,
      onChange,
      selectionLimit,
      returnItem,
      itemTitle,
      itemValue,
      inputWidth,
      showTagsInInput,
      showCheckCount,
      onRemove,
      onAdd,
      ...selectProps
    }: MCustomMultiSelectProps,
    ref,
  ) => {
    const [value, setValue] = useState<unknown[]>(() => ensureArray(propValue));

    useEffect(() => {
      setValue(ensureArray(propValue));
    }, [propValue]);

    useImperativeHandle(
      ref,
      (): MCustomMultiSelectRef => ({
        updateItem: (item: any, newValue: boolean) => {
          if (newValue) {
            setValue(
              value
                .filter((existingItem) => existingItem !== item)
                .concat(item),
            );
          } else {
            setValue(value.filter((existingItem) => existingItem !== item));
          }
        },
        updateItems: (items: any[]) => {
          setValue(items);
        },
        clearAll: () => {
          setValue([]);
        },
      }),
    );

    const onCheckedStateChange = (val: any, event: any) => {
      let existingIdx = -1;
      if (returnItem) {
        existingIdx = value.findIndex(
          (item: any) => item[itemValue as string] === val[itemValue as string],
        );
      } else {
        existingIdx = value.findIndex((item: any) => item === val);
      }
      event.target.checked
        ? internalHandleAdd(val, existingIdx)
        : internalHandleRemove(val, existingIdx);
    };

    const internalHandleAdd = (val: any, existingIdx: number) => {
      let newVal = value;
      if (existingIdx < 0) {
        newVal = value.concat(val);
        setValue(newVal);
      }
      onAdd && onAdd(val, newVal);
    };

    const internalHandleRemove = (val: any, existingIdx: number) => {
      const newVal = [...value];
      if (existingIdx >= 0) {
        newVal.splice(existingIdx, 1);
        setValue(newVal);
      }
      onRemove && onRemove(val, newVal);
    };

    const handleOpenStateChange = (isOpen: boolean) => {
      !isOpen && setValue(ensureArray(propValue));
      selectProps.onOpenStateChange && selectProps.onOpenStateChange(isOpen);
    };

    const showContentInInput = !!showTagsInInput;

    return (
      <Box w="full" position="relative" mt={selectProps.mt ?? 2}>
        <MCustomSelect
          ref={ref}
          value={value}
          checkboxDisplay
          showCheckCount={showCheckCount}
          returnItem={returnItem}
          itemTitle={itemTitle}
          itemValue={itemValue}
          w={inputWidth}
          {...selectProps}
          onChangeCheckbox={onCheckedStateChange}
          showContentInInput={showContentInInput}
          onMultiSelectSubmit={() => onChange && onChange(value)}
          onOpenStateChange={handleOpenStateChange}
          clearAllCheckValues={() => setValue([])}
        />
      </Box>
    );
  },
);

MCustomMultiSelect.defaultProps = {
  selectionLimit: -1,
  value: [],
  onChange: null,
  inputWidth: 'full',
  showTagsInInput: false,
  onRemove: null,
  onAdd: null,
};

export default MCustomMultiSelect;
