import {
  Auth0Provider as Auth0ProviderReactWrapper,
  RedirectLoginOptions,
  useAuth0,
} from '@auth0/auth0-react';
import { User } from '@auth0/auth0-spa-js';
import { useQuery } from '@tanstack/react-query';
import jwt_decode from 'jwt-decode';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  accessTokenState,
  currentTenantState,
  currentUserIdState,
  refreshAccessTokenState,
  tempRedirectInfoState,
} from '~app/store/global.store';
import { ITenant, IUser } from '~app/types';
import { useCurrentUser } from '~hooks';
import { AUTH0, KEYS } from '../config';
import { onHeapIdentify } from './heap';
import { initLaunchDarklyContext } from './launchDarkly';

// eslint-disable-next-line no-restricted-imports
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { logger } from './logger';

export const Auth0ProviderReact = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const setRedirectInfo = useSetRecoilState(tempRedirectInfoState);

  const onRedirectCallback = useCallback((appState: any) => {
    if (appState && appState.returnTo) {
      // When auth0 app state contains the redirect info then save it in redirectUrl
      // This will be used on /select-tenant route.
      setRedirectInfo({
        redirectUrl: appState.returnTo,
      });
    }
  }, []);

  return (
    <Auth0ProviderReactWrapper
      domain={AUTH0.domain}
      clientId={AUTH0.clientId}
      redirectUri={AUTH0.callbackUrl}
      audience={AUTH0.apiAudience}
      useRefreshTokens
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0ProviderReactWrapper>
  );
};

interface AccessTokenUpdateResponse {
  updatedAccessToken: string;
  updatedUserId: string;
}

type AuthProviderType = {
  // From auth0
  isAuthenticated: boolean;
  user: User;
  isLoading: boolean;
  isLDLoading: boolean;
  loginWithRedirect:
    | (() => void)
    | ((options?: RedirectLoginOptions | undefined) => Promise<void>);
  logoutAuth0: () => void;

  // Auth state related
  setCurrentTenant: (tenant: ITenant | null) => void;
  currentTenant?: null | ITenant;
  accessToken: string;
  tenantId: string;
  userId: string;

  // Access token related
  handleAccessToken: (token: string) => {
    updatedUserId?: string;
    updatedAccessToken: string;
  };
  getAndHandleAccessTokenSilently: () => Promise<AccessTokenUpdateResponse>;
  getTenantIdsFromAccessToken: (accessTokenP?: string) => any[];
  getDecodedToken: (accessTokenP?: string) => any;

  selectTenant: (tenant: ITenant | null) => void;

  // currentTenantUser & permission related
  currentTenantUser: IUser;
  currentTenantUserHasRole: (roles: string[]) => boolean;
  currentTenantUserHasSpecificRole: (role: string) => boolean;
  isTokenRefreshed: boolean;
  error: any;
};

export const useAuthProvider = () => {
  const auth0 = useAuth0();
  const {
    logout,
    getAccessTokenSilently,
    isAuthenticated,
    loginWithRedirect,
    user,
    isLoading,
  } = auth0;
  const [currentTenant, setCurrentTenant] = useRecoilState(currentTenantState);
  const tenantId = currentTenant?.id || '';
  const [prevTenantId, setPrevTenantId] = useState(tenantId);
  const [userId, setUserId] = useRecoilState(currentUserIdState);
  const [accessToken, setAccessToken] = useRecoilState(accessTokenState);
  const setRefreshTokenFn = useSetRecoilState(refreshAccessTokenState);
  const [isTokenRefreshed, setIsTokenRefreshed] = useState(false);
  const [isLDLoading, setIsLDLoading] = useState(true);
  const {
    error,
    currentTenantUser,
    currentTenantUserHasRole,
    currentTenantUserHasSpecificRole,
  } = useCurrentUser(isTokenRefreshed, tenantId, userId);
  const ldClient = useLDClient();

  // Store refresh function in store so that axios has access to call it
  const storeRefreshAccessToken = useCallback(() => {
    return getAccessTokenSilently({
      scope: 'openid profile email offline_access',
      ignoreCache: true,
    });
  }, [getAccessTokenSilently]);

  useEffect(() => {
    setRefreshTokenFn(() => storeRefreshAccessToken);
  }, [storeRefreshAccessToken, setRefreshTokenFn]);

  useEffect(() => {
    if (tenantId && tenantId !== prevTenantId) {
      setPrevTenantId(tenantId);
    }
  }, [prevTenantId, tenantId]);

  useEffect(() => {
    (async () => {
      if (currentTenantUser && currentTenant) {
        try {
          setIsLDLoading(true);
          await initLaunchDarklyContext(
            ldClient,
            currentTenantUser,
            currentTenant,
          );
          setIsLDLoading(false);
          onHeapIdentify(currentTenantUser.email, {
            id: currentTenantUser.id!,
            name: currentTenantUser.name,
            tenantId: currentTenant.id!,
            tenantName: currentTenant.description!,
          });
        } catch (err) {
          // Even if LaunchDarkly fails, we should not block the app.
          logger.log(err, 'Error in initLaunchDarklyContext');
          setIsLDLoading(false);
        }
      }
    })();
  }, [currentTenantUser, ldClient, currentTenant]);

  // Ensure access token gets refreshed on initial load
  useQuery(['auth', 'getAndHandleAccessTokenSilently', currentTenant?.id], {
    queryFn: () => getAndHandleAccessTokenSilently(),
    onSuccess: (data) => setIsTokenRefreshed(true),
    enabled: isAuthenticated,
    refetchOnWindowFocus: false,
  });

  const handleAccessToken = (accessTokenP: string) => {
    let updatedUserId = '';
    setAccessToken(accessTokenP);

    const jwtDecoded: any = jwt_decode(accessTokenP);

    if (jwtDecoded[KEYS.userKey]) {
      updatedUserId = jwtDecoded[KEYS.userKey];
      setUserId(updatedUserId);
    }

    return { updatedUserId, updatedAccessToken: accessTokenP };
  };

  const selectTenant = (tenant: ITenant | null) => {
    setCurrentTenant(tenant);
  };

  const getDecodedToken = (accessTokenP?: string) => {
    try {
      const jwtDecoded: any = jwt_decode(accessTokenP || accessToken);
      return jwtDecoded;
    } catch (err) {
      return null;
    }
  };

  const getTenantIdsFromAccessToken = (accessTokenP?: string) => {
    try {
      const jwtDecoded: any = jwt_decode(accessTokenP || accessToken);
      return jwtDecoded[KEYS.tenantKey] || [];
    } catch (err) {
      return [];
    }
  };

  const logoutAuth0 = async () => {
    setCurrentTenant(null);
    logout({
      returnTo: AUTH0.returnTo,
    });
  };

  const getAndHandleAccessTokenSilently =
    async (): Promise<AccessTokenUpdateResponse> => {
      const newToken = await getAccessTokenSilently({
        scope: 'openid profile email offline_access',
        ignoreCache: true,
      });
      return handleAccessToken(newToken);
    };

  const state: AuthProviderType = {
    // From auth0
    isAuthenticated,
    user: user!,
    isLDLoading: isLDLoading,
    isLoading: isLoading,
    loginWithRedirect,
    logoutAuth0,

    // Auth state related
    setCurrentTenant,
    currentTenant,
    accessToken,
    tenantId,
    userId,

    // Access token related
    handleAccessToken,
    getAndHandleAccessTokenSilently,
    getTenantIdsFromAccessToken,
    getDecodedToken,

    selectTenant,

    // currentTenantUser & permission related
    currentTenantUser: currentTenantUser as any,
    currentTenantUserHasRole,
    currentTenantUserHasSpecificRole,
    isTokenRefreshed,
    error,
  };
  return state;
};

const defaultAuthContext = {};

const AuthContext = createContext<AuthProviderType>(defaultAuthContext as any);

export const useAuth = () => useContext(AuthContext);

export const Auth0Provider = ({ children }: { children: any }) => {
  const auth = useAuthProvider();
  return (
    <AuthContext.Provider value={auth as any}>{children}</AuthContext.Provider>
  );
};

export const MAuth0Wrapper = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  return (
    <Auth0ProviderReact>
      <Auth0Provider>{children}</Auth0Provider>
    </Auth0ProviderReact>
  );
};
