import { useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import {
  MAccordion,
  MBox,
  MButton,
  MIcon,
  MPageLoader,
} from '~app/components/Monetize';
import { useDocumentHead } from '~services/documentHead';
import { useQuoteContext } from '../../Quote/quoteContext';
import { QuoteInternalBilling } from './QuoteInternalBilling';
import { QuoteInternalExtraInfo } from './QuoteInternalExtraInfo';
import { QuoteInternalOfferings } from './QuoteInternalOfferings';
import { QuoteInternalSubmit } from './QuoteInternalSubmit';
import { QuoteBasic } from './QuoteBasic';
import { QuoteStatusEnum } from '~app/types';
import { MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';

interface QuotePageInternalProps {
  setContactDrawerOpen: (val: boolean) => void;
  manualAcceptanceModalState: ReturnType<typeof useDisclosure>;
}
const ACCORDION_EXPAND_ALL = [0, 1, 2];

export const QuotePageInternal = ({
  setContactDrawerOpen,
  manualAcceptanceModalState,
}: QuotePageInternalProps) => {
  const { quoteData, handleSubmitButton } = useQuoteContext();
  const { quote } = quoteData;
  const { setDocTitle } = useDocumentHead();
  const [openAccordionIndex, setOpenAccordionIndex] = useState<number[]>([0]);

  const isAccordionExpanded =
    openAccordionIndex.length === ACCORDION_EXPAND_ALL.length;
  const onToggleAccordion = () => {
    if (isAccordionExpanded) {
      setOpenAccordionIndex([]);
    } else {
      setOpenAccordionIndex(ACCORDION_EXPAND_ALL);
    }
  };

  useEffect(() => {
    setDocTitle(
      quote?.id &&
        `Edit Quote${quote.description ? ` - ${quote.description}` : ''}`,
    );
  }, [quote?.description, quote?.id, setDocTitle]);

  if (!quote) {
    return <MPageLoader />;
  }

  return (
    <form onSubmit={handleSubmitButton} style={{ width: '100%' }}>
      <QuoteBasic
        onOpenContactDrawer={() => {
          setContactDrawerOpen(true);
        }}
      />

      <MBox mt="6" mb="2" display="flex" justifyContent="flex-end">
        <MButton variant="tertiary" onClick={onToggleAccordion}>
          {isAccordionExpanded ? 'Collapse' : 'Expand'} All
          <MIcon
            as={isAccordionExpanded ? MdArrowDropUp : MdArrowDropDown}
            boxSize="5"
            ml="2"
          />
        </MButton>
      </MBox>

      <MBox
        borderColor="tGray.lightPurple"
        borderRadius="0.25rem"
        borderWidth="1px"
        overflow="hidden"
      >
        <MAccordion
          allowMultiple
          variant="primary"
          index={openAccordionIndex}
          onChange={(indexes: number[]) => setOpenAccordionIndex(indexes)}
        >
          <QuoteInternalOfferings />
          <QuoteInternalExtraInfo />
          <QuoteInternalBilling
            onOpenContactDrawer={() => setContactDrawerOpen(true)}
          />
        </MAccordion>

        {quote?.status === QuoteStatusEnum.DRAFT && (
          <MAccordion
            variant="primary"
            defaultIndex={[0]}
            index={[0]}
            allowToggle={false}
          >
            <QuoteInternalSubmit
              onOpenManualAcceptanceModal={() =>
                manualAcceptanceModalState.onOpen()
              }
            />
          </MAccordion>
        )}
      </MBox>
    </form>
  );
};
