import { atomFamily, selectorFamily } from 'recoil';

type QuoteId = string;

type BannerDismissedState = {
  ContractWillBeCancelledBanner: boolean;
  ContractIsCancelledBanner: boolean;
  ContractCancelledFromContractActionBanner: boolean;
  InactiveRateInfoBanner: boolean;
  InactiveRateWarningBanner: boolean;
  RenewalNotAvailableBanner: boolean;
  UsageProductWarningBanner: boolean;
};

// Banner state - whether the banner has been dismissed for a given quote
// atomFamily stores state for a given key, each quote has it's own state stored in memory
const bannerDismissedState = atomFamily<BannerDismissedState, QuoteId>({
  key: 'bannerDismissedState',
  default: {
    ContractWillBeCancelledBanner: false,
    ContractIsCancelledBanner: false,
    ContractCancelledFromContractActionBanner: false,
    InactiveRateInfoBanner: false,
    InactiveRateWarningBanner: false,
    RenewalNotAvailableBanner: false,
    UsageProductWarningBanner: false,
  },
});

// Convenience selector to get/set the dismissed state for a given quote+banner
export const bannerDismissedSelector = selectorFamily({
  key: 'bannerDismissedStateSelector',
  get:
    ([quoteId, bannerKey]: [QuoteId, keyof BannerDismissedState]) =>
    ({ get }) => {
      return get(bannerDismissedState(quoteId))[bannerKey];
    },
  set:
    ([quoteId, bannerKey]: [QuoteId, keyof BannerDismissedState]) =>
    ({ set }, newValue) => {
      set(bannerDismissedState(quoteId), (prevValue) => ({
        ...prevValue,
        [bannerKey]: newValue,
      }));
    },
});
