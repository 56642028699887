import {
  MFlex,
  MBox,
  MButton,
  MBreadcrumb,
  MPageTopNavContainer,
} from '~app/components/Monetize';
import { MLastSavedStatus } from '~app/components/Monetize/MLastSavedStatus';
import { ReviewQuoteActionButton } from '../../Quote/components/reviewQuote/ReviewQuoteActionButton';
import { useQuoteContext } from '../../Quote/quoteContext';
import { useFlags } from '~app/services/launchDarkly';
import { QuoteActionsV2 } from '../../../../components/Quotes/QuoteActionsV2';
import { ROUTES } from '~app/constants';
import { QuoteProgressBar } from '~app/components/Quotes/QuoteProgress.tsx/QuoteProgressBar';
import { QuoteInternalExternalViewToggle } from '../../Quote/components/QuoteInternalExternalViewToggle';
import { QuoteAlerts } from '../../Quote/QuoteAlerts';
import { useState, useEffect } from 'react';
import { QuoteStatusEnum } from '~app/types';
import ViewOriginalLink from '../../Quote/ViewOriginalLink';
import { QuoteTagsV2 } from '~app/components/Quotes/QuoteTagsV2';

interface QuotePageHeaderProps {
  hasValidRole: boolean;
  setContactDrawerOpen: (val: boolean) => void;
  canEditQuote?: boolean;
  editQuoteLoading?: boolean;
  handleEditQuoteClick?: () => void;
  onOpenManualAcceptanceModal: () => void;
}

export const QuotePageHeader = ({
  hasValidRole,
  canEditQuote = false,

  editQuoteLoading = false,
  handleEditQuoteClick,

  onOpenManualAcceptanceModal,
}: QuotePageHeaderProps) => {
  const {
    isReadOnly,
    isError,
    quoteData,
    quoteStateData,
    methods,
    reviewQuoteModalData,
    manualRenewalFormMethods,
    isInternalView,
    actionRunning,
    isEsignEnabled,
    setIsInternalView,
    handleSendQuote,
    handleRecreateQuote,
    isQuoteSaving,
    handleBackButton,
  } = useQuoteContext();
  const { loading: quoteLoading, quote, setQuote, fetchQuote } = quoteData;
  const isDraft = quote?.status === QuoteStatusEnum.DRAFT;
  const isActionLoading = actionRunning || quoteLoading;

  const { quoteInternalExternalView } = useFlags();

  const { offeringLoadingState } = quoteStateData;
  const anyOfferingLoading = Object.values(offeringLoadingState).some(
    (item) => item.isLoading,
  );

  const [displaySticky, setDisplaySticky] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('scroll', handleStickyMenu);

    return () => {
      window.removeEventListener('scroll', handleStickyMenu);
    };
  }, []);

  const handleStickyMenu = () => {
    if (window !== undefined) {
      window.scrollY > 20 ? setDisplaySticky(true) : setDisplaySticky(false);
    }
  };

  if (!quote) {
    return null;
  }

  return (
    <MPageTopNavContainer
      position="sticky"
      top="0"
      background="tWhite.300"
      zIndex="4"
      boxShadow={displaySticky ? '22px 0px 56px 0px #00000021' : 'none'}
    >
      <MBox maxWidth="1440px" margin="0 auto">
        <MFlex alignItems="center" justifyContent="space-between" height="64px">
          <MFlex alignItems="center" justifyContent="space-between">
            <MBreadcrumb
              items={[
                {
                  label: quote.accountName,
                  route: ROUTES.getAccountDetailRoute(quote.accountId),
                },
                {
                  label:
                    quote?.status === QuoteStatusEnum.DRAFT
                      ? 'New Quote'
                      : 'Review Quote',
                  isCurrentPage: true,
                },
              ]}
              backButtonProps={{
                title: 'Go Back',
                onClick: handleBackButton,
              }}
              containerStyles={{ mr: '2', ml: -1 }}
            />
            <QuoteTagsV2
              quote={quote}
              isReadOnly={isReadOnly}
              setQuote={setQuote}
              manualRenewalFormMethods={manualRenewalFormMethods}
              mt={0.5}
            />
          </MFlex>

          <MBox>
            {!isReadOnly && quote.modifyDate && isInternalView && (
              <MLastSavedStatus
                isLoading={
                  quoteData.saving || anyOfferingLoading || isQuoteSaving
                }
                isError={isError}
              />
            )}
          </MBox>
        </MFlex>

        <MFlex w="100%">
          <QuoteProgressBar
            quote={quote}
            approvals={quote.approvals}
            reloadData={() => {
              quote?.id && fetchQuote(quote.id);
            }}
          />
        </MFlex>

        <MFlex
          flexDir="row"
          justifyContent="space-between"
          w="100%"
          pt="3"
          pb="3"
        >
          {quoteInternalExternalView && (
            <QuoteInternalExternalViewToggle
              isInternalView={isInternalView}
              setIsInternalView={setIsInternalView}
            />
          )}

          <MFlex align="center">
            <QuoteAlerts validations={quote.validations} />

            {isDraft && <ViewOriginalLink quote={quote} px="0" pr="4" />}

            <ReviewQuoteActionButton
              isReadOnly={isReadOnly}
              hasValidRole={hasValidRole}
              isActionLoading={isActionLoading}
              isEsignEnabled={isEsignEnabled}
              quote={quote}
              quoteStateData={quoteStateData}
              quoteFormMethods={methods}
              reviewQuoteModalData={reviewQuoteModalData}
              anyOfferingLoading={anyOfferingLoading}
              onAcceptQuote={onOpenManualAcceptanceModal}
              onRecreateQuote={handleRecreateQuote}
              onSendQuote={handleSendQuote}
            />
            {quote.id && hasValidRole && (
              <QuoteActionsV2
                quote={quote}
                setQuote={setQuote}
                loadData={() => quote.id && fetchQuote(quote.id)}
                manualRenewalFormMethods={manualRenewalFormMethods}
                isReadOnly={isReadOnly}
                handleEditQuoteClick={handleEditQuoteClick}
                canEditQuote={canEditQuote}
                editQuoteLoading={editQuoteLoading}
              />
            )}
          </MFlex>
        </MFlex>
      </MBox>
    </MPageTopNavContainer>
  );
};
