import { FC } from 'react';
import { MBox, MStatusIDBox, MFlex, MText } from '~app/components/Monetize';
import { MParentLink } from '~app/components/Monetize/MParentLink';
import { ChangeOwnerV2 } from '~app/components/Quotes/ChangeOwnerV2';
import { QuoteBasicInfoLine } from '~app/components/Quotes/QuoteFormHeaderLine';
import { getAccountQuotesRoute } from '~app/constants/routes';
import { QuoteTypeTag } from '../../Quote/components/QuoteTypeTag';
import { useQuoteContext } from '../../Quote/quoteContext';
import QuoteBasicOppotunity from '~app/components/Quotes/QuoteBasicOppotunity';
import { QuoteTypeEnum } from '~app/types';

interface QuoteBasicDetailsProps {}

export const QuoteBasicDetails: FC<
  QuoteBasicDetailsProps
> = ({}: QuoteBasicDetailsProps) => {
  const {
    isReadOnly,
    quoteData: { quote },
    opportunityLinkData,
    handleChangeOwner,
  } = useQuoteContext();

  if (!quote) {
    return null;
  }
  return (
    <MBox>
      <QuoteBasicInfoLine title="Account">
        <MParentLink
          parentLink={getAccountQuotesRoute(quote?.accountId)}
          parentLinkTitle={quote.accountName}
        />
      </QuoteBasicInfoLine>
      <QuoteBasicInfoLine title="Opportunity">
        {opportunityLinkData && (
          <QuoteBasicOppotunity {...opportunityLinkData} />
        )}
      </QuoteBasicInfoLine>
      <QuoteBasicInfoLine title="Owner" inputId="quote-header-owner">
        <MBox mr={isReadOnly ? 0 : '-3'}>
          <ChangeOwnerV2
            inputId="quote-header-owner"
            isReadOnly={isReadOnly}
            value={quote?.owner}
            ownerName={quote?.ownerName}
            onChange={(e) => handleChangeOwner(String(e))}
          />
        </MBox>
      </QuoteBasicInfoLine>

      <QuoteBasicInfoLine title="Quote ID">
        <MBox>
          <MStatusIDBox id={quote?.id!} copyUrl displayIcon={false} />
        </MBox>
      </QuoteBasicInfoLine>

      <QuoteBasicInfoLine title="Type">
        <MFlex alignItems="center">
          {quote.type === QuoteTypeEnum.NEW ? (
            <MText>New Quote</MText>
          ) : (
            <QuoteTypeTag quote={quote} />
          )}
        </MFlex>
      </QuoteBasicInfoLine>
    </MBox>
  );
};
