import { CardBody } from '@chakra-ui/react';
import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { handleApiErrorToast } from '~app/api/axios';
import { useUpdateNewQuoteType } from '~app/api/cpqService';
import { MBox, MButton, MCard, MFlex } from '~app/components/Monetize';
import { QUOTE_MANUAL_RENEWABLE_STATUSES } from '~app/constants/quotes';
import { useACL } from '~app/services/acl/acl';
import {
  INewQuoteTypeReqSchema,
  IQuoteRespSchema,
  NewQuoteTypeEnum,
  QuoteStatusEnum,
  QuoteTypeEnum,
} from '~app/types';
import { ManualRenewalForm } from '../../ManualRenewalForm';

interface QuoteAnalyticsManualRenewalFormProps {
  isReadOnly?: boolean;
  quote: IQuoteRespSchema;
  manualRenewalForm: UseFormReturn<INewQuoteTypeReqSchema, object>;
  setQuote: (quote: IQuoteRespSchema | null) => void;
  onClose?: () => void;
}
export const QuoteAnalyticsManualRenewalForm = ({
  isReadOnly,
  quote,
  manualRenewalForm,
  setQuote,
  onClose,
}: QuoteAnalyticsManualRenewalFormProps) => {
  const { formState, handleSubmit } = manualRenewalForm;
  const { isValid, isDirty } = formState;
  const [isEditable, setIsEditable] = useState<boolean>(false);

  const { canDo } = useACL();
  const canUpdateSales = canDo([['sales', 'update']]);
  const canEdit =
    canUpdateSales &&
    !isReadOnly &&
    !QUOTE_MANUAL_RENEWABLE_STATUSES.has(quote.status) &&
    quote.status === QuoteStatusEnum.DRAFT;

  const { mutate: updateNewQuoteType, isLoading } = useUpdateNewQuoteType({
    onSuccess: (data) => {
      setQuote(data);
      onClose && onClose();
    },
    onError: (error) => {
      handleApiErrorToast(error);
    },
  });

  const onSubmit = async (data: INewQuoteTypeReqSchema) =>
    updateNewQuoteType({
      quoteId: quote.id,
      payload: {
        ...data,
        previousArr: Number(data.previousArr),
        type: NewQuoteTypeEnum.MANUAL_RENEWAL,
      },
    });

  if (
    quote.newQuoteType !== NewQuoteTypeEnum.MANUAL_RENEWAL ||
    quote.type !== QuoteTypeEnum.NEW
  ) {
    return null;
  }

  return (
    <MCard variant="borderless" mt="2">
      <CardBody>
        <MBox>
          <ManualRenewalForm
            isEditable={isEditable}
            onSubmit={onSubmit}
            manualRenewalForm={manualRenewalForm}
            formFieldProps={{
              isHorizontal: true,
              justifyContent: 'space-between',
              isRequired: false,
              labelProps: {
                fontSize: 24,
                fontWeight: 400,
              },
            }}
            lockedTextProps={{
              fontSize: 24,
            }}
          />
          {canEdit && (
            <MFlex justifyContent="end">
              <MButton
                isLoading={isEditable && isLoading}
                variant={isEditable ? 'primary' : 'tertiary'}
                onClick={() => {
                  if (isEditable) {
                    handleSubmit(onSubmit)();
                  } else {
                    setIsEditable(true);
                  }
                }}
                isDisabled={isEditable && (!isValid || !isDirty || isLoading)}
                type="submit"
                minW="auto"
                ml={isEditable ? 0 : -4}
                mt={isEditable ? 3 : 0}
              >
                {isEditable ? 'Save' : 'Edit'}
              </MButton>
            </MFlex>
          )}
        </MBox>
      </CardBody>
    </MCard>
  );
};
