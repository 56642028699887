import { useMutation, useQuery } from '@tanstack/react-query';
import { apiGet, apiPost } from '~api/axios';
import {
  IShareInvoiceGenerateSetupIntentResp,
  IShareInvoiceRespSchema,
  ISharedInvoicePaymentReq,
  Maybe,
} from '~types';
import { invoiceServiceQueryKeys } from './invoiceService';

export const shareQueryKeys = {
  base: ['share'] as const,
  invoices: () => [...shareQueryKeys.base, 'invoices'] as const,
  invoiceDetail: (id: string) => [...shareQueryKeys.invoices(), id] as const,
};

export function useGetShareInvoiceById(
  secretId: string,
  tenantId: string,
  options: {
    onSuccess?: (data: IShareInvoiceRespSchema) => void;
    onError?: (err: unknown) => void;
  } = {},
) {
  return useQuery([...shareQueryKeys.invoiceDetail(secretId)], {
    queryFn: () =>
      apiGet<IShareInvoiceRespSchema>(`/api/share/invoices/${secretId}`, {
        axiosConfig: {
          excludeUserIdFromHeader: true,
          excludeTenantIdFromHeader: true,
          customXTenantId: tenantId,
        },
      }).then((res) => res.data),
    ...options,
    refetchOnWindowFocus: false,
  });
}

export function useShareInvoicesGenerateSetupIntent(
  options: {
    onSuccess?: (data: IShareInvoiceGenerateSetupIntentResp) => void;
    onError?: (err: unknown) => void;
  } = {},
) {
  return useMutation<
    IShareInvoiceGenerateSetupIntentResp,
    unknown,
    { invoiceSecretId: string; tenantId: string }
  >(
    ({ invoiceSecretId, tenantId }) => {
      return apiPost<IShareInvoiceGenerateSetupIntentResp>(
        `/api/share/invoices/${invoiceSecretId}/paymentMethods/token`,
        null,
        {
          axiosConfig: {
            excludeUserIdFromHeader: true,
            excludeTenantIdFromHeader: true,
            customXTenantId: tenantId,
          },
        },
      ).then((res) => res.data);
    },
    {
      ...options,
    },
  );
}

export const doPrintShareInvoiceToPdf = async (
  tenantId: string,
  invoiceSecretId: string,
  invoiceId: string,
  invoiceNumber: Maybe<string>,
) => {
  const res = await apiGet<any>(
    `/api/share/invoices/${invoiceSecretId}/print`,
    {
      responseType: 'arraybuffer',
      axiosConfig: {
        excludeUserIdFromHeader: true,
        excludeTenantIdFromHeader: true,
        customXTenantId: tenantId,
      },
      headers: {
        accept: 'application/pdf',
      },
    },
  );
  return {
    data: res.data,
    fileName: `invoice-${invoiceNumber || invoiceId}.pdf`,
  };
};

export const usePrintShareInvoiceToHtml = (
  {
    invoiceSecretId,
    lastModifiedTimestamp,
    tenantId,
  }: {
    invoiceSecretId: string;
    /** Used for caching response */
    lastModifiedTimestamp?: string;
    tenantId: string;
  },
  options: {
    enabled?: boolean;
    onSuccess?: (data: string) => void;
    onError?: (error: unknown) => void;
  } = {},
) => {
  return useQuery(
    [
      ...invoiceServiceQueryKeys.htmlTemplate(invoiceSecretId),
      lastModifiedTimestamp,
      tenantId,
    ],
    {
      queryFn: () =>
        apiGet<string>(`/api/share/invoices/${invoiceSecretId}/print`, {
          responseType: 'text',
          headers: {
            accept: 'text/html',
          },
          axiosConfig: {
            excludeUserIdFromHeader: true,
            excludeTenantIdFromHeader: true,
            customXTenantId: tenantId,
          },
        }).then((res) => res.data),
      refetchOnWindowFocus: false,
      retry: false,
      keepPreviousData: true,
      ...options,
    },
  );
};

export function useCreateSharedInvoicePayment(
  options: {
    onSuccess?: (data: void) => void;
    onError?: (err: unknown) => void;
  } = {},
) {
  return useMutation<
    void,
    unknown,
    {
      invoiceSecretId: string;
      tenantId: string;
      body: ISharedInvoicePaymentReq;
    }
  >(
    ({ invoiceSecretId, tenantId, body }) => {
      return apiPost(
        `/api/share/invoices/${invoiceSecretId}/startPayment`,
        body,
        {
          axiosConfig: {
            excludeUserIdFromHeader: true,
            excludeTenantIdFromHeader: true,
            customXTenantId: tenantId,
          },
        },
      ).then((res) => res.data);
    },
    {
      ...options,
    },
  );
}
