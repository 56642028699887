import { UseFormReturn } from 'react-hook-form';
import { MButton, MFlex } from '../../../../components/Monetize';
import { MLastSavedStatus } from '../../../../components/Monetize/MLastSavedStatus';
import { NotesDrawer } from '../../../../components/Notes/NotesDrawer';
import { QUOTE_ACCEPTED_STATUSES } from '../../../../constants/quotes';
import {
  INewQuoteTypeReqSchema,
  IQuoteRequestSchema,
  IQuoteRespSchema,
  QuoteStatusEnum,
} from '../../../../types';
import OpportunityLink, { OpportunityLinkDataTypes } from '../OpportunityLink';
import { QuoteActions } from '../QuoteActions';
import { QuoteAlerts } from '../QuoteAlerts';
import { QuoteModalDataTypes } from '../QuoteReviewModal';
import ViewOriginalLink from '../ViewOriginalLink';
import { QuoteStateDataTypes } from '../quoteContext';
import { PreviewQuotePdfDrawer } from './PreviewQuotePdfDrawer';
import { QuoteInternalExternalViewToggle } from './QuoteInternalExternalViewToggle';
import QuoteContactIcon from './contacts/QuoteContactIcon';
import CustomizeQuoteDrawer from './customizeQuoteDrawer/CustomizeQuoteDrawer';
import QuoteAnalytics from './quoteAnalytics/QuoteAnalytics';
import { ReviewQuoteActionButton } from './reviewQuote/ReviewQuoteActionButton';

interface QuotePageActionsProps {
  isReadOnly: boolean;
  quote: IQuoteRespSchema;
  isLoading: boolean;
  isError?: boolean;
  showPrimaryContactRequiredBg: boolean;
  quoteInternalExternalView: boolean;
  manualRenewalFormMethods: UseFormReturn<INewQuoteTypeReqSchema>;
  opportunityLinkData: OpportunityLinkDataTypes;

  hasValidRole: boolean;
  setQuote: (val: IQuoteRespSchema | null) => void;
  setContactDrawerOpen: (val: boolean) => void;
  fetchQuote: (id: string) => void;

  /** Specific to Review Quote screen */
  isEsignEnabled?: boolean;
  canEditQuote?: boolean;
  isActionLoading?: boolean;
  editQuoteLoading?: boolean;
  handleEditQuoteClick?: () => void;
  onOpenManualAcceptanceModal: () => void;
  handleRecreateQuote: () => void;
  handleSendQuote: () => void;

  isInternalView: boolean;
  setIsInternalView: (val: boolean) => void;

  quoteFormMethods: UseFormReturn<IQuoteRequestSchema>;
  reviewQuoteModalData: QuoteModalDataTypes;
  quoteStateData: QuoteStateDataTypes;
}
export const QuotePageActions = ({
  isReadOnly,
  quote,
  isLoading,

  isError = false,
  showPrimaryContactRequiredBg,
  manualRenewalFormMethods,
  opportunityLinkData,

  quoteInternalExternalView,
  hasValidRole,
  setQuote,
  setContactDrawerOpen,
  fetchQuote,

  isEsignEnabled = false,
  canEditQuote = false,
  isActionLoading = false,
  editQuoteLoading = false,
  handleEditQuoteClick,
  onOpenManualAcceptanceModal,
  handleRecreateQuote,
  handleSendQuote,

  isInternalView,
  setIsInternalView,
  quoteFormMethods,
  reviewQuoteModalData,
  quoteStateData,
}: QuotePageActionsProps) => {
  const isDraft = quote.status === QuoteStatusEnum.DRAFT;
  const { offeringLoadingState } = quoteStateData;
  const anyOfferingLoading = Object.values(offeringLoadingState).some(
    (item) => item.isLoading,
  );

  return (
    <MFlex flexDir="column" alignItems="flex-end">
      <MFlex align="center">
        {!isReadOnly && quote.modifyDate && isInternalView && (
          <MLastSavedStatus
            mr={2}
            isLoading={isLoading || anyOfferingLoading}
            isError={isError}
          />
        )}

        {isDraft && <ViewOriginalLink quote={quote} />}

        {canEditQuote && handleEditQuoteClick && (
          <MButton
            variant="tertiary"
            isDisabled={isActionLoading || editQuoteLoading}
            isLoading={editQuoteLoading}
            onClick={handleEditQuoteClick}
          >
            Edit Quote
          </MButton>
        )}

        {quote.contacts && (
          <QuoteContactIcon
            showPrimaryContactRequiredBg={showPrimaryContactRequiredBg}
            contacts={quote.contacts}
            onOpen={() => setContactDrawerOpen(true)}
          />
        )}

        <QuoteAnalytics
          quote={quote}
          setQuote={setQuote}
          manualRenewalFormMethods={manualRenewalFormMethods}
        />
        {opportunityLinkData && <OpportunityLink {...opportunityLinkData} />}

        {quote.id && <CustomizeQuoteDrawer quote={quote} />}

        {quote.id && (
          <NotesDrawer
            id={quote.id}
            entity="quotes"
            milestones={
              QUOTE_ACCEPTED_STATUSES.has(quote.status) && quote.acceptedAt
                ? [{ date: quote.acceptedAt, label: 'Quote Accepted' }]
                : undefined
            }
            isReadOnly={isReadOnly}
            disabled={!quote}
          />
        )}

        {quote.id && <PreviewQuotePdfDrawer quote={quote} />}

        <ReviewQuoteActionButton
          isReadOnly={isReadOnly}
          hasValidRole={hasValidRole}
          isActionLoading={isActionLoading}
          isEsignEnabled={isEsignEnabled}
          quote={quote}
          quoteStateData={quoteStateData}
          quoteFormMethods={quoteFormMethods}
          reviewQuoteModalData={reviewQuoteModalData}
          anyOfferingLoading={anyOfferingLoading}
          onAcceptQuote={onOpenManualAcceptanceModal}
          onRecreateQuote={handleRecreateQuote}
          onSendQuote={handleSendQuote}
        />

        {quote.id && hasValidRole && (
          <QuoteActions
            quote={quote}
            setQuote={setQuote}
            loadData={() => quote.id && fetchQuote(quote.id)}
            manualRenewalFormMethods={manualRenewalFormMethods}
          />
        )}
      </MFlex>
      <MFlex mt="5" mr="4" alignItems="center">
        <QuoteAlerts validations={quote.validations} />
        {quoteInternalExternalView && (
          <QuoteInternalExternalViewToggle
            isInternalView={isInternalView}
            setIsInternalView={setIsInternalView}
          />
        )}
      </MFlex>
    </MFlex>
  );
};
