import { ListItem, VStack } from '@chakra-ui/react';
import { FC } from 'react';
import { CustomSelectStateProps } from '~app/types/mCustomSelectTypes';
import { MCheckbox } from '../../chakra';
import MText from '../../MText';

interface CheckboxItemListProps
  extends Pick<
    CustomSelectStateProps,
    | 'isSelectedItem'
    | 'isDisabledItem'
    | 'itemRefs'
    | 'getTitle'
    | 'items'
    | 'cursor'
    | 'handleChange'
    | 'renderItemContent'
    | 'query'
  > {
  indexOffset: number;
}

const CheckboxItemList: FC<CheckboxItemListProps> = ({
  indexOffset,
  isSelectedItem,
  isDisabledItem,
  itemRefs,
  getTitle,
  cursor,
  items,
  handleChange,
  renderItemContent,
  query,
}: CheckboxItemListProps) => {
  const elements = items.map((item: any, i: number) => {
    const index = i + indexOffset;
    const isHighlight = cursor === index;
    const isSelected = isSelectedItem(item);
    const isDisabled = isDisabledItem(item);

    let backgroundColor = '';
    if (isSelected) {
      backgroundColor = 'tBlue.hover';
    }
    if (isHighlight) {
      backgroundColor = 'tBlue.hover';
    }

    return (
      <ListItem
        key={`cb-${index}`}
        tabIndex={-1}
        _hover={{
          background: 'tBlue.hover',
        }}
        backgroundColor={backgroundColor}
        cursor="pointer"
        px={1}
        py={1}
        role="group"
      >
        <MCheckbox
          onChange={(event: any) => {
            handleChange(item, event);
          }}
          mt="0"
          w="100%"
          isChecked={isSelected}
          isDisabled={isDisabled}
          fontWeight={isSelected ? 'bold' : undefined}
          variant="custom-select"
          tabIndex={-1}
          ref={(element: HTMLInputElement) =>
            (itemRefs.current[index] = element)
          }
        >
          {renderItemContent ? (
            renderItemContent({
              title: getTitle(item),
              item,
              isSubtitle: false,
              isSelected,
              isHighlight,
              isDisabled,
              query,
            })
          ) : (
            <MText
              color={'tPurple.base'}
              noOfLines={1}
              fontSize="sm"
              ml="1"
              maxW="250px"
            >
              {getTitle(item)}
            </MText>
          )}
        </MCheckbox>
      </ListItem>
    );
  });
  if (items.length === 0) {
    return null;
  }
  return (
    <VStack align="left" spacing="0">
      {elements}
    </VStack>
  );
};

export default CheckboxItemList;
