import { FC, useMemo } from 'react';
import { AiOutlineProduct } from 'react-icons/ai';
import { QuoteBodyAccordion } from '~app/components/Quotes/QuoteBodyAccordion';
import { useQuoteContext } from '../../Quote/quoteContext';
import { QuoteStatusEnum, QuoteTypeEnum } from '~app/types';
import { QuoteInternalOfferingTable } from './QuoteInternalOfferingTable';

interface QuoteInternalOfferingsProps {}

export const QuoteInternalOfferings: FC<
  QuoteInternalOfferingsProps
> = ({}: QuoteInternalOfferingsProps) => {
  const { quoteData } = useQuoteContext();
  const { quote } = quoteData;

  const title = useMemo(() => {
    if (quote?.status !== QuoteStatusEnum.DRAFT) {
      return 'Offerings';
    }
    if (quote?.type === QuoteTypeEnum.NEW) {
      return 'Select Offerings';
    } else {
      return 'Edit Offerings';
    }
  }, [quote?.status, quote?.type]);

  return (
    <QuoteBodyAccordion
      title={`${title} (${quote?.quoteOfferings.length})`}
      icon={AiOutlineProduct}
    >
      <QuoteInternalOfferingTable />
    </QuoteBodyAccordion>
  );
};
