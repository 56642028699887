import { ControllerRenderProps } from 'react-hook-form';
import {
  MCurrencySelect,
  MCustomNumberInput,
  MCustomSelect,
  MText,
} from '~app/components/Monetize';
import { DatePicker } from '~app/components/Monetize/DatePicker/DatePicker';
import {
  GuidedQuotingFieldDataTypeMapping,
  GuidedQuotingFieldDefaultsDisplayText,
  GuidedQuotingVariables,
} from '~app/constants/guidedQuoting';
import {
  GuidedQuotingReq,
  QuestionFilterByEnum,
  QuestionReq,
  QuestionTypesEnum,
  QuoteSourceFieldName,
} from '~app/types';
import { objectToSelectOptions } from '~app/utils/misc';
import { MLegalEntitySelect } from '../../../../components/Monetize/MLegalEntitySelect';

const VARIABLE_OPTIONS = objectToSelectOptions(GuidedQuotingVariables);

function getQuestionOptions(questions: QuestionReq[], type: QuestionTypesEnum) {
  return questions
    .filter((question) => question.type === type)
    .map((question) => ({
      title: `${question.questionNumber} - ${question.questionText}`,
      value: question.id,
    }));
}

interface GuidedQuotingColumnsValueProps {
  name: QuoteSourceFieldName;
  type: QuestionFilterByEnum;
  field: ControllerRenderProps<GuidedQuotingReq, any>;
  questions: QuestionReq[];
  clearErrors: () => void;
}

export const GuidedQuotingColumnsValue = ({
  name,
  type,
  field,
  questions,
  clearErrors,
}: GuidedQuotingColumnsValueProps) => {
  const dataType = GuidedQuotingFieldDataTypeMapping[name];
  if (type === 'CONSTANT') {
    if (dataType === 'DATE') {
      return (
        <DatePicker
          usePortal
          {...field}
          onChange={(date) => {
            field.onChange(date);
            clearErrors();
          }}
        />
      );
    } else if (dataType === 'NUMBER') {
      return (
        <MCustomNumberInput
          {...field}
          onChange={(value) => {
            field.onChange(value);
            clearErrors();
          }}
        />
      );
    } else if (dataType === 'CURRENCY') {
      return (
        <MCurrencySelect
          usePortal
          {...field}
          onChange={(value) => {
            field.onChange(value);
            clearErrors();
          }}
        />
      );
    } else if (dataType === 'LEGAL_ENTITY') {
      return (
        <MLegalEntitySelect
          usePortal
          {...field}
          onChange={(value) => {
            field.onChange(value);
            clearErrors();
          }}
        />
      );
    } else {
      return null;
    }
  } else if (type === 'QUESTION') {
    return (
      <MCustomSelect
        usePortal
        items={getQuestionOptions(questions, dataType)}
        {...field}
        onChange={(value) => {
          field.onChange(value);
          clearErrors();
        }}
      />
    );
  } else if (type === 'VARIABLE') {
    return (
      <MCustomSelect
        usePortal
        items={VARIABLE_OPTIONS}
        {...field}
        onChange={(value) => {
          field.onChange(value);
          clearErrors();
        }}
      />
    );
  } else {
    return (
      <MText fontStyle="italic">
        {GuidedQuotingFieldDefaultsDisplayText[name]}
      </MText>
    );
  }
};
