import { ColumnProps } from 'primereact/column';
import { DataTableExpandedRows } from 'primereact/datatable';
import { MutableRefObject } from 'react';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { Link } from 'react-router-dom';
import {
  MCustomIconButton,
  MDataTable,
  MFlex,
  MSkeleton,
  MText,
} from '../../../../components/Monetize';
import DataTableActions from '../../../../components/Monetize/DataTable/MDataTableActions';
import { MEntityStatusBadge } from '../../../../components/Monetize/MEntityStatusBadge';
import { ROUTES } from '../../../../constants';
import {
  IBillingSchedulePeriodRespSchema,
  IBillingScheduleRespSchemaUI,
  IBillingScheduleResSchema,
} from '../../../../types';
import { formatCurrency } from '../../../../utils';
import { toDateShort } from '../../../../utils/dates';
import { InvoiceItemsTable } from './InvoiceItemsTable';
import { BillingScheduleStatusEnumDiplay } from '~app/constants/billGroups';

interface InvoicesUnderContractTableProps {
  billingSchedule?: IBillingScheduleResSchema;
  expandedRows: DataTableExpandedRows;
  loading: boolean;
  billingScheduleLoading: boolean;
  rowRefs: MutableRefObject<{
    [index: string]: HTMLButtonElement;
  }>;
  setExpandedRows: (val: DataTableExpandedRows) => void;
  setPdfInvoiceId: (val: string | null) => void;
}
export const InvoicesUnderContractTable = ({
  billingSchedule,
  expandedRows,
  loading,
  billingScheduleLoading,
  rowRefs,
  setExpandedRows,
  setPdfInvoiceId,
}: InvoicesUnderContractTableProps) => {
  if (billingScheduleLoading) {
    return <MSkeleton height={10} w="full" />;
  }

  if (!billingSchedule) {
    return null;
  }

  const columns: ColumnProps[] = [
    {
      expander: true,
      field: 'invoiceId',
      header: 'Name',
      body: (data, op) => {
        return (
          <MFlex gap={1} align="center" pl={4}>
            <MCustomIconButton
              ref={(element: HTMLButtonElement) => {
                rowRefs.current[data.uid] = element;
              }}
              icon={
                !!expandedRows[data.invoiceDate] ? MdExpandLess : MdExpandMore
              }
              btnSize={5}
              onClick={op.expander?.onClick}
              _focus={{
                backgroundColor: 'transparent',
              }}
            />
            {data.invoiceId && (
              <Link to={ROUTES.getInvoiceDetailRoute(data.invoiceId)}>
                <MText
                  _hover={{ textDecor: 'underline', color: 'tIndigo.base' }}
                >{`Invoice: ${data.invoiceId}`}</MText>
              </Link>
            )}
            {!data.invoiceId && <MText>Upcoming Invoice</MText>}
          </MFlex>
        );
      },
      style: { width: '15rem' },
    },
    {
      field: 'servicePeriod',
      header: 'Service Period',
      style: { width: '10rem' },
      body: ({ servicePeriod: { start, end } }) => (
        <MText>
          {toDateShort(start)} - {toDateShort(end)}
        </MText>
      ),
    },
    {
      field: 'invoiceDate',
      header: 'Invoice Date',
      style: { width: '10rem' },
      body: ({ invoiceDate }) => <MText>{toDateShort(invoiceDate)}</MText>,
    },
    {
      field: 'amount',
      header: 'Amount',
      style: { width: '7rem' },
      body: ({ amount, currency, usageProductExists }) => {
        const formattedAmount = formatCurrency(amount, { currency });
        return (
          <MText>
            {formattedAmount}
            {usageProductExists && '*'}
          </MText>
        );
      },
    },
    {
      field: 'status',
      header: 'Status',
      style: { textAlign: 'left', width: '5rem' },
      body: (data: IBillingScheduleRespSchemaUI) => (
        <MEntityStatusBadge
          status={BillingScheduleStatusEnumDiplay[data.status]}
          ml="0"
        />
      ),
    },
    {
      style: { textAlign: 'left', width: '2rem' },
      body: (data) => {
        const actions = [
          {
            title: 'View Invoice PDF',
            enabled: !!data.invoiceId,
            action: () => {
              setPdfInvoiceId(data.invoiceId);
            },
          },
        ];
        return <DataTableActions actions={actions} />;
      },
    },
  ];

  return (
    <MDataTable
      style={{
        padding: 0,
      }}
      tableStyle={{
        tableLayout: 'fixed',
      }}
      value={billingSchedule.periods || []}
      expandedRows={expandedRows}
      dataKey="uid"
      onRowToggle={({ data }) => setExpandedRows(data as DataTableExpandedRows)}
      rowExpansionTemplate={(data: IBillingSchedulePeriodRespSchema) => (
        <InvoiceItemsTable period={data} />
      )}
      onRowClick={({ data: { uid } }) => {
        if (!billingScheduleLoading) {
          rowRefs.current?.[uid].click?.();
        }
      }}
      showHeaders={false}
      loading={loading}
      columns={columns}
    />
  );
};
