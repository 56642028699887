import { FC } from 'react';
import { MBox, MFlex, MText, QuoteSelectInput } from '~app/components/Monetize';
import { useQuoteContext } from '../../Quote/quoteContext';
import {
  ContactTypes,
  IContactRespSchema,
  IQuoteRequestSchema,
  QuoteSettingsDefaultAddressSourceEnum,
} from '~app/types';
import { Switch } from '@chakra-ui/react';
import { useFormContext, Controller } from 'react-hook-form';
import { AccountAddressSelect } from '~app/components/Account/AccountAddressSelect';
import { useIsTenantEsignConfigured } from '~app/hooks/useIsTenantEsignConfigured';
import { LegalEntityAddressFormatEnum } from '~app/types/legalEntityTypes';
import { getAddress } from '~app/utils/address';
import { QuoteBasicInfoLine } from '~app/components/Quotes/QuoteFormHeaderLine';
import { QuoteBasicContactSelect } from '~app/components/Quotes/QuoteBasicContactSelect';

interface QuoteBasicContactsProps {}

export const QuoteBasicContacts: FC<
  QuoteBasicContactsProps
> = ({}: QuoteBasicContactsProps) => {
  const {
    quoteContacts,
    quoteData: { quote },

    handleSubmitButtonWithoutDirtyCheck,
    isReadOnly,
  } = useQuoteContext();
  const isTenantEsignConfigured = useIsTenantEsignConfigured();

  const {
    externalContacts,
    internalContacts,
    externalAccountContacts,
    internalAccountContacts,
    handleChangeContact,
    addressDataForm,
    addressSource,
    isDisabled,
  } = quoteContacts;
  const shippingAddressId = addressDataForm.watch('shippingAddressId');

  const billingContact = externalContacts.find((contact) => contact.billing);
  const primaryContact = externalContacts.find((contact) => contact.primary);
  const esignContact = externalContacts.find((contact) => contact.esign);
  const esignInternalContact = internalContacts.find(
    (contact) => contact.esign,
  );

  const addressFormat = LegalEntityAddressFormatEnum.US_FORMAT;
  const { control, watch } = useFormContext<IQuoteRequestSchema>();
  const requiresEsignWatch = watch('requiresEsign');

  const handleChangeContactAndEvaluate = async (
    contact: IContactRespSchema,
    type: ContactTypes,
    isInternal: boolean,
  ) => {
    handleChangeContact(contact, type, isInternal);
  };

  return (
    <MBox>
      <QuoteBasicInfoLine
        title="Primary Contact"
        showRequired={!primaryContact}
      >
        <MBox mr={isDisabled ? 0 : '-2'}>
          <QuoteBasicContactSelect
            value={primaryContact?.contact}
            onChange={(contact) =>
              handleChangeContactAndEvaluate(contact, 'primary', false)
            }
            contacts={externalAccountContacts}
            isDisabled={isDisabled}
            isReadOnly={isDisabled}
          />
        </MBox>
      </QuoteBasicInfoLine>

      <QuoteBasicInfoLine
        title="Primary Address"
        showRequired={
          addressSource === QuoteSettingsDefaultAddressSourceEnum.ACCOUNT
            ? !shippingAddressId
            : !billingContact
        }
      >
        <MBox
          display="flex"
          flexDir="column"
          alignItems="flex-end"
          textAlign="right"
        >
          <MBox mr={isDisabled ? 0 : '-2'}>
            {addressSource === QuoteSettingsDefaultAddressSourceEnum.ACCOUNT &&
              quote?.accountId && (
                <Controller
                  name={'shippingAddressId'}
                  control={addressDataForm.control}
                  render={({ field: { ...rest } }) => {
                    return (
                      <AccountAddressSelect
                        isDisabled={isDisabled}
                        isReadOnly={isDisabled}
                        addressFormat={addressFormat}
                        accountId={quote?.accountId}
                        MainInputComponent={QuoteSelectInput}
                        showContentInInput
                        renderContentInInput={({
                          inputTitle,
                          onToggleOpen,
                        }) => (
                          <MFlex align="center" onClick={onToggleOpen}>
                            <MText
                              fontWeight="normal"
                              isTruncated
                              noOfLines={1}
                              whiteSpace="initial"
                              color="inherit"
                            >
                              {inputTitle}
                            </MText>
                          </MFlex>
                        )}
                        inputProps={{
                          autoComplete: 'off',
                        }}
                        placeholder="Select"
                        trucatedReadonly
                        popOverProps={{
                          placement: 'bottom-end',
                        }}
                        popOverContentProps={{
                          width: '100%',
                        }}
                        {...rest}
                      />
                    );
                  }}
                />
              )}
          </MBox>
          {addressSource === QuoteSettingsDefaultAddressSourceEnum.CONTACT && (
            <MText textAlign="right">
              {
                getAddress(primaryContact?.contact?.address, { addressFormat })
                  .fullAddress
              }
            </MText>
          )}
        </MBox>
      </QuoteBasicInfoLine>

      {isTenantEsignConfigured && (
        <QuoteBasicInfoLine title="eSign">
          <MFlex>
            <Controller
              name="requiresEsign"
              control={control}
              render={({ field: { value, onChange, ...rest } }) => (
                <Switch
                  isReadOnly={isReadOnly}
                  isDisabled={isReadOnly}
                  isChecked={value}
                  onChange={(e) => {
                    onChange(e.target.checked);
                    handleSubmitButtonWithoutDirtyCheck();
                  }}
                  {...rest}
                />
              )}
            />
          </MFlex>
        </QuoteBasicInfoLine>
      )}
      {requiresEsignWatch && (
        <>
          <QuoteBasicInfoLine
            title="eSign Internal Contact"
            showRequired={!esignContact}
          >
            <MBox mr={isDisabled ? 0 : '-2'}>
              <QuoteBasicContactSelect
                value={esignInternalContact?.contact}
                onChange={(contact) =>
                  handleChangeContactAndEvaluate(contact, 'esign', true)
                }
                contacts={internalAccountContacts}
                isDisabled={isDisabled}
                isReadOnly={isDisabled}
              />
            </MBox>
          </QuoteBasicInfoLine>
          <QuoteBasicInfoLine
            title="eSign Customer Contact"
            showRequired={!esignContact}
          >
            <MBox mr={isDisabled ? 0 : '-2'}>
              <QuoteBasicContactSelect
                value={esignContact?.contact}
                onChange={(contact) =>
                  handleChangeContactAndEvaluate(contact, 'esign', false)
                }
                contacts={externalAccountContacts}
                isDisabled={isDisabled}
                isReadOnly={isDisabled}
              />
            </MBox>
          </QuoteBasicInfoLine>
        </>
      )}
    </MBox>
  );
};
