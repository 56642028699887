import { useQueryClient } from '@tanstack/react-query';
import isObject from 'lodash/isObject';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { handleApiErrorToast, isAxiosError } from '~app/api/axios';
import {
  doChangeQuoteSigningOrder,
  doCreateQuote,
  doEditQuote,
  doEvaluateQuoteRules,
  doGetQuote,
  doManuallyAcceptQuote,
  doReorderQuoteOfferings,
  doReviewQuote,
  doSendQuote,
  doUpdateQuote,
  updateContactByQuote,
  useGetQuoteDisplayConfig,
} from '~app/api/cpqService';
import { SALES_QUOTES_ROUTE } from '~app/constants/routes';
import { updateQuoteQueryCache } from '~app/routes/Quotes/Quote/quoteUtils';
import {
  IQuoteBillingScheduleRespSchema,
  IQuoteContacts,
  IQuoteContactsRequest,
  IQuoteItemRespSchema,
  IQuoteOfferingRespSchema,
  IQuoteRequestSchema,
  IQuoteRespSchema,
  IQuoteReviewReq,
  IQuoteSigningOrderResp,
  IQuoteTemplateConfigSchema,
  QuoteStatusEnum,
  QuoteStatusTransitions,
  SigningOrderEnum,
} from '~app/types';
import { whoIsFirstQuoteApproval } from '~app/utils/quotes';
import { useFlags } from '../services/launchDarkly';
import { arrayToObject } from '../utils/misc';
import useBillingSchedule from './useBillingSchedule';

export type ModifiedFields = Set<keyof IQuoteRespSchema>;

export type QuoteDataById = {
  quote: IQuoteRespSchema | null;
  quoteOfferings: Record<string, IQuoteOfferingRespSchema>;
  quoteItems: Record<string, IQuoteItemRespSchema>;
};

export interface QuoteDataTypes {
  loading: boolean;
  saving: boolean;
  isError: boolean;
  modifiedFields: ModifiedFields;
  quote?: IQuoteRespSchema | null;
  quoteDataOnInitialLoad: QuoteDataById;
  /**
   * Update initial quote data to use as a reference for managing UI state
   * @param action - action to take on the seed data - MERGE will merge with existing data, it is fine if we have extra data stored as it will be ignored
   * @param seedQuote - quote to use as seed data
   */
  updateInitialQuoteData: (
    action: 'MERGE' | 'REPLACE',
    quote?: IQuoteRespSchema | null,
  ) => void;
  billingScheduleLoading: boolean;
  quoteBillingSchedule?: IQuoteBillingScheduleRespSchema | null;
  quoteFirstApprovalUser: string;
  displayConfig: IQuoteTemplateConfigSchema | null | undefined;
  fetchQuote: (
    quoteId: string,
    runEvaluate?: boolean,
    modifyQuoteResp?: (quote: IQuoteRespSchema) => IQuoteRespSchema,
  ) => Promise<IQuoteRespSchema | null>;
  /** Transition quote to a new state using dedicated APIs */
  transitionQuoteStatus: (
    quoteId: string,
    options:
      | {
          newState: Exclude<
            QuoteStatusTransitions,
            QuoteStatusEnum.ACCEPTED | QuoteStatusEnum.REVIEW
          >;
          extraData?: void;
        }
      | { newState: QuoteStatusEnum.REVIEW; extraData?: IQuoteReviewReq }
      | { newState: QuoteStatusEnum.ACCEPTED; extraData: FormData },
  ) => Promise<IQuoteRespSchema | null>;
  createQuote: (data: IQuoteRequestSchema) => Promise<IQuoteRespSchema | null>;
  updateQuote: (
    id: string,
    data: IQuoteRequestSchema,
    signal?: AbortSignal,
  ) => Promise<IQuoteRespSchema | null>;
  /** Evaluate Quote rules and update page with quote response */
  evaluateQuoteRules: (quoteId: string) => Promise<IQuoteRespSchema | null>;
  updateQuoteContacts: (
    quoteId: string,
    data: IQuoteContactsRequest,
  ) => Promise<IQuoteContacts | void>;
  /** Change order of quote offerings via drag/drop */
  reorderQuoteOfferings: (
    quoteId: string,
    order: string[],
  ) => Promise<IQuoteRespSchema | null>;
  /** Sets quote state. Allows setting known quote data without having to re-fetch */
  setQuote: React.Dispatch<React.SetStateAction<IQuoteRespSchema | null>>;
  updateQuoteSigningOrder: (
    data: SigningOrderEnum,
  ) => Promise<IQuoteSigningOrderResp | void>;
  signingOrderUI: SigningOrderEnum | null;
}

/**
 * Calculate modified fields - does not apply to complex fields such as objects and arrays.
 * Calculated based on the fields passed in to modified
 *
 * @param initial existing quote
 * @param modified modified payload being sent to updateQuote
 */
function getChangedFields(
  initial: IQuoteRespSchema | null,
  modified: IQuoteRequestSchema | null,
): ModifiedFields {
  const output: ModifiedFields = new Set<keyof IQuoteRespSchema>();
  if (!initial || !modified) {
    return output;
  }
  for (const [key, value] of Object.entries(modified)) {
    if (Array.isArray(value) || isObject(value)) {
      continue;
    }
    if (initial[key as keyof IQuoteRespSchema] !== value) {
      output.add(key as keyof IQuoteRespSchema);
    }
  }
  return output;
}

const useQuote = (initialQuote?: IQuoteRespSchema | null): QuoteDataTypes => {
  const { allowBillingScheduleV2 } = useFlags();

  // used to avoid explicit dependency on quote in useCallback's
  const quoteRef = useRef<IQuoteRespSchema | null>(null);
  /**
   * Used for amendment state comparison - amendmentStatusOverwrite may be mutated by consumers
   * this allows us to know we had a change even when BE reports "NO_CHANGE" - e.x. add a new segment
   * and also allows us to set the amendmentStatusOverwrite when we get new quote data so that state does not revert to locked
   */
  const quoteDataOnInitialLoad = useRef<QuoteDataById>({
    quote: null,
    quoteOfferings: {},
    quoteItems: {},
  });
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  /** Fields in this set were modified and are being updated if loading=true */
  const [modifiedFields, setLoadingFields] = useState<ModifiedFields>(
    new Set(),
  );
  const [quote, setQuote] = useState<IQuoteRespSchema | null>(
    () => initialQuote || null,
  );
  const [quoteFirstApprovalUser, setQuoteFirstApprovalUser] = useState('');
  const [signingOrderUI, setSigningOrderUI] = useState<SigningOrderEnum | null>(
    null,
  );

  const {
    fetchBillingSchedule,
    fetchBillingScheduleV2,
    loading: billingScheduleLoading,
    quoteBillingSchedule,
  } = useBillingSchedule(quote?.id || '');
  const navigate = useNavigate();

  useEffect(() => {
    if (
      quote &&
      (!quoteDataOnInitialLoad.current.quote ||
        quote.id !== quoteDataOnInitialLoad.current.quote?.id)
    ) {
      updateInitialQuoteData('REPLACE', quote);
    }
  }, [quote]);

  useEffect(() => {
    quoteRef.current = quote;
    setQuoteFirstApprovalUser(whoIsFirstQuoteApproval(quote));
  }, [quote]);

  // update quote if initial quote changes to a different quote
  useEffect(() => {
    setQuote((currentQuote) =>
      initialQuote?.id !== currentQuote?.id
        ? initialQuote ?? null
        : currentQuote,
    );
  }, [initialQuote?.id]);

  const { data: displayConfig } = useGetQuoteDisplayConfig(quote?.id!, {
    enabled: !!quote?.id,
  });

  /**
   * Update initial quote data to use as a reference for managing UI state
   * @param seedQuote - quote to use as seed data
   * @param action - action to take on the seed data - MERGE will merge with existing data, it is fine if we have extra data stored as it will be ignored
   */
  const updateInitialQuoteData = useCallback(
    (
      action: 'MERGE' | 'REPLACE',
      seedQuote: IQuoteRespSchema | null = quote,
    ) => {
      if (seedQuote) {
        const quoteOfferings =
          action === 'MERGE'
            ? [
                ...Object.values(quoteDataOnInitialLoad.current.quoteOfferings),
                ...seedQuote.quoteOfferings,
              ]
            : seedQuote.quoteOfferings;
        const quoteItems =
          action === 'MERGE'
            ? [
                ...Object.values(quoteDataOnInitialLoad.current.quoteItems),
                ...seedQuote.quoteOfferings.flatMap(
                  (quoteOffering) => quoteOffering.items,
                ),
              ]
            : seedQuote.quoteOfferings.flatMap(
                (quoteOffering) => quoteOffering.items,
              );

        quoteDataOnInitialLoad.current = {
          quote: seedQuote,
          quoteOfferings: arrayToObject(quoteOfferings, 'id'),
          quoteItems: arrayToObject(quoteItems, 'id'),
        };
      }
    },
    [],
  );

  const fetchQuote = useCallback(
    async (
      quoteId: string,
      runEvaluate?: boolean,
      modifyQuoteResp?: (quote: IQuoteRespSchema) => IQuoteRespSchema,
    ) => {
      try {
        setLoading(true);
        const quoteResponse = !runEvaluate
          ? await doGetQuote(quoteId)
          : await doEvaluateQuoteRules(quoteId);
        const updatedQuoteResp = modifyQuoteResp
          ? modifyQuoteResp(quoteResponse)
          : quoteResponse;
        setQuote(updatedQuoteResp);
        updateQuoteQueryCache(queryClient, updatedQuoteResp);
        return updatedQuoteResp;
      } catch (error) {
        handleApiErrorToast(error);
        // If quote id is not valid, redirect to sales page
        if (isAxiosError(error) && error.response?.status === 404) {
          navigate(SALES_QUOTES_ROUTE, { replace: true });
        }
      } finally {
        setLoading(false);
      }
      return null;
    },
    [],
  );

  const transitionQuoteStatus = useCallback(
    async (
      quoteId: string,
      {
        newState,
        extraData,
      }:
        | {
            newState: Exclude<
              QuoteStatusTransitions,
              QuoteStatusEnum.ACCEPTED | QuoteStatusEnum.REVIEW
            >;
            extraData?: void;
          }
        | { newState: QuoteStatusEnum.REVIEW; extraData?: IQuoteReviewReq }
        | { newState: QuoteStatusEnum.ACCEPTED; extraData: FormData },
    ): Promise<IQuoteRespSchema | null> => {
      try {
        setIsError(false);
        setLoading(true);
        let quoteResponse: IQuoteRespSchema | null = null;
        switch (newState) {
          case QuoteStatusEnum.REVIEW:
            quoteResponse = await doReviewQuote(quoteId, extraData);
            break;
          case QuoteStatusEnum.SENT:
            quoteResponse = await doSendQuote(quoteId);
            break;
          case QuoteStatusEnum.DRAFT:
            quoteResponse = await doEditQuote(quoteId);
            break;
          case QuoteStatusEnum.ACCEPTED:
            quoteResponse = await doManuallyAcceptQuote(quoteId, extraData);
            break;
          default:
            throw new Error('Invalid state transition');
        }

        if (!quoteResponse) {
          return null;
        }

        updateQuoteQueryCache(queryClient, quoteResponse);
        setQuote(quoteResponse);
        return quoteResponse;
      } catch (error) {
        setIsError(true);
        handleApiErrorToast(error);
      } finally {
        setLoading(false);
      }
      return null;
    },
    [queryClient],
  );

  const createQuote = useCallback(
    async (data: IQuoteRequestSchema): Promise<IQuoteRespSchema | null> => {
      try {
        setIsError(false);
        setLoading(true);
        const quoteResponse = await doCreateQuote(data);
        updateQuoteQueryCache(queryClient, quoteResponse);
        setQuote(quoteResponse);
        return quoteResponse;
      } catch (error) {
        setIsError(true);
        throw error; // re-throw to allow caller to handle
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const updateQuote = useCallback(
    async (
      id: string,
      data: IQuoteRequestSchema,
      signal?: AbortSignal,
    ): Promise<IQuoteRespSchema | null> => {
      try {
        setSaving(true);
        setIsError(false);
        setLoadingFields(getChangedFields(quoteRef.current, data));
        const quoteResponse = await doUpdateQuote(id, data);
        updateQuoteQueryCache(queryClient, quoteResponse);
        setQuote(quoteResponse);
        return quoteResponse;
      } catch (error) {
        setIsError(true);
        handleApiErrorToast(error);
        return null;
      } finally {
        setSaving(false);
        setLoadingFields(new Set());
      }
    },
    [],
  );

  const evaluateQuoteRules = useCallback(async (quoteId: string) => {
    try {
      setIsError(false);
      setLoading(true);
      const quoteResponse = await doEvaluateQuoteRules(quoteId);
      setQuote(quoteResponse);
      updateQuoteQueryCache(queryClient, quoteResponse);
      return quoteResponse;
    } catch (error) {
      setIsError(true);
      handleApiErrorToast(error);
    } finally {
      setLoading(false);
    }
    return null;
  }, []);

  const reorderQuoteOfferings = useCallback(
    async (id: string, order: string[]): Promise<IQuoteRespSchema | null> => {
      try {
        setIsError(false);
        setLoading(true);
        const quoteResponse = await doReorderQuoteOfferings(id, { order });
        updateQuoteQueryCache(queryClient, quoteResponse);
        setQuote(quoteResponse);
        return quoteResponse;
      } catch (error) {
        setIsError(true);
        handleApiErrorToast(error);
        return null;
      } finally {
        setLoading(false);
        setLoadingFields(new Set());
      }
    },
    [],
  );

  const updateQuoteContacts = useCallback(
    async (
      quoteId: string,
      data: IQuoteContactsRequest,
    ): Promise<IQuoteContacts | void> => {
      try {
        if (!quoteId) {
          return;
        }
        setIsError(false);
        setLoading(true);
        const contacts = await updateContactByQuote(quoteId, data);
        if (quote && contacts) {
          quote.contacts = contacts;
          setQuote({ ...quote, contacts });
        }
        // initiate fetch in background to ensure that any inflight fetches will include the contacts
        fetchQuote(quoteId);
        return contacts;
      } catch (error) {
        setIsError(true);
        handleApiErrorToast(error);
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  const updateQuoteSigningOrder = async (signingOrder: SigningOrderEnum) => {
    try {
      setIsError(false);
      setLoading(true);
      setSigningOrderUI(signingOrder);
      if (!quote) {
        return;
      }
      await doChangeQuoteSigningOrder(quote.id, signingOrder);
      const updatedQuote = { ...quote, signingOrder };
      setQuote(updatedQuote);
      updateQuoteQueryCache(queryClient, updatedQuote);
    } catch (ex) {
      setIsError(true);
      handleApiErrorToast(ex);
    } finally {
      setSigningOrderUI(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (quote && allowBillingScheduleV2) {
      fetchBillingScheduleV2();
    } else if (quote) {
      fetchBillingSchedule();
    }
  }, [quote]);

  return {
    loading,
    saving,
    isError,
    modifiedFields,
    billingScheduleLoading,
    quote,
    quoteDataOnInitialLoad: quoteDataOnInitialLoad.current,
    updateInitialQuoteData,
    signingOrderUI,
    quoteBillingSchedule,
    quoteFirstApprovalUser,
    displayConfig,
    updateQuoteSigningOrder,
    fetchQuote,
    transitionQuoteStatus,
    createQuote,
    updateQuote,
    evaluateQuoteRules,
    reorderQuoteOfferings,
    updateQuoteContacts,
    setQuote,
  };
};

export default useQuote;
