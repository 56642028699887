import { MTag } from '~app/components/Monetize';
import { QuoteTypeEnumDisplay } from '~app/constants/quotes';
import { IQuoteRespSchema } from '~app/types';
import { getVariantByStatus } from '~app/utils';

export const QuoteTypeTag = (props: { quote: IQuoteRespSchema }) => {
  const { quote, ...rest } = props;
  const variant = getVariantByStatus(quote.type);
  return (
    <MTag variant={variant} {...rest}>
      {QuoteTypeEnumDisplay[quote.type]}
    </MTag>
  );
};
