import { v4 as uuidv4 } from 'uuid';
import { composeGetQuery } from '~app/api/utils';
import { MFlex, MText } from '~app/components/Monetize';
import { Highlighter } from '~app/components/Monetize/MPageSearchInput/Highlighter';
import {
  OnlyEqualFields,
  QuestionComparatorsDisplayText,
  QuestionCompareFromCustomFields,
  QuestionCompareFromFields,
  listApiConfig,
} from '~app/constants/guidedQuoting';
import {
  ReferenceOptions,
  renderItemContentForItemsWithIdName,
} from '~app/routes/Settings/rules-v3/rules.utils';
import {
  GetListApiFilter,
  GuidedQuotingReq,
  GuidedQuotingStatusEnum,
  IGuidedQuotingRespSchema,
  Maybe,
  QuestionCompareFromEnum,
  QuestionFilterByEnum,
  QuestionIdPlaceholder,
  QuestionReq,
  QuestionTypesEnum,
  QuoteSourceField,
  QuoteSourceFieldName,
} from '~app/types';
import { arrayToObject, objectToSelectOptions } from '~app/utils/misc';

export function getQuestionTempId(): QuestionIdPlaceholder {
  return `TEMP_${uuidv4()}`;
}

export function getEmptyQuestion(
  questionNumber: number,
  guidedQuotingId?: Maybe<string>,
): QuestionReq {
  return {
    id: getQuestionTempId(),
    guidedQuotingId,
    questionText: '',
    questionNumber,
    type: QuestionTypesEnum.TEXT,
    filterBy: QuestionFilterByEnum.NONE,
  };
}

export function convertResponseToRequestData(
  data?: IGuidedQuotingRespSchema,
): GuidedQuotingReq {
  const defaultQuestionArray: QuestionReq[] = [getEmptyQuestion(1, data?.id)];

  const quoteFieldsByName = arrayToObject(
    data?.quoteSourceFields || [],
    'quoteField',
  );

  function getQuoteField(quoteField: QuoteSourceFieldName): QuoteSourceField {
    return {
      quoteField,
      type: quoteFieldsByName[quoteField]?.type ?? QuestionFilterByEnum.NONE,
      value: quoteFieldsByName[quoteField]?.value ?? null,
    };
  }

  return {
    id: data?.id ?? null,
    name: data?.name ?? '',
    status: data?.status ?? GuidedQuotingStatusEnum.INACTIVE,
    quoteSourceFields: [
      getQuoteField('contractLength'),
      getQuoteField('contractStartDate'),
      getQuoteField('currency'),
      getQuoteField('expirationDate'),
      getQuoteField('legalEntity'),
      getQuoteField('quoteOffering'),
    ],
    questions:
      data?.questions?.map((item, index) => ({
        id: item.id,
        guidedQuotingId: item.guidedQuotingId,
        questionText: item.questionText,
        // ensure that all have correct number (there was some bad data from a bug)
        questionNumber: index + 1,
        type: item.type,
        filterBy: item.filterBy,
        compareFrom: item.compareFrom,
        customField: item.customField,
        comparator: item.comparator,
        // FIXME: add better types for compareTo
        compareTo: item.compareTo?.includes(',')
          ? item.compareTo.split(',')
          : item.compareTo ?? null,
      })) || defaultQuestionArray,
  };
}

export const showCompare = (
  responseType: string,
  compareFrom: QuestionCompareFromEnum | null | undefined,
  customField: string | null | undefined,
) => {
  if (Object.keys(QuestionCompareFromFields).includes(responseType)) {
    if (compareFrom) {
      return QuestionCompareFromCustomFields.includes(compareFrom)
        ? customField
        : true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

const renderItemContentForItemsWithKeyDisplayLabel: ReferenceOptions['renderItemContent'] =
  ({ item, query }) => {
    return (
      <MFlex
        direction="column"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        w="100%"
      >
        <MFlex justifyContent="space-between" w="100%">
          <Highlighter
            title={item.displayLabel}
            color="tPurple.dark"
            fontSize="sm"
            fontWeight="medium"
            textToHighlight={item.displayLabel}
            searchWords={[query]}
            whiteSpace="normal"
          />
        </MFlex>
        <MText color="tGray.darkPurple" fontSize="xs">
          {item.key}
        </MText>
      </MFlex>
    );
  };

export const referenceOptions = (
  item: string,
  filters?: GetListApiFilter,
  offeringId?: string,
) => {
  const { sortField, sortOrder, ...configWithoutSort } = listApiConfig;
  if (item === 'PRODUCT_ID' || item === 'PRODUCT') {
    return {
      endpoint: `/api/products`,
      additionalSearchParams: composeGetQuery(listApiConfig, filters),
      getByIdEndpointFn: (record: any) => `/api/products/${record.id}`,
      itemTitle: 'name',
      itemValue: 'id',
      renderItemContent: renderItemContentForItemsWithIdName,
    };
  } else if (item === 'OFFERING_ID' || item === 'OFFERING') {
    return {
      endpoint: `/api/offerings`,
      additionalSearchParams: composeGetQuery(listApiConfig, {
        ...filters,
        status: 'ACTIVE',
      }),
      getByIdEndpointFn: (record: any) => `/api/offerings/${record.id}`,
      itemTitle: 'name',
      itemValue: 'id',
      renderItemContent: renderItemContentForItemsWithIdName,
    };
  } else if (item === 'RATE_ID' || item === 'RATE') {
    return {
      endpoint: `/api/rates`,
      additionalSearchParams: composeGetQuery(listApiConfig, {
        ...filters,
        ...(offeringId && { offeringId }),
        status: 'ACTIVE',
      }),
      getByIdEndpointFn: (recordId: any) => `/api/rates/${recordId}`,
      itemTitle: 'name',
      itemValue: 'id',
      renderItemContent: renderItemContentForItemsWithIdName,
    };
  } else if (item.includes('PRODUCT_CUSTOM_FIELD')) {
    return {
      endpoint: `/api/configurations/customFields`,
      additionalSearchParams: composeGetQuery(configWithoutSort, {
        ...filters,
        entity: 'PRODUCT',
        //type: GuidedQuotingCustomFieldTypes[item],
      }),
      getByIdEndpointFn: (recordId: any) =>
        `/api/configurations/customFields/PRODUCT/${recordId}`,
      itemTitle: 'displayLabel',
      itemValue: 'key',
      renderItemContent: renderItemContentForItemsWithKeyDisplayLabel,
    };
  } else if (item.includes('OFFERING_CUSTOM_FIELD')) {
    return {
      endpoint: `/api/configurations/customFields`,
      additionalSearchParams: composeGetQuery(configWithoutSort, {
        ...filters,
        entity: 'OFFERING',
        //type: GuidedQuotingCustomFieldTypes[item],
      }),
      getByIdEndpointFn: (recordId: any) =>
        `/api/configurations/customFields/OFFERING/${recordId}`,
      itemTitle: 'displayLabel',
      itemValue: 'key',
      renderItemContent: renderItemContentForItemsWithKeyDisplayLabel,
    };
  }
};

export const getComparators = (
  responseType: QuestionTypesEnum,
  compareFrom: QuestionCompareFromEnum | null | undefined,
) => {
  if (compareFrom) {
    if (OnlyEqualFields.includes(compareFrom)) {
      return objectToSelectOptions({ EQUAL: 'Equal' });
    } else {
      return objectToSelectOptions(QuestionComparatorsDisplayText);
    }
  } else {
    if (OnlyEqualFields.includes(responseType)) {
      return objectToSelectOptions({ EQUAL: 'Equal' });
    } else {
      return objectToSelectOptions(QuestionComparatorsDisplayText);
    }
  }
};
