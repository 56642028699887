import { SupportedCurrencyCodes } from '../constants/currencies';
import {
  Maybe,
  PaymentGatewayConfigurations,
  PaymentMethodTypeEnum,
} from '../types';

export function initDefaultPaymentGatewayOptionsIfNotExists(
  supportedCurrencies: SupportedCurrencyCodes[],
  configuration?: Maybe<PaymentGatewayConfigurations>,
): PaymentGatewayConfigurations {
  if (!!configuration?.configurations.length) {
    return configuration;
  }
  return {
    configurations: [
      {
        type: PaymentMethodTypeEnum.US_BANK_TRANSFER,
        amountConfigurations: [
          {
            currency: 'USD',
            minimum: 0,
            maximum: null,
          },
        ],
      },
    ],
  };
}
