import { useEffect, useState } from 'react';
import { z } from 'zod';
import { handleApiErrorToast } from '~app/api/axios';
import { useGetListData } from '~app/api/queryUtils';
import { buildFilterParamsRequestObject } from '~app/utils';
import { arrayToObject } from '~app/utils/misc';
import {
  ApiListResponse,
  BillGroupRespSchema,
  DEFAULT_API_RESPONSE,
  DEFAULT_PAGER,
  FilterType,
  GetListApiFilter,
  GetSubscriptionSchema,
  IBillGroupResp,
  IGetSubscriptionSchema,
  TDataTablePager,
} from '~types';

const SubscriptionWithBillGroup = GetSubscriptionSchema.extend({
  billGroup: BillGroupRespSchema,
});

export type ISubscriptionWithBillGroup = z.infer<
  typeof SubscriptionWithBillGroup
>;

export const useSubscriptions = (accountId: string, billGroupId?: string) => {
  const [filters, setFilters] = useState<FilterType[]>([]);
  const [searchKey] = useState<string>('description');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [pager, setPager] = useState<TDataTablePager>(DEFAULT_PAGER);

  const [filterParams, setFilterParams] = useState<GetListApiFilter>(() =>
    buildFilterParamsRequestObject(filters, searchTerm, searchKey),
  );

  const [subscriptionsWithBillGroup, setSubscriptionsWithBillGroup] =
    useState<ApiListResponse<ISubscriptionWithBillGroup>>();

  const {
    isLoading: isBillGroupResLoading,
    isFetching: isBillGroupResFetching,
    data: billGroupsById,
  } = useGetListData<IBillGroupResp, Record<string, IBillGroupResp>>(
    'billGroups',
    { config: { first: 50, page: 0 } },
    {
      endpointArgs: { accountId },
      select: (data) => {
        return arrayToObject(data?.content || [], 'id');
      },
    },
  );

  useEffect(() => {
    if (filters) {
      setFilterParams(
        buildFilterParamsRequestObject(filters, searchTerm, searchKey),
      );
    }
  }, [filters, searchTerm, pager]);

  const {
    isLoading,
    isFetching,
    data: listData,
  } = useGetListData<IGetSubscriptionSchema>(
    'accountSubscriptions',
    {
      config: pager,
      filters: billGroupId ? { ...filterParams, billGroupId } : filterParams,
    },
    {
      endpointArgs: { accountId },
      onError: (err) => handleApiErrorToast(err),
    },
  );

  useEffect(() => {
    if (listData && billGroupsById) {
      setSubscriptionsWithBillGroup(() => {
        return {
          ...listData,
          content: listData.content.map((subscription) => {
            return {
              ...subscription,
              billGroup: billGroupsById[subscription.billGroupId],
            };
          }),
        };
      });
    }
  }, [listData, billGroupsById]);

  const onResetFilter = () => {
    setFilters([]);
  };

  return {
    pager,
    setPager,
    filters,
    setFilters,
    searchKey,
    searchTerm,
    setSearchTerm,
    onResetFilter,
    totalRecords: listData?.totalElements || 0,
    loading:
      (isLoading && isFetching) ||
      (isBillGroupResLoading && isBillGroupResFetching),
    listData: subscriptionsWithBillGroup || DEFAULT_API_RESPONSE,
  };
};
