import {
  CreditCardTypeEnum,
  PaymentMethodStatusEnum,
  PaymentMethodTypeEnum,
} from '~app/types';

export const PaymentMethodStatusEnumDisplay: {
  [key in PaymentMethodStatusEnum]: string;
} = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  CANCELED: 'Canceled',
  EXPIRED: 'Expired',
};

export const PaymentMethodTypeEnumDisplay: {
  [key in PaymentMethodTypeEnum]: string;
} = {
  CREDIT_CARD: 'Credit Card',
  ACH_CREDIT: 'ACH Credit Transfer',
  US_BANK_TRANSFER: 'Bank Transfer (USD)',
  DIRECT_DEBIT: 'ACH Direct Debit',
  MANUAL: 'Manual',
};

export const CREDIT_CARD_TYPE_DISPLAY: {
  [key in CreditCardTypeEnum]: string;
} = {
  VISA: 'Visa',
  MASTERCARD: 'Mastercard',
  DISCOVER: 'Discover',
  AMEX: 'American Express',
  DINERS: 'Diners',
  JCB: 'JCB',
  UNIONPAY: 'UnionPay',
};

export const COMPANY_NAME = 'MonetizeNow';

export const NEW_PAYMENT_METHOD = {
  title: '+ New Payment Method',
  value: 'NEW_PAYMENT_METHOD',
};

export const ACH_OR_BANK_TRANSFER = new Set([
  PaymentMethodTypeEnum.ACH_CREDIT,
  PaymentMethodTypeEnum.US_BANK_TRANSFER,
]);

export const REQUIRES_MANUAL_REFUND = new Set([
  PaymentMethodTypeEnum.MANUAL,
  PaymentMethodTypeEnum.ACH_CREDIT,
  PaymentMethodTypeEnum.US_BANK_TRANSFER,
]);
