import { forwardRef, useEffect, useState } from 'react';
import { contactSelectRenderItem } from '~app/components/Contacts/ContactSelect';
import { MCustomSelect, QuoteSelectInput } from '~app/components/Monetize';
import { IContactRespSchema } from '~app/types';
import { MCustomSelectProps } from '~app/types/mCustomSelectTypes';

interface QuoteBasicContactSelectProps
  extends Omit<MCustomSelectProps, 'value' | 'onChange'> {
  contacts: IContactRespSchema[];
  value?: IContactRespSchema | null;
  onChange: (contact: IContactRespSchema) => void;
}

export const QuoteBasicContactSelect = forwardRef(
  (
    { contacts, value, onChange, ...rest }: QuoteBasicContactSelectProps,
    ref,
  ) => {
    const [query, setQuery] = useState('');
    const [iContacts, setIContacts] = useState(contacts);
    const onChangeQuery = (q: string): void => {
      if (q !== query) {
        setQuery(q);
      }
    };

    useEffect(() => {
      if (query) {
        setIContacts(
          contacts.filter(
            (c) =>
              (c.fullName &&
                c.fullName.toLowerCase().includes(query.toLowerCase())) ||
              (c.email && c.email.toLowerCase().includes(query.toLowerCase())),
          ),
        );
      } else {
        setIContacts(contacts);
      }
    }, [contacts, query]);

    return (
      <MCustomSelect
        value={value}
        name="contacts"
        popOverProps={{ placement: 'bottom-end', matchWidth: false }}
        items={iContacts}
        itemTitle="fullName"
        itemValue="id"
        onChange={(ev: any) => onChange(ev)}
        MainInputComponent={QuoteSelectInput}
        showContentInInput
        useHighlightedItemList
        showItemDivider
        inputProps={{
          autoComplete: 'off',
        }}
        placeholder="Select"
        showQueryInput
        returnItem
        useExternalQuery
        externalQuery={query}
        onChangeQuery={onChangeQuery}
        skipFilter={true}
        renderItemContent={contactSelectRenderItem}
        ref={ref}
        {...rest}
      />
    );
  },
);
