import { useState } from 'react';
import { IOfferingReqSchema, IOfferingRes } from '~app/types';
import {
  useCreateEntity,
  useGetById,
  useUpdateEntity,
} from '~app/api/queryUtils';

const useOffering = (id: string) => {
  const [error, setError] = useState<any>(null);

  const {
    data: offering,
    isInitialLoading: isOfferingLoading,
    refetch: fetchOffering,
  } = useGetById<IOfferingRes>('productCatalogOfferings', id, {
    enabled: !!id,
    refetchOnWindowFocus: false,
  });

  const { mutateAsync: createOffering, isLoading: isCreateOfferingLoading } =
    useCreateEntity<IOfferingRes, IOfferingReqSchema>(
      'productCatalogOfferings',
      { onError: (error) => setError(error) },
    );
  const { mutateAsync: updateOffering, isLoading: isUpdateOfferingLoading } =
    useUpdateEntity<IOfferingRes, IOfferingReqSchema>(
      'productCatalogOfferings',
      { onError: (error) => setError(error) },
    );

  return {
    isFetching: isOfferingLoading,
    loading: isCreateOfferingLoading || isUpdateOfferingLoading,
    offering,
    error,
    fetchOffering,
    createOffering,
    updateOffering,
  };
};

export default useOffering;
