import { ReactNode } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import {
  MCustomMultiSelect,
  MCustomNumberInput,
  MCustomSelect,
  MInput,
} from '~app/components/Monetize';
import { DatePicker } from '~app/components/Monetize/DatePicker/DatePicker';
import {
  GuidedQuotingVariables,
  QuestionCompareFromTypes,
  QuestionCompareToConstant,
} from '~app/constants/guidedQuoting';
import {
  GuidedQuotingReq,
  QuestionCompareFromEnum,
  QuestionFilterByEnum,
  QuestionReq,
  QuestionTypesEnum,
} from '~app/types';
import { objectToSelectOptions } from '~app/utils/misc';
import { referenceOptions } from './guidedQuoting.utils';

interface CompareToProps {
  responseType: QuestionTypesEnum;
  filterBy: QuestionFilterByEnum;
  compareFrom: QuestionCompareFromEnum | null | undefined;
  field: ControllerRenderProps<GuidedQuotingReq, any>;
  questions: QuestionReq[];
  index: number;
}

const GuidedQuotingCompareTo = ({
  responseType,
  filterBy,
  compareFrom,
  field,
  questions,
  index,
}: CompareToProps): ReactNode => {
  if (filterBy === 'CONSTANT') {
    const type =
      QuestionCompareToConstant[
        compareFrom ??
          (responseType as Exclude<
            QuestionTypesEnum,
            | QuestionTypesEnum.PRODUCT
            | QuestionTypesEnum.OFFERING
            | QuestionTypesEnum.RATE
            | QuestionTypesEnum.TEXT
            // | QuestionTypesEnum.BOOLEAN
            | QuestionTypesEnum.CURRENCY
            | QuestionTypesEnum.LEGAL_ENTITY
          >)
      ];

    if (type.type === 'MULTI') {
      if (type.itemsType === 'OBJECT') {
        return (
          <MCustomMultiSelect
            mt="0 !important"
            showQueryInput
            closeButtonText="Apply"
            items={objectToSelectOptions(type.items)}
            {...field}
            isLazy
            multiple
            inputProps={{
              _placeholder: { color: 'tPurple.dark' },
              _disabled: {
                _placeholder: {
                  color: 'tGray.darkPurple',
                },
              },
            }}
          />
        );
      } else if (type.itemsType === 'REF') {
        return (
          <MCustomMultiSelect
            mt="0 !important"
            showQueryInput
            closeButtonText="Apply"
            {...field}
            {...referenceOptions(compareFrom ?? responseType)}
            isLazy
            multiple
            inputProps={{
              _placeholder: { color: 'tPurple.dark' },
              _disabled: {
                _placeholder: {
                  color: 'tGray.darkPurple',
                },
              },
            }}
          />
        );
      }
    } else if (type.type === 'SELECT') {
      if (type.itemsType === 'OBJECT') {
        return (
          <MCustomSelect items={objectToSelectOptions(type.items)} {...field} />
        );
      } else if (type.itemsType === 'REF') {
        return (
          <MCustomMultiSelect
            mt="0 !important"
            {...field}
            {...referenceOptions(compareFrom ?? responseType)}
          />
        );
      }
    } else if (type.type === 'DATE') {
      return <DatePicker {...field} />;
    } else if (type.type === 'NUMBER') {
      return <MCustomNumberInput {...field} />;
    } else if (type.type === 'TEXT') {
      return <MInput {...field} />;
    }
  } else if (filterBy === 'QUESTION') {
    const otherQuestions = questions.filter(
      (question) => question.questionNumber < index + 1,
    );
    const questionOptions: Record<string, string> = Object.assign(
      {},
      ...otherQuestions
        .filter(
          (question) =>
            question.type ===
            QuestionCompareFromTypes[compareFrom ?? responseType],
        )
        .map((question) => ({ [question.id]: question.questionText })),
    );
    return (
      <MCustomSelect
        items={objectToSelectOptions(questionOptions)}
        {...field}
      />
    );
  } else if (filterBy === 'VARIABLE') {
    return (
      <MCustomSelect
        items={objectToSelectOptions(GuidedQuotingVariables)}
        {...field}
      />
    );
  } else {
    return null;
  }

  return null;
};

export default GuidedQuotingCompareTo;
