import { Switch, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { Control, Controller, FormState } from 'react-hook-form';
import {
  MCustomSelect,
  MFlex,
  MFormField,
  MFormFieldProps,
} from '~app/components/Monetize';
import { useFlags } from '~app/services/launchDarkly';
import { IQuoteTemplateConfigRes } from '~app/types';
import { useQuoteTemplatesList } from '../../../hooks/useQuoteTemplatesList';

interface IQuoteTemplateFormProps {
  source: 'tenant' | 'quote';
  control: Control<IQuoteTemplateConfigRes>;
  errors: FormState<IQuoteTemplateConfigRes>['errors'];
  isDisabled?: boolean;
  requireSigningPage?: boolean;
  handleSubmit: React.FormEventHandler<HTMLFormElement>;
}
export const QuoteTemplateForm = ({
  source = 'tenant',
  control,
  errors,
  isDisabled,
  requireSigningPage,
  handleSubmit,
}: IQuoteTemplateFormProps) => {
  const baseFieldProps: MFormFieldProps = {
    as: MFlex,
    alignItems: 'center',
    justifyContent: 'space-between',
    labelProps: { m: 0, fontWeight: 'normal' },
  };

  const fieldProps: MFormFieldProps = {
    ...baseFieldProps,
    py: 3,
    borderBottom: '1px',
    borderBottomColor: 'tGray.back',
  };
  const { hideBillingSchedule, useAmendmentV2 } = useFlags();
  const { quoteTemplates } = useQuoteTemplatesList();

  return (
    <>
      <form
        id="customize-quoteTemplate-form"
        data-testid="customize-quoteTemplate-form"
        style={{ marginTop: '0' }}
        onSubmit={handleSubmit}
      >
        <>
          <MFormField label="Template" maxW={56} pt={3}>
            <Controller
              name="defaultTemplate"
              control={control}
              render={({ field: { ...rest } }) => (
                <MCustomSelect
                  items={quoteTemplates}
                  isDisabled={source !== 'tenant' || isDisabled}
                  {...rest}
                />
              )}
            />
          </MFormField>

          <MFlex
            flexDir="column"
            justifyContent="space-between"
            alignItems="center"
            w="100%"
          >
            {!hideBillingSchedule && (
              <MFormField
                label="Show Billing Schedule"
                {...fieldProps}
                error={errors?.showBillingSchedule}
                tooltip="Hides 'Billing Schedule' and 'Billing Frequency' from display on Quote Documents"
              >
                <Controller
                  name="showBillingSchedule"
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <Switch
                      isChecked={value}
                      {...rest}
                      isDisabled={isDisabled}
                    />
                  )}
                />
              </MFormField>
            )}

            <MFormField
              label="Show Credits"
              {...fieldProps}
              error={errors?.showCredits}
              tooltip="Show the credits that are created as part of a upgrade, downgrade, removal of an offering, or other actions that create credits"
            >
              <Controller
                name="showCredits"
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <Switch isChecked={value} {...rest} isDisabled={isDisabled} />
                )}
              />
            </MFormField>

            <MFormField
              label="Show Discount Section"
              {...fieldProps}
              error={errors?.showDiscount}
            >
              <Controller
                name="showDiscount"
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <Switch isChecked={value} {...rest} isDisabled={isDisabled} />
                )}
              />
            </MFormField>

            <MFormField
              label="Show Price per Unit"
              {...fieldProps}
              error={errors?.showPricePerUnit}
            >
              <Controller
                name="showPricePerUnit"
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <Switch isChecked={value} {...rest} isDisabled={isDisabled} />
                )}
              />
            </MFormField>

            <MFormField
              label="Show Pricing"
              {...fieldProps}
              error={errors?.showPricing}
            >
              <Controller
                name="showPricing"
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <Switch isChecked={value} {...rest} isDisabled={isDisabled} />
                )}
              />
            </MFormField>

            {useAmendmentV2 && (
              <MFormField
                label="Show Prior Contract in Amendment PDF"
                {...fieldProps}
                error={errors?.showPriorContractOnAmendments}
              >
                <Controller
                  name="showPriorContractOnAmendments"
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <Switch
                      isChecked={value}
                      {...rest}
                      isDisabled={isDisabled}
                    />
                  )}
                />
              </MFormField>
            )}

            <MFormField
              label="Show Product Description"
              {...fieldProps}
              error={errors?.showProductDescription}
            >
              <Controller
                name="showProductDescription"
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <Switch
                    data-testid="showProductDescription-switch"
                    isChecked={value}
                    {...rest}
                    isDisabled={isDisabled}
                  />
                )}
              />
            </MFormField>

            <MFormField
              label="Show Proration"
              {...fieldProps}
              error={errors?.showProration}
              tooltip="Show the prorated period for each offering, and scheduled change"
              tooltipSize="sm"
            >
              <Controller
                name="showProration"
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <Switch isChecked={value} {...rest} isDisabled={isDisabled} />
                )}
              />
            </MFormField>

            <MFormField {...fieldProps} label="Show Rate Description">
              <Controller
                name={
                  source === 'tenant'
                    ? 'showRateDescription'
                    : 'showOfferingDescription'
                }
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <Switch
                    data-testid={
                      source === 'tenant'
                        ? 'showRateDescription-switch'
                        : 'showOfferingDescription-switch'
                    }
                    isChecked={value}
                    {...rest}
                    isDisabled={isDisabled}
                  />
                )}
              />
            </MFormField>

            {/* Show as true if quote.requiresEsign regardless of actual value */}
            <Tooltip
              label={
                requireSigningPage
                  ? 'Signing Sheet will always show because eSign is configured.'
                  : undefined
              }
              size="sm"
            >
              <MFormField {...fieldProps} label="Show Signing Page">
                <Controller
                  name="showSigningPage"
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <Switch
                      isChecked={requireSigningPage ? true : value}
                      {...rest}
                      isDisabled={isDisabled || requireSigningPage}
                    />
                  )}
                />
              </MFormField>
            </Tooltip>

            <MFormField
              label="Show Unused Quote Items"
              {...baseFieldProps}
              pt={3}
              error={errors?.showUnusedItems}
              tooltip="Display Quote Items with 0 quantity and 0 amount."
              tooltipSize="xs"
            >
              <Controller
                name="showUnusedItems"
                control={control}
                render={({ field: { value, ...rest } }) => (
                  <Switch isChecked={value} {...rest} isDisabled={isDisabled} />
                )}
              />
            </MFormField>
          </MFlex>
        </>
      </form>
    </>
  );
};
