import { useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { formatISO } from 'date-fns/formatISO';
import isEmpty from 'lodash/isEmpty';
import { FC, useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useCreateAccount } from '~app/api/accountsService';
import { handleApiErrorToast } from '~app/api/axios';
import { doGetAccountContacts } from '~app/api/contactsService';
import {
  cpqServiceQueryKeys,
  doGetContractsByAccount,
  useGetOrCreateOpportunityWithCrmLink,
} from '~app/api/cpqService';
import { useGetListData } from '~app/api/queryUtils';
import {
  ILegalEntitiesSelector,
  selectLegalEntities,
} from '~app/api/selectors';
import { MPageLoader } from '~app/components/Monetize';
import { ROUTES } from '~app/constants';
import { INITIAL_FORM_VALUES } from '~app/constants/quotes';
import { getQuoteEditRoute } from '~app/constants/routes';
import { useNetTerms, useQuote } from '~app/hooks';
import { useBackNavigate } from '~app/hooks/useBackNavigate';
import { useIsTenantEsignConfiguredWithLoading } from '~app/hooks/useIsTenantEsignConfigured';
import { logger } from '~app/services/logger';
import {
  ContactStatusEnum,
  ContractStatusEnum,
  CrmOpportunityForInput,
  DEFAULT_PAGER,
  IAccountRespSchema,
  IAccountSchema,
  ICustomFieldRecordSchema,
  IQuoteRequestSchema,
  IQuoteRequestSchemaExtended,
  IQuoteRespSchema,
  Maybe,
  QuoteRequestSchema,
} from '~app/types';
import { ILegalEntityResponseSchema } from '~app/types/legalEntityTypes';
import { nullifyEmptyStrings } from '~app/utils/misc';
import { useDocumentHead } from '~services/documentHead';
import { useActiveGuidedQuotingProcess } from '../../../api/guidedQuotingService';
import Sentry from '../../../services/sentry';
import { NewGuidedQuoteModal } from './components/guidedQuote/NewGuidedQuoteModal';
import {
  NewQuoteForm,
  useNewQuoteFormData,
} from './components/NewQuoteForm/NewQuoteForm';
import { getDefaultEsignIds, getQuoteDescription } from './quoteUtils';

export const NewQuoteFormPage: FC = () => {
  const { accountId: accountIdParam = '' } = useParams();
  const [searchParams] = useSearchParams();
  const { navigate, navigateBack } = useBackNavigate(1);
  const { setDocTitle } = useDocumentHead();
  const queryClient = useQueryClient();
  const { accountById, fetchAccountById, isOpen, onOpen } =
    useNewQuoteFormData();
  const { isOpen: isGuidedSellingOpen, onOpen: onGuidedSellingOpen } =
    useDisclosure();
  const { mutateAsync: doGetOrCreateOpportunity } =
    useGetOrCreateOpportunityWithCrmLink();
  const { createQuote, updateQuoteContacts } = useQuote();
  const { defaultNetTerm } = useNetTerms();
  const [account, setAccount] = useState<IAccountRespSchema | null>(null);
  const [accountName, setAccountName] = useState<string>('');
  const { isLoading: isLoadingEsignConfig, isTenantEsignConfigured } =
    useIsTenantEsignConfiguredWithLoading();

  const { isLoading: isGuidedQuotingLoading, data: guidedQuotingProcess } =
    useActiveGuidedQuotingProcess();

  /** These parameters are set from Quotes created via CRM */
  const hasSkipModalSearchParam = searchParams.has('skipModal');
  const opportunityIdSearchParam = searchParams.get('opportunityId') as string;
  const contactIdSearchParam = searchParams.get('contactId') as string;
  const quoteFieldMapping = searchParams.get('fieldMapping') as string;
  let parsedFieldMapping: Record<string, any> = {};

  if (quoteFieldMapping) {
    try {
      parsedFieldMapping = JSON.parse(quoteFieldMapping);
    } catch (error) {
      logger.error('unable to parse quote field mapping\t', error);
    }
  }

  const { data: legalEntitiesData } = useGetListData<
    ILegalEntityResponseSchema,
    ILegalEntitiesSelector
  >(
    'legalEntities',
    {
      config: DEFAULT_PAGER,
    },
    {
      select: selectLegalEntities,
      refetchOnWindowFocus: false,
    },
  );

  const { setValue, getValues, ...formMethods } =
    useForm<IQuoteRequestSchemaExtended>({
      mode: 'onChange',
      resolver: zodResolver(QuoteRequestSchema),
      defaultValues: {
        contractStartDate: formatISO(new Date(), { representation: 'date' }),
        ...INITIAL_FORM_VALUES,
        legalEntityId: parsedFieldMapping.legalEntityId,
      },
    });

  useEffect(() => {
    if (defaultNetTerm) {
      setValue('netTerms', defaultNetTerm.value);
    }
  }, [defaultNetTerm, setValue]);

  useEffect(() => {
    setDocTitle('New Quote');
  }, [setDocTitle]);

  useEffect(() => {
    !isLoadingEsignConfig && !isGuidedQuotingLoading && initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingEsignConfig, isGuidedQuotingLoading]);

  const { mutateAsync: doCreateAccount } = useCreateAccount();
  /**
   * Initialized everything needed for the form
   * Optionally skip opening the modal based on query parameters
   * Paths:
   * * User creating quote with no account
   * * User creating quote from an existing account
   * * User creating quote from CRM with existing account and opportunity, no contract
   *   * In this case skipModal=true and the modal will not be shown
   * * User creating quote from CRM with existing account and opportunity, existing contracts
   *   * In this case skipModal=true, but the modal is shown to the user to select the correct action NEW/AMEND/RENEW and select the correct contract
   */
  async function initialize() {
    try {
      let skipModal = hasSkipModalSearchParam;
      if (opportunityIdSearchParam) {
        setValue('opportunityId', opportunityIdSearchParam);
      }

      if (accountIdParam) {
        await fetchAndSetAccount();
      }

      // If there are active contracts, then don't skip the modal
      if (hasSkipModalSearchParam && accountIdParam) {
        const contracts = await doGetContractsByAccount(
          accountIdParam,
          { rows: 1 },
          {
            status: {
              in: [
                ContractStatusEnum.ACTIVE,
                ContractStatusEnum.PENDING,
                ContractStatusEnum.FINISHED,
              ],
            },
          },
        );
        skipModal = contracts.totalElements === 0;
      }

      // let user opt-in to guided quoting if they want
      if (hasSkipModalSearchParam && guidedQuotingProcess) {
        skipModal = false;
      }

      if (skipModal) {
        handleCreateQuote();
      } else {
        onOpen();
      }
    } catch (ex) {
      handleApiErrorToast(ex);
      if (hasSkipModalSearchParam) {
        navigate(ROUTES.SALES_LANDING, { replace: true });
      }
    }
  }

  // Get account and update form values
  async function fetchAndSetAccount() {
    const account = await fetchAccountById(accountIdParam);
    if (account) {
      setAccount(account as unknown as IAccountRespSchema);
      setAccountName(account?.accountName || '');
      setValue('accountId', accountIdParam);
      setValue('description', getQuoteDescription(account?.accountName));
      setValue(
        'legalEntityId',
        parsedFieldMapping.legalEntityId ||
          account.defaultLegalEntityId ||
          legalEntitiesData?.defaultLegalEntity?.id ||
          '',
      );
    }

    if (account?.defaultCurrency) {
      setValue('currency', account.defaultCurrency);
    }
    return account;
  }

  function handleCancel() {
    navigateBack();
  }

  /**
   * For guided selling, update the payload to apply any CRM mapped fields
   */
  const getGuidedSellingInitialQuoteFieldValues = () => {
    const crmFieldMapping = structuredClone(parsedFieldMapping || {});
    delete crmFieldMapping['contacts.esign'];

    return crmFieldMapping as Partial<IQuoteRequestSchema>;
  };

  /**
   * Create a new quote and navigate to the edit page
   *
   */
  const handleCreateQuote = async ({
    crmOpportunity,
    customFields,
    useGuidedSelling = false,
  }: {
    crmOpportunity?: Maybe<CrmOpportunityForInput>;
    customFields?: ICustomFieldRecordSchema;
    useGuidedSelling?: boolean;
  } = {}) => {
    try {
      let { contractId, quoteType, ...quoteRequest } = getValues();

      // Create opportunity if user selected to create one from the CRM
      if (crmOpportunity?.id) {
        await doGetOrCreateOpportunity({
          customId: crmOpportunity.id,
          createPayload: {
            accountId: quoteRequest.accountId!,
            name: crmOpportunity.name,
            customId:
              crmOpportunity.id ||
              `Quote for ${accountName || quoteRequest.accountId!}`,
          },
        });
      }

      // account property is added from accountForm
      // but should not be in the payload since accountId will always be set
      if (quoteRequest.hasOwnProperty('account')) {
        setAccount(quoteRequest.account as unknown as IAccountRespSchema);
        (quoteRequest as any).account = undefined;
      }

      if (useGuidedSelling) {
        onGuidedSellingOpen();
        return;
      }

      quoteRequest.customFields = customFields || {};
      const eSignContactFromCrm = parsedFieldMapping?.['contacts.esign'];
      if (!isEmpty(parsedFieldMapping)) {
        parsedFieldMapping?.['contacts.esign'] &&
          delete parsedFieldMapping['contacts.esign'];
        quoteRequest = { ...quoteRequest, ...parsedFieldMapping };
      }
      const createdQuote = await createQuote(quoteRequest);
      if (createdQuote) {
        await setQuoteContacts(
          createdQuote,
          contactIdSearchParam,
          createdQuote.accountId,
          eSignContactFromCrm,
        );

        queryClient.setQueryData(
          cpqServiceQueryKeys.quotesById(createdQuote.id),
          createdQuote,
        );

        navigate(getQuoteEditRoute(createdQuote.id), { replace: true });
      }
    } catch (error) {
      handleApiErrorToast(error);
      if (hasSkipModalSearchParam) {
        navigate(ROUTES.SALES_LANDING, { replace: true });
      }
    }
  };

  /**
   * Create a new account if the user selected that option
   */
  const createNewAccount = useCallback(async (newAccount: IAccountSchema) => {
    const quote = getValues();
    const createdAccount = await doCreateAccount(
      nullifyEmptyStrings(newAccount),
    );
    quote.accountId = createdAccount.id;
    quote.description = getQuoteDescription(createdAccount?.accountName);
    quote.currency = createdAccount.defaultCurrency;
    setAccount(createdAccount);
    setAccountName(createdAccount.accountName);
    return createdAccount;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * After creating a quote, optionally add a contact to the quote
   * If a contactId is provided, it will be used (from queryParam or the primary billing contact)
   * Else, if the already exists, if there is exactly 1 active contact on the account, use that for the quote
   */
  const setQuoteContacts = useCallback(
    async (
      quote: IQuoteRespSchema,
      contactId: Maybe<string>,
      /** This should be omitted for newly created accounts since they will not have contacts */
      existingAccountId: Maybe<string>,
      /**
       * CRM field mapping may provide an override for the eSign contact id to use here
       */
      eSignContact?: Maybe<string>,
    ) => {
      try {
        let contactIdToSet = contactId;

        if (!contactIdToSet && !eSignContact && existingAccountId) {
          // If no contact provided and there is exactly 1 active contact on the account, use that for the quote
          const activeAccountContacts = (
            await doGetAccountContacts(
              existingAccountId,
              { first: 0, rows: 2, page: 0 },
              { status: ContactStatusEnum.ACTIVE },
            )
          ).content;

          if (activeAccountContacts.length === 1) {
            contactIdToSet = activeAccountContacts[0].id;
          }
        }

        if (eSignContact || contactIdToSet) {
          const esignIds = getDefaultEsignIds({
            quote,
            defaultContactId: contactIdToSet,
            internalEsignContactId: eSignContact,
            isTenantEsignConfigured,
          });

          const quoteContacts = await updateQuoteContacts(quote.id, {
            billingId: contactIdToSet || null,
            primaryId: contactIdToSet || null,
            esignIds,
            esignCcIds: [],
          });

          if (quoteContacts) {
            quote.contacts = quoteContacts;
          }
        }
      } catch (ex) {
        handleApiErrorToast(new Error('Unable to set quote contacts'));
      }
    },
    [isTenantEsignConfigured, updateQuoteContacts],
  );

  return (
    <FormProvider setValue={setValue} getValues={getValues} {...formMethods}>
      {!isOpen && !isGuidedSellingOpen && <MPageLoader />}
      {isOpen && !isGuidedSellingOpen && (
        <NewQuoteForm
          isOpen
          hasGuidedSellingProcess={!!guidedQuotingProcess}
          fixedAccount={accountById}
          existingOpportunityId={opportunityIdSearchParam}
          legalEntities={legalEntitiesData?.legalEntities || []}
          onClose={handleCancel}
          closeAndContinue={handleCreateQuote}
          onCreateNewAccount={createNewAccount}
          crmCustomFields={parsedFieldMapping.customFields}
        />
      )}
      {!!guidedQuotingProcess && isGuidedSellingOpen && account && (
        <NewGuidedQuoteModal
          account={account}
          initialQuoteData={getValues()}
          onCancel={navigateBack}
          getInitialQuoteFieldValues={getGuidedSellingInitialQuoteFieldValues}
          onFinished={async (quote) => {
            try {
              const eSignContactFromCrm =
                parsedFieldMapping?.['contacts.esign'];
              await setQuoteContacts(
                quote,
                contactIdSearchParam,
                quote.accountId,
                eSignContactFromCrm,
              );
            } catch (ex) {
              logger.error('Error setting quote contacts', ex);
              Sentry.captureException(ex, { tags: { type: 'QUOTE_CREATION' } });
            } finally {
              navigate(getQuoteEditRoute(quote.id), { replace: true });
            }
          }}
          onManualQuote={handleCreateQuote}
        />
      )}
    </FormProvider>
  );
};
