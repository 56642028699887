import { ButtonProps } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useUpdatePoNumberOnQuote } from '~api/accountsService';
import { handleApiErrorToast } from '~app/api/axios';
import {
  MBox,
  MButton,
  MFormField,
  MLink,
  MText,
} from '~app/components/Monetize';
import MEditableInput from '~app/components/Monetize/MEditableInput';
import { useToast } from '~app/services/toast';
import { IQuoteRespSchema } from '~app/types';
import { getQuoteStatus } from '~app/utils';
import { IInvoiceAddPoNumberSchema, InvoiceAddPoNumberSchema } from '~types';

export const AddPoNumberSectionV2 = ({
  quote,
  setQuote,
  setIsQuoteSaving,
  buttonProps,
}: {
  quote: IQuoteRespSchema;
  setQuote: (quote: IQuoteRespSchema | null) => void;
  setIsQuoteSaving: (val: boolean) => void;
  buttonProps?: ButtonProps;
}) => {
  const { mutateAsync: doUpdatePoNumber } = useUpdatePoNumberOnQuote();
  const { isReview, isApproved, isDraft } = getQuoteStatus(quote);
  const canEditPONumber = isReview || isApproved || isDraft;

  const [loading, setLoading] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const { addToast } = useToast();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IInvoiceAddPoNumberSchema>({
    resolver: zodResolver(InvoiceAddPoNumberSchema),
    mode: 'onChange',
    defaultValues: { poNumber: '' },
  });

  useEffect(() => {
    reset({ poNumber: quote.purchaseOrderNumber || '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote]);

  const updatePONumber = async (poNumber?: string) => {
    try {
      await doUpdatePoNumber({
        quoteId: quote.id,
        poNumber: poNumber,
      });
      setQuote({ ...quote, purchaseOrderNumber: poNumber });
    } catch (err) {
      addToast({
        summary: 'Error',
        detail:
          'Failed to update purchase order number. Please try again later.',
        severity: 'error',
      });
    }
  };

  const onSubmit = async (formData: IInvoiceAddPoNumberSchema) => {
    setLoading(true);
    setIsQuoteSaving(true);
    try {
      await updatePONumber(formData.poNumber);
    } catch (err) {
      handleApiErrorToast(err);
    }
    setLoading(false);
    setEditMode(false);
    setIsQuoteSaving(false);
  };

  if (!canEditPONumber) {
    return (
      <MBox minW="100px" textAlign="right">
        {quote.purchaseOrderNumber ? (
          <MText color="tGray.darkPurple">{quote.purchaseOrderNumber}</MText>
        ) : (
          <MText fontStyle="italic">Blank</MText>
        )}
      </MBox>
    );
  }

  if (!editMode && !quote.purchaseOrderNumber) {
    return (
      <MBox>
        <MButton
          variant="tertiary"
          onClick={() => setEditMode(true)}
          size="sm"
          {...buttonProps}
        >
          + Add
        </MButton>
      </MBox>
    );
  }

  return (
    <MFormField
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      error={errors?.poNumber}
      formErrorMessageProps={{
        display: 'none',
      }}
    >
      <Controller
        control={control}
        name="poNumber"
        render={({ field: { onChange, value, ...rest } }) => (
          <MEditableInput
            minH={8}
            isDisabled={!canEditPONumber && loading}
            placeholder="PO Number"
            value={value || ''}
            showCancel
            showSave={!errors?.poNumber}
            startWithEditView={!quote.purchaseOrderNumber}
            onChange={onChange}
            onCancel={(currValue) => {
              setEditMode(false);
              onChange(currValue);
              updatePONumber();
            }}
            onSubmit={(currValue) => {
              onChange(currValue);
              handleSubmit(onSubmit)();
            }}
            previewProps={
              editMode
                ? {
                    fontSize: 'sm',
                    borderColor: 'tGray.lightPurple',
                    minHeight: '2rem',
                    minWidth: '8rem',
                    color: 'tPurple.base',
                  }
                : {
                    _hover: {
                      borderColor: 'transparent',
                    },
                    fontSize: 'sm',
                    minHeight: '2rem',
                    minWidth: '8rem',
                    color: 'tPurple.base',
                    paddingLeft: 0,
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }
            }
            inputProps={{
              value: value || '',
              w: 'auto',
            }}
            showEdit={true}
            {...rest}
          />
        )}
      />
    </MFormField>
  );
};
