import {
  AggregationModelEnum,
  IOfferingReqSchema,
  OfferingStatusEnum,
  OfferingTypesEnum,
  RateBillingFrequencyEnum,
  RateStatusEnum,
  RateUsageBillingFrequencyEnum,
  SubscriptionTimingEnum,
} from '~app/types';
import { PriceModelEnum } from '~app/types/priceTypes';

import { objectToSelectOptions } from '~app/utils/misc';

export const OFFERING_TYPES_ENUM_DISPLAY: {
  [key in OfferingTypesEnum]: string;
} = {
  SUBSCRIPTION: 'Subscription',
  ONETIME: 'Onetime',
  MIN_COMMIT: 'Minimum Commit',
  CUSTOM_PERCENT_OF_TOTAL: 'Percent of Total',
};

// for displaying as the right-hand label in certain select elements:
export const OFFERING_TYPES_LABEL_DISPLAY: {
  [key in OfferingTypesEnum]: string;
} = {
  SUBSCRIPTION: 'Subscription',
  ONETIME: 'Onetime',
  MIN_COMMIT: 'Minimum Commit',
  CUSTOM_PERCENT_OF_TOTAL: 'Percent of Total',
};

export const OFFERING_TYPES_INPUT_MESSAGES: {
  [key in OfferingTypesEnum]: string;
} = {
  SUBSCRIPTION:
    'This offering type allows subscriptions, usage, and bundles of different types of products.',
  ONETIME:
    'This Offering Type is only for Onetime products. You will not be able to set the billing frequency.',
  MIN_COMMIT:
    'This Offering Type is only for one Minimum Commit Product. Rate billing and usage frequency can only be monthly.',
  CUSTOM_PERCENT_OF_TOTAL:
    'This offering type allows subscriptions and Onetime products.',
};

// Default Offering form values
export const defaultOfferingCreateValues: IOfferingReqSchema = {
  name: '',
  description: '',
  status: OfferingStatusEnum.ACTIVE,
  startDate: '',
  endDate: '',
  productIds: [],
  type: OfferingTypesEnum.SUBSCRIPTION,
};

export const OFFERING_STATUS_DISPLAY: {
  [key in OfferingStatusEnum]: string;
} = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  CANCELED: 'Canceled',
  PENDING: 'Pending',
  EXPIRED: 'Expired',
  ARCHIVED: 'Archived',
};

export const OFFERING_STATUS_FORM_OPTIONS = objectToSelectOptions(
  (({ ACTIVE, INACTIVE }) => ({ ACTIVE, INACTIVE }))(OFFERING_STATUS_DISPLAY),
);

export const RATE_USAGE_BILLING_FREQUENCY_DISPLAY: {
  [key in RateUsageBillingFrequencyEnum]: string;
} = {
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  SEMIANNUALLY: 'Semi-annually',
  ANNUALLY: 'Annually',
};

export const RATE_MIN_COMMIT_USAGE_FREQUENCY_OPTIONS = objectToSelectOptions(
  (({ MONTHLY, QUARTERLY, ANNUALLY, SEMIANNUALLY }) => ({
    MONTHLY,
    QUARTERLY,
    ANNUALLY,
    SEMIANNUALLY,
  }))(RATE_USAGE_BILLING_FREQUENCY_DISPLAY),
);
export const RATE_NON_MIN_COMMIT_USAGE_FREQUENCY_OPTIONS =
  objectToSelectOptions(
    (({ MONTHLY, QUARTERLY, ANNUALLY, SEMIANNUALLY }) => ({
      MONTHLY,
    }))(RATE_USAGE_BILLING_FREQUENCY_DISPLAY),
  );

export const RATE_USAGE_BILLING_FREQUENCY_UI_OPTIONS = objectToSelectOptions(
  (({ MONTHLY }) => ({ MONTHLY, NONE: 'None' }))(
    RATE_USAGE_BILLING_FREQUENCY_DISPLAY,
  ),
);

export const getRateUsageBillingFrequencyDisplay = (
  frequency?: RateUsageBillingFrequencyEnum | null,
): string => {
  if (!frequency) {
    return 'None';
  } else {
    return RATE_USAGE_BILLING_FREQUENCY_DISPLAY[frequency];
  }
};

export const getUsageFrequencyItems = (type: OfferingTypesEnum) => {
  switch (type) {
    case OfferingTypesEnum.MIN_COMMIT:
      return RATE_MIN_COMMIT_USAGE_FREQUENCY_OPTIONS;
    default:
      return RATE_NON_MIN_COMMIT_USAGE_FREQUENCY_OPTIONS;
  }
};

export const getBillingFrequencyItems = (type: OfferingTypesEnum) => {
  switch (type) {
    case OfferingTypesEnum.SUBSCRIPTION:
      return RATE_SUBSCRIPTION_BILLING_FREQUENCY_OPTIONS;
    case OfferingTypesEnum.MIN_COMMIT:
      return RATE_MIN_COMMIT_BILLING_FREQUENCY_OPTIONS;
    case OfferingTypesEnum.ONETIME:
      return RATE_ONETIME_BILLING_FREQUENCY_OPTIONS;
    case OfferingTypesEnum.CUSTOM_PERCENT_OF_TOTAL:
      return RATE_POT_BILLING_FREQUENCY_OPTIONS;
    default:
      return [];
  }
};

export const RATE_BILLING_FREQUENCY_DISPLAY: {
  [key in RateBillingFrequencyEnum]: string;
} = {
  DAILY: 'Daily',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  SEMIANNUALLY: 'Semi-annually',
  ANNUALLY: 'Annually',
  CUSTOM: 'Custom',
  ONETIME: 'Onetime',
};

export type FrequencyMapReturn = {
  label: string;
  btnLabel: string;
  month?: number | null;
  value: string;
};

export const RATE_BILLING_FREQUENCY_MAP: {
  [key in RateBillingFrequencyEnum]: (
    val?: number | null,
  ) => FrequencyMapReturn;
} = {
  DAILY: () => ({
    label: 'Daily',
    btnLabel: 'Daily (0)',
    month: 0,
    value: RateBillingFrequencyEnum.DAILY,
  }),
  MONTHLY: () => ({
    label: 'Monthly',
    btnLabel: 'Monthly (1)',
    month: 1,
    value: RateBillingFrequencyEnum.MONTHLY,
  }),
  QUARTERLY: () => ({
    label: 'Quarterly',
    btnLabel: 'Quarterly (3)',
    month: 3,
    value: RateBillingFrequencyEnum.QUARTERLY,
  }),
  SEMIANNUALLY: () => ({
    label: 'Semi-Annually',
    btnLabel: 'Semi-Annually (6)',
    month: 6,
    value: RateBillingFrequencyEnum.SEMIANNUALLY,
  }),
  ANNUALLY: () => ({
    label: 'Annually',
    btnLabel: 'Annually (12)',
    month: 12,
    value: RateBillingFrequencyEnum.ANNUALLY,
  }),
  CUSTOM: (frequency) => {
    let label: string = '';
    if (frequency) {
      if (frequency % 12 === 0) {
        label = `Every ${frequency / 12} Year(s)`;
      } else {
        label = `Every ${frequency} Month(s)`;
      }
    }

    return {
      label,
      btnLabel: label,
      month: frequency,
      value: RateBillingFrequencyEnum.CUSTOM,
    };
  },
  ONETIME: () => ({
    label: 'Onetime',
    btnLabel: 'Onetime',
    month: null,
    value: RateBillingFrequencyEnum.ONETIME,
  }),
};

export const RATE_BILLING_FREQUENCY_NUMBER_MAP: {
  [key in number]: Omit<FrequencyMapReturn, 'month'> & { month: number };
} = {
  0: {
    label: 'Daily',
    btnLabel: 'Daily (0)',
    month: 0,
    value: RateBillingFrequencyEnum.DAILY,
  },
  1: {
    label: 'Monthly',
    btnLabel: 'Monthly (1)',
    month: 1,
    value: RateBillingFrequencyEnum.MONTHLY,
  },
  3: {
    label: 'Quarterly',
    btnLabel: 'Quarterly (3)',
    month: 3,
    value: RateBillingFrequencyEnum.QUARTERLY,
  },
  6: {
    label: 'Semi-Annually',
    btnLabel: 'Semi-Annually (6)',
    month: 6,
    value: RateBillingFrequencyEnum.SEMIANNUALLY,
  },
  12: {
    label: 'Annually',
    btnLabel: 'Annually (12)',
    month: 12,
    value: RateBillingFrequencyEnum.ANNUALLY,
  },
};

export const RATE_BILLING_FREQUENCY_SHORTCODE_DISPLAY: {
  [key in string]: (frequency?: number | null) => string;
} = {
  MONTHLY: () => 'mo',
  QUARTERLY: () => '3mo',
  SEMIANNUALLY: () => '6mo',
  ANNUALLY: () => 'yr',
  CUSTOM: (frequency) => {
    if (frequency) {
      if (frequency % 12 === 0) {
        return `${frequency / 12}y`;
      } else {
        return `${frequency}mo`;
      }
    }

    return '';
  },
};

export const RATE_SUBSCRIPTION_BILLING_FREQUENCY_OPTIONS =
  objectToSelectOptions(
    (({ MONTHLY, QUARTERLY, ANNUALLY, SEMIANNUALLY }) => ({
      MONTHLY,
      QUARTERLY,
      ANNUALLY,
      SEMIANNUALLY,
    }))(RATE_BILLING_FREQUENCY_DISPLAY),
  );

export const RATE_ONETIME_BILLING_FREQUENCY_OPTIONS = objectToSelectOptions(
  (({ ONETIME }) => ({ ONETIME }))(RATE_BILLING_FREQUENCY_DISPLAY),
);
export const RATE_MIN_COMMIT_BILLING_FREQUENCY_OPTIONS = objectToSelectOptions(
  (({ MONTHLY, QUARTERLY, ANNUALLY, SEMIANNUALLY }) => ({
    MONTHLY,
    QUARTERLY,
    ANNUALLY,
    SEMIANNUALLY,
  }))(RATE_BILLING_FREQUENCY_DISPLAY),
);
export const RATE_POT_BILLING_FREQUENCY_OPTIONS = objectToSelectOptions(
  (({ MONTHLY, QUARTERLY, SEMIANNUALLY, ANNUALLY }) => ({
    MONTHLY,
    QUARTERLY,
    SEMIANNUALLY,
    ANNUALLY,
  }))(RATE_BILLING_FREQUENCY_DISPLAY),
);

export const RATE_STATUS_MODEL: {
  [key in RateStatusEnum]: string;
} = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  CANCELED: 'Canceled',
  PENDING: 'Pending',
  EXPIRED: 'Expired',
};

export const RATE_STATUS_MODEL_DISPLAY_STRING = objectToSelectOptions(
  (({ ACTIVE, INACTIVE }) => ({ ACTIVE, INACTIVE }))(RATE_STATUS_MODEL),
);

export const PRICE_MODEL_OPTIONS: {
  [key in PriceModelEnum]: string;
} = {
  VOLUME: 'Volume',
  TIERED: 'Tiered',
  FLAT: 'Flat',
  CUSTOM: 'Custom',
  PERCENT_OF_TOTAL: 'Percent of Total',
};

export const PRICE_MODEL_OPTIONS_DISPLAY_STRING = objectToSelectOptions(
  (({ VOLUME, TIERED, FLAT, CUSTOM }) => ({ VOLUME, TIERED, FLAT, CUSTOM }))(
    PRICE_MODEL_OPTIONS,
  ),
);

export const PRICE_MODEL_OPTIONS_DISPLAY_STRING_POT = objectToSelectOptions(
  (({ PERCENT_OF_TOTAL }) => ({ PERCENT_OF_TOTAL }))(PRICE_MODEL_OPTIONS),
);

export const DISABLED_TIERED_PRICE_MODEL_OPTIONS = [
  PriceModelEnum.VOLUME,
  PriceModelEnum.FLAT,
  PriceModelEnum.CUSTOM,
  PriceModelEnum.PERCENT_OF_TOTAL,
];

export const DISABLED_CUSTOM_PRICE_MODEL_OPTIONS = [
  PriceModelEnum.VOLUME,
  PriceModelEnum.FLAT,
  PriceModelEnum.TIERED,
  PriceModelEnum.PERCENT_OF_TOTAL,
];

export const DISABLED_FLAT_VOLUME_PRICE_MODEL_OPTIONS = [
  PriceModelEnum.CUSTOM,
  PriceModelEnum.TIERED,
  PriceModelEnum.PERCENT_OF_TOTAL,
];

export const SUBSCRIPTION_TIMING_OPTIONS: {
  [key in SubscriptionTimingEnum]: string;
} = {
  ADVANCE: 'Advance',
  ARREARS: 'Arrears',
};

export const SUBSCRIPTION_TIMING_UI_OPTIONS = objectToSelectOptions(
  SUBSCRIPTION_TIMING_OPTIONS,
);

export const getSubscriptionTimingDisplay = (
  subscriptionTiming?: SubscriptionTimingEnum | null,
): string => {
  if (!subscriptionTiming) {
    return 'None';
  } else {
    return SUBSCRIPTION_TIMING_OPTIONS[subscriptionTiming];
  }
};

export const AGGREGATION_MODEL_OPTIONS: {
  [key in AggregationModelEnum]: string;
} = {
  SUM: 'Sum',
  AVERAGE: 'Monthly Average',
};

export const AGGREGATION_MODEL_UI_OPTIONS = objectToSelectOptions(
  AGGREGATION_MODEL_OPTIONS,
);

export const SUBSCRIPTION_FREQUENCY_TIMING_NONE_OPTION = {
  title: 'None',
  value: 'NONE',
};

export const OFFERING_TYPES_ORDER: {
  [key in OfferingTypesEnum]: number;
} = {
  MIN_COMMIT: 0,
  SUBSCRIPTION: 1,
  ONETIME: 2,
  CUSTOM_PERCENT_OF_TOTAL: 3,
};
