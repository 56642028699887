import { parseISO } from 'date-fns/parseISO';
import { sub } from 'date-fns/sub';
import { Fragment, useEffect, useState } from 'react';
import { handleApiErrorToast } from '~app/api/axios';
import { doGetSubscriptionUsageRatedPerDayMultiple } from '~app/api/subscriptionsService';
import {
  MCustomSelect,
  MDrawer,
  MDrawerBody,
  MDrawerCloseButton,
  MDrawerContent,
  MDrawerHeader,
  MDrawerOverlay,
  MFlex,
  MSkeleton,
  MText,
  MVStack,
} from '~app/components/Monetize';
import { SUBSCRIPTIONS } from '~app/constants';
import {
  IGetSubscriptionSchema,
  ISubscriptionUsageChartData,
  ProductTypeEnum,
  UsagePeriodPointerEnum,
} from '~app/types';
import { sortByAlphabetically } from '~app/utils';
import { toDateShort } from '~app/utils/dates';
import { convertSubscriptionUsageToChartData } from '~app/utils/subscriptions';
import { usageChartOptions } from '../components/SubscriptionOverview/ChartOptions';
import { UsageOverviewChart } from './UsageOverviewChart';

interface UsageOverviewChartDrawerProps {
  accountId: string;
  subscriptionId: string;
  subscription: IGetSubscriptionSchema;
  open: boolean;
  onClose: () => void;
}

export const UsageOverviewChartDrawer = ({
  accountId,
  subscriptionId,
  subscription,
  open,
  onClose,
}: UsageOverviewChartDrawerProps) => {
  const [usagePeriodPointer, setUsagePeriodPointer] =
    useState<UsagePeriodPointerEnum>(UsagePeriodPointerEnum.CURRENT);
  const [usageChartData, setUsageChartData] = useState<
    ISubscriptionUsageChartData[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchChartData = async () => {
    try {
      if (!subscription.subscriptionItems?.length) {
        return;
      }
      setLoading(true);

      const subscriptionItems = subscription.subscriptionItems
        .filter(({ product }) => product.productType === ProductTypeEnum.USAGE)
        .sort((a, b) => sortByAlphabetically()(a.product, b.product));

      const usageData = await doGetSubscriptionUsageRatedPerDayMultiple({
        accountId,
        period: usagePeriodPointer,
        productIds: subscriptionItems.map(({ product }) => product.id),
        subscriptionId,
      });

      setUsageChartData(
        Object.keys(usageData).map(
          (productId, i): ISubscriptionUsageChartData =>
            convertSubscriptionUsageToChartData(
              subscriptionItems[i],
              usageData[productId],
              usageChartOptions,
              usagePeriodPointer === UsagePeriodPointerEnum.PREVIOUS,
            ),
        ),
      );
    } catch (ex) {
      handleApiErrorToast(ex);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (accountId && subscriptionId && subscription) {
      fetchChartData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, subscriptionId, subscription, usagePeriodPointer]);

  return (
    <MDrawer
      autoFocus={false}
      size="lg"
      isOpen={open}
      placement="right"
      onClose={onClose}
      blockScrollOnMount={false}
    >
      <MDrawerOverlay />
      <MDrawerContent>
        <MDrawerCloseButton />
        <MDrawerHeader borderBottomWidth="1px">
          <MText fontSize="inherit" fontWeight="inherit">
            Usage
          </MText>
        </MDrawerHeader>
        <MDrawerBody>
          <MFlex alignItems="center" gap="2" mb="4">
            <MText fontWeight="semibold">Period</MText>
            <MCustomSelect
              w="176"
              items={SUBSCRIPTIONS.UsagePeriodSelectOptions}
              value={usagePeriodPointer}
              onChange={(value: any) => {
                setUsagePeriodPointer(value as UsagePeriodPointerEnum);
              }}
            />
            {usagePeriodPointer === UsagePeriodPointerEnum.CURRENT &&
              subscription?.usagePeriodStart &&
              subscription?.usagePeriodEnd && (
                <MText color="tGray.darkPurple">
                  {toDateShort(subscription.usagePeriodStart)} &mdash;{' '}
                  {toDateShort(subscription.usagePeriodEnd)}
                </MText>
              )}
            {usagePeriodPointer === UsagePeriodPointerEnum.PREVIOUS &&
              subscription?.usagePeriodStart && (
                <MText color="tGray.darkPurple">
                  {toDateShort(
                    sub(parseISO(subscription.usagePeriodStart), { months: 1 }),
                  )}{' '}
                  &mdash;{' '}
                  {toDateShort(
                    sub(parseISO(subscription.usagePeriodStart), { days: 1 }),
                  )}
                </MText>
              )}
          </MFlex>
          <MFlex flexDir="column">
            {loading ? (
              <MVStack spacing="2" px={4}>
                {(subscription.subscriptionItems || [])
                  .filter(
                    ({ product }) =>
                      product.productType === ProductTypeEnum.USAGE,
                  )
                  .map((_, i) => (
                    <Fragment key={i}>
                      <MSkeleton height="32px" w="100%" />
                      <MSkeleton height="300px" w="100%" mb={8} />
                    </Fragment>
                  ))}
              </MVStack>
            ) : (
              usageChartData?.map((data, index) => (
                <UsageOverviewChart
                  key={index}
                  subscriptionUsage={data}
                  accountId={accountId}
                  subscription={subscription}
                  onNewUsageRecord={() => fetchChartData()}
                />
              ))
            )}
          </MFlex>
        </MDrawerBody>
      </MDrawerContent>
    </MDrawer>
  );
};
