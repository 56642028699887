import { FC } from 'react';
import { MBox, MButton, MIcon } from '~app/components/Monetize';
import { useQuoteContext } from '../../Quote/quoteContext';
import { QuoteBasicInfoLine } from '~app/components/Quotes/QuoteFormHeaderLine';
import { MdSettings } from 'react-icons/md';
import { AddPoNumberSectionV2 } from '~app/components/Quotes/AddPoNumberSectionV2';
import { QuoteStatusEnum } from '~app/types';
import { QuoteDocumentsV2 } from '../../Quote/components/quoteDocuments/QuoteDocumentsV2';
import { QuoteReviewCustomRates } from '~app/components/Quotes/QuoteReviewCustomRates';
import { QuoteReviewInfoEsign } from '~app/components/Quotes/QuoteReviewEsign';
import { useQuoteSettings } from '~app/hooks';

interface QuoteReviewInfoOtherProps {
  onOpenContactDrawer: () => void;
}

export const QuoteReviewInfoOther: FC<QuoteReviewInfoOtherProps> = ({
  onOpenContactDrawer,
}: QuoteReviewInfoOtherProps) => {
  const {
    isReadOnly,
    quoteData: { quote, setQuote },
    setIsQuoteSaving,
    handleUpdateQuoteWithFormReset,
  } = useQuoteContext();
  const { quoteSettings } = useQuoteSettings();

  if (!quote) {
    return null;
  }

  return (
    <MBox>
      {quoteSettings?.allowPONumberOnQuotes && (
        <QuoteBasicInfoLine title="PO Number">
          <MBox>
            <AddPoNumberSectionV2
              quote={quote}
              setQuote={setQuote}
              setIsQuoteSaving={setIsQuoteSaving}
              buttonProps={{ mr: -3 }}
            />
          </MBox>
        </QuoteBasicInfoLine>
      )}
      <QuoteBasicInfoLine title="Contacts">
        <MButton
          variant="tertiary"
          onClick={() => {
            onOpenContactDrawer();
          }}
          minW="auto"
          size="sm"
          mr="-3"
        >
          <MIcon as={MdSettings} mr="1" />
          Manage
        </MButton>
      </QuoteBasicInfoLine>
      <QuoteBasicInfoLine title="Documents">
        <QuoteDocumentsV2
          isReadOnly={isReadOnly || quote?.status !== QuoteStatusEnum.DRAFT}
          quote={quote}
          onUpdate={handleUpdateQuoteWithFormReset}
        />
      </QuoteBasicInfoLine>
      {quote.requiresEsign && (
        <QuoteBasicInfoLine title="Will be sent through eSign">
          <QuoteReviewInfoEsign quote={quote} />
        </QuoteBasicInfoLine>
      )}
      <QuoteBasicInfoLine title="Uses Custom Rates">
        <QuoteReviewCustomRates quote={quote} />
      </QuoteBasicInfoLine>
    </MBox>
  );
};
