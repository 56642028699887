import { useParams } from 'react-router-dom';
import { MPageContainer } from '~app/components/Monetize';
import GuidedQuotingForm from './GuidedQuotingForm';

const GuidedQuotingEditPage = () => {
  const { guidedQuotingId = '' } = useParams();

  return (
    <MPageContainer alignItems="stretch">
      <GuidedQuotingForm guidedQuotingId={guidedQuotingId} />
    </MPageContainer>
  );
};

export default GuidedQuotingEditPage;
