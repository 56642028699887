import {
  CustomFieldPermissionsEnum,
  CustomFieldTypeEnum,
  ICustomFieldRecordSchema,
  ICustomFieldResSchema,
} from '../types';

export const sortCustomFields = (
  fieldA: ICustomFieldResSchema,
  fieldB: ICustomFieldResSchema,
) => {
  const fieldAWritable = fieldA.permissions.includes(
    CustomFieldPermissionsEnum.WRITE,
  );
  const fieldBWritable = fieldB.permissions.includes(
    CustomFieldPermissionsEnum.WRITE,
  );
  if (fieldAWritable !== fieldBWritable) {
    return fieldAWritable ? -1 : 1;
  }

  return fieldA.displayLabel
    .toLowerCase()
    .localeCompare(fieldB.displayLabel.toLowerCase());
};

export const handleCustomFieldToDisplayBannerFormat = (
  valueFields: ICustomFieldRecordSchema,
  customFields: ICustomFieldResSchema[],
) => {
  const customFieldList = customFields.sort(sortCustomFields);

  const customFieldToDisplay = customFieldList.map((field) => {
    const fieldKey = field.key;
    const fieldValue = valueFields[fieldKey]?.toString() ?? 'Blank';
    const fieldLabel = field.displayLabel;

    return {
      label: fieldLabel,
      value: fieldValue,
    };
  });

  return customFieldToDisplay;
};

/**
 *
 * @param values
 * @param customFields
 * @returns
 */
export const convertFormValuesToReq = (
  values: ICustomFieldRecordSchema,
  customFields: ICustomFieldResSchema[],
) => {
  const newValues: ICustomFieldRecordSchema = {};

  Object.keys(values || {}).forEach((key) => {
    const field = customFields.find((field) => field.key === key);

    if (field && field.type === CustomFieldTypeEnum.DATE && !values[key]) {
      newValues[key] = null;
    } else {
      newValues[key] = values[key];
    }
  });

  return newValues;
};
