import { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  MBox,
  MButton,
  MCard,
  MDivider,
  MFlex,
  MIcon,
  MPageLoader,
  MText,
} from '~app/components/Monetize';
import { QuoteCollaborationPopoverV2 } from '~app/components/Quotes/QuoteCollaborationPopoverV2';
import { IQuoteRequestSchema, CollaborationAccessEnum } from '~app/types';
import { useQuoteContext } from '../../Quote/quoteContext';
import MEditableInput from '~app/components/Monetize/MEditableInput';
import { QuoteTagsV2 } from '~app/components/Quotes/QuoteTagsV2';
import { MdSettings } from 'react-icons/md';
import { QuoteInternalBillingInfoDetails } from './QuoteInternalBillingInfoDetails';
import { QuoteInternalBillingInfoContacts } from './QuoteInternalBillingInfoContacts';

interface QuoteInternalBillingInfoProps {
  onOpenContactDrawer: () => void;
}
export const QuoteInternalBillingInfo: FC<QuoteInternalBillingInfoProps> = ({
  onOpenContactDrawer,
}: QuoteInternalBillingInfoProps) => {
  const { quoteData } = useQuoteContext();

  const { quote, setQuote } = quoteData;

  const { control, setValue } = useFormContext<IQuoteRequestSchema>();

  if (!quote) {
    return <MPageLoader />;
  }

  return (
    <MCard width={'100%'} p={6} borderColor="tGray.lightPurple">
      <MFlex flexDir={['column', 'column', 'column', 'column', 'row']}>
        <MBox flex="1">
          <MFlex
            borderBottomWidth="1px"
            borderColor="tGray.support"
            alignItems="center"
            justifyContent="space-between"
          >
            <MText fontWeight="600" fontSize="md" lineHeight="2">
              Details
            </MText>
          </MFlex>
          <QuoteInternalBillingInfoDetails />
        </MBox>
        <MBox mx="6" display={['hidden', 'hidden', 'visible']}>
          <MBox w="1px" background="tGray.support" height="100%"></MBox>
        </MBox>
        <MBox flex="1">
          <MFlex
            borderBottomWidth="1px"
            borderColor="tGray.support"
            alignItems="center"
            justifyContent="space-between"
          >
            <MText fontWeight="600" fontSize="md" lineHeight="2">
              Contacts
            </MText>
            <MButton
              variant="tertiary"
              onClick={() => {
                onOpenContactDrawer();
              }}
              minW="auto"
              size="sm"
              mr="-3"
            >
              <MIcon as={MdSettings} mr="1" />
              Manage
            </MButton>
          </MFlex>
          <QuoteInternalBillingInfoContacts />
        </MBox>
      </MFlex>
    </MCard>
  );
};
