import { FC } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import {
  MBox,
  MButton,
  MCard,
  MFlex,
  MIcon,
  MPageLoader,
  MText,
} from '~app/components/Monetize';
import { QuoteCollaborationPopoverV2 } from '~app/components/Quotes/QuoteCollaborationPopoverV2';
import { IQuoteRequestSchema, CollaborationAccessEnum } from '~app/types';
import { useQuoteContext } from '../../Quote/quoteContext';
import MEditableInput from '~app/components/Monetize/MEditableInput';
import { QuoteBasicDetails } from './QuoteBasicDetails';
import { QuoteBasicContacts } from './QuoteBasicContacts';
import { MdSettings } from 'react-icons/md';
import { QuoteBasicContract } from './QuoteBasicContract';
import {
  QuoteStatusEnumDisplay,
  QuoteStatusTagStyle,
} from '~app/constants/quotes';

interface QuoteBasicProps {
  onOpenContactDrawer: () => void;
}
export const QuoteBasic: FC<QuoteBasicProps> = ({
  onOpenContactDrawer,
}: QuoteBasicProps) => {
  const {
    isReadOnly,
    quoteData,
    handleSubmitButton,
    handleSubmitButtonWithoutDirtyCheck,
  } = useQuoteContext();

  const { quote, setQuote } = quoteData;

  const { control, setValue } = useFormContext<IQuoteRequestSchema>();

  if (!quote) {
    return <MPageLoader />;
  }

  return (
    <MCard width={'100%'} p={6} borderColor="tGray.lightPurple" mb="8">
      <MFlex
        justifyContent="space-between"
        alignItems="center"
        borderBottomWidth="1px"
        borderBottomColor="tWhite.400"
        pb="3"
        mb="4"
      >
        <MFlex alignItems="center" ml="-2.5">
          <Controller
            defaultValue=""
            name="description"
            control={control}
            render={({ field: { onChange, value, onBlur, ref, ...rest } }) => (
              <MEditableInput
                aria-label="description"
                value={value || ''}
                onSubmit={(newValue) => {
                  onChange(newValue);
                  onBlur();
                  value && handleSubmitButton();
                }}
                submitOnBlur
                isDisabled={isReadOnly}
                onChange={onChange}
                onBlur={onBlur}
                {...rest}
                previewProps={{
                  fontSize: '2xl',
                  fontWeight: '700',
                }}
                inputProps={{
                  value: value || '',
                  fontSize: '2xl',
                  fontWeight: '700',
                  'data-testid': 'input-description',
                  height: '38px',
                  ...rest,
                }}
              />
            )}
          />

          <MText
            {...QuoteStatusTagStyle[quote.status]}
            ml=".5rem"
            data-testid="quote-tag-status"
          >
            {QuoteStatusEnumDisplay[quote.status]}
          </MText>
        </MFlex>

        <MBox mr="-3">
          <QuoteCollaborationPopoverV2
            quoteId={quote.id}
            quoteStatus={quote.status}
            collaborationAccess={quote.collaborationAccess}
            accessLevel={quote.accessLevel}
            onChange={(collaborationAccess: CollaborationAccessEnum) => {
              setValue('collaborationAccess', collaborationAccess, {
                shouldDirty: true,
              });
              handleSubmitButtonWithoutDirtyCheck();
            }}
          />
        </MBox>
      </MFlex>
      <MFlex flexDir={['column', 'column', 'column', 'column', 'row']}>
        <MBox flex="1">
          <MFlex
            borderBottomWidth="1px"
            borderColor="tGray.support"
            alignItems="center"
            justifyContent="space-between"
          >
            <MText my="2" fontWeight="600" fontSize="md">
              Details
            </MText>
          </MFlex>
          <QuoteBasicDetails />
        </MBox>
        <MBox mx="6" display={['hidden', 'hidden', 'visible']}>
          <MBox w="1px" background="tGray.support" height="100%"></MBox>
        </MBox>
        <MBox flex="1">
          <MFlex
            borderBottomWidth="1px"
            borderColor="tGray.support"
            alignItems="center"
            justifyContent="space-between"
          >
            <MText my="2" fontWeight="600" fontSize="md">
              Contract
            </MText>
          </MFlex>
          <QuoteBasicContract />
        </MBox>
        <MBox mx="6" display={['hidden', 'hidden', 'visible']}>
          <MBox w="1px" background="tGray.support" height="100%"></MBox>
        </MBox>
        <MBox flex="1">
          <MFlex
            borderBottomWidth="1px"
            borderColor="tGray.support"
            alignItems="center"
            justifyContent="space-between"
          >
            <MText my="2" fontWeight="600" fontSize="md">
              Contacts
            </MText>
            <MButton
              variant="tertiary"
              onClick={() => {
                onOpenContactDrawer();
              }}
              minW="auto"
              size="sm"
              mr="-3"
            >
              <MIcon as={MdSettings} mr="1" />
              Manage
            </MButton>
          </MFlex>
          <QuoteBasicContacts />
        </MBox>
      </MFlex>
    </MCard>
  );
};
