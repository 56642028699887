import { FC } from 'react';
import {
  MBox,
  MStatusIDBox,
  MFlex,
  MLink,
  MText,
} from '~app/components/Monetize';
import { MParentLink } from '~app/components/Monetize/MParentLink';
import { ChangeOwnerV2 } from '~app/components/Quotes/ChangeOwnerV2';
import { QuoteBasicInfoLine } from '~app/components/Quotes/QuoteFormHeaderLine';
import { getAccountQuotesRoute } from '~app/constants/routes';
import { QuoteTypeTag } from '../../Quote/components/QuoteTypeTag';
import { useQuoteContext } from '../../Quote/quoteContext';
import QuoteBasicOppotunity from '~app/components/Quotes/QuoteBasicOppotunity';
import { QuoteTypeEnum } from '~app/types';
import { Link } from 'react-router-dom';
import { ROUTES } from '~app/constants';

interface QuoteReviewInfoDetailsProps {}

export const QuoteReviewInfoDetails: FC<
  QuoteReviewInfoDetailsProps
> = ({}: QuoteReviewInfoDetailsProps) => {
  const {
    isReadOnly,
    quoteData: { quote },
    opportunityLinkData,
    handleChangeOwner,
  } = useQuoteContext();

  if (!quote) {
    return null;
  }
  return (
    <MBox>
      <QuoteBasicInfoLine title="Account">
        <MParentLink
          parentLink={getAccountQuotesRoute(quote?.accountId)}
          parentLinkTitle={quote.accountName}
        />
      </QuoteBasicInfoLine>
      <QuoteBasicInfoLine title="Opportunity">
        {opportunityLinkData && (
          <QuoteBasicOppotunity {...opportunityLinkData} />
        )}
      </QuoteBasicInfoLine>
      <QuoteBasicInfoLine title="Owner" inputId="quote-header-owner">
        <ChangeOwnerV2
          inputId="quote-header-owner"
          isReadOnly={isReadOnly}
          value={quote?.owner}
          ownerName={quote?.ownerName}
          onChange={(e) => handleChangeOwner(String(e))}
        />
      </QuoteBasicInfoLine>

      <QuoteBasicInfoLine title="Quote ID">
        <MBox>
          <MStatusIDBox id={quote?.id!} copyUrl displayIcon={false} />
        </MBox>
      </QuoteBasicInfoLine>

      <QuoteBasicInfoLine title="Contract ID">
        {!quote.contractId ? (
          <MText fontStyle="italic">Defined after quote processing</MText>
        ) : (
          <MLink
            fontSize="sm"
            isTruncated
            maxWidth="200px"
            to={ROUTES.getQuoteContractEditRoute(quote.id, quote.contractId!)}
            as={Link}
            replace
          >
            {quote.contractId}
          </MLink>
        )}
      </QuoteBasicInfoLine>

      {[QuoteTypeEnum.AMENDMENT, QuoteTypeEnum.RENEWAL].includes(
        quote.type,
      ) && (
        <QuoteBasicInfoLine title="Type">
          <MFlex alignItems="center">
            <QuoteTypeTag quote={quote} />
          </MFlex>
        </QuoteBasicInfoLine>
      )}
    </MBox>
  );
};
