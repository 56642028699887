import { z } from 'zod';
import { getRequiredMessage } from '~app/utils/messages';
import { IPaymentMethodContactSchema } from './paymentMethodTypes';

export interface IShareInvoiceGenerateSetupIntentResp {
  clientSecret: string;
  publicKey: string;
}

// from POST /api/share/invoices/{invoiceShareSecret}/startPayment
export interface ISharedInvoicePaymentReq {
  amount: number;
  paymentToken: string;
  paymentMethodName: string;
  description?: string;
  billingDetails: IPaymentMethodContactSchema;
}

export const SharedInvoicePaymentSchemaUI = z.object({
  amount: z.number().nonnegative('Must be a positive number'),
  billingDetails: z.object({
    email: z
      .string()
      .email()
      .min(1, { message: getRequiredMessage('Email') }),
    fullName: z.string().min(1, { message: getRequiredMessage('Name') }),
  }),
  stripePublicKey: z.string(),
});

export type ISharedInvoicePaymentUI = z.infer<
  typeof SharedInvoicePaymentSchemaUI
>;
