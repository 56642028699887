import { Control, Controller, useController } from 'react-hook-form';
import {
  MCheckbox,
  MCustomNumberInput,
  MFormField,
  MInput,
  MText,
} from '~app/components/Monetize';
import {
  CustomFieldPermissionsEnum,
  CustomFieldTypeEnum,
  ICustomFieldRecordSchema,
  ICustomFieldResSchema,
} from '~app/types';
import { DatePicker } from '../Monetize/DatePicker/DatePicker';

interface CustomFormDataFormFieldProps {
  customField: ICustomFieldResSchema;
  control: Control<ICustomFieldRecordSchema>;
  onSave: (field: string, value: any) => void;
  isDisabled?: boolean;
  isReadOnly?: boolean;
  variant?: string;
  isHorizontal?: boolean;
}

export const CustomFormDataFormField = ({
  customField,
  control,
  onSave,
  isDisabled,
  isReadOnly,
  variant,
  isHorizontal,
}: CustomFormDataFormFieldProps) => {
  const { key, displayLabel, tooltip, type, permissions } = customField;

  const canEdit = permissions.includes(CustomFieldPermissionsEnum.WRITE);

  const {
    formState: { errors },
  } = useController({ control, name: key });

  const fieldProps = {
    isDisabled: isDisabled || isReadOnly || !canEdit,
    isReadOnly: isReadOnly || !canEdit,
    variant,
  };

  return (
    <MFormField
      error={errors[key]}
      label={type !== CustomFieldTypeEnum.CHECKBOX ? displayLabel : ''}
      tooltip={tooltip || ''}
      isHorizontal={isHorizontal}
    >
      <Controller
        name={key}
        control={control}
        render={({ field: { value, onBlur, onChange, ...rest } }) => {
          switch (type) {
            case CustomFieldTypeEnum.CHECKBOX: {
              return (
                <MCheckbox
                  isChecked={!!value}
                  placeholder={displayLabel}
                  onChange={(e) => {
                    onChange(e);
                    onSave(key, e.target.checked);
                  }}
                  {...rest}
                  {...fieldProps}
                >
                  {displayLabel}
                </MCheckbox>
              );
            }
            case CustomFieldTypeEnum.NUMBER: {
              if (fieldProps.isReadOnly && !value) {
                return (
                  <MText fontStyle="italic" mt="2">
                    Blank
                  </MText>
                );
              }
              if (!canEdit) {
                return <MText mt="2">{value}</MText>;
              }
              return (
                <MCustomNumberInput
                  placeholder={displayLabel}
                  inputMode="decimal"
                  value={value as number}
                  onBlur={(e) => {
                    onSave(key, value);
                  }}
                  onChange={(val) => onChange(val)}
                  {...rest}
                  {...fieldProps}
                />
              );
            }
            case CustomFieldTypeEnum.DATE: {
              if (fieldProps.isReadOnly && !value) {
                return (
                  <MText fontStyle="italic" mt="2">
                    Blank
                  </MText>
                );
              }
              return (
                <DatePicker
                  placeholder={displayLabel}
                  value={value as string}
                  clearable
                  onBlur={onBlur}
                  onChange={(e) => {
                    onChange(e);
                    onSave(key, e);
                  }}
                  {...rest}
                  {...fieldProps}
                />
              );
            }
            case CustomFieldTypeEnum.SINGLE_LINE_TEXT:
            default: {
              if (fieldProps.isReadOnly && !value) {
                return (
                  <MText fontStyle="italic" mt="2">
                    Blank
                  </MText>
                );
              }
              if (!canEdit) {
                return <MText mt="2">{value}</MText>;
              }
              return (
                <MInput
                  placeholder={displayLabel}
                  value={(value || '') as string}
                  onBlur={(e) => {
                    onSave(key, value);
                  }}
                  onChange={onChange}
                  {...rest}
                  {...fieldProps}
                />
              );
            }
          }
        }}
      />
    </MFormField>
  );
};
