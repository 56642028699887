import { GridItem, GridItemProps, Heading } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import {
  MCustomSelect,
  MFormField,
  MGrid,
  MText,
} from '~app/components/Monetize';
import {
  GuidedQuotingFieldDataTypeMapping,
  GuidedQuotingFieldDisplayText,
  GuidedQuotingPopulateByToAvailableTypes,
} from '~app/constants/guidedQuoting';
import {
  GuidedQuotingReq,
  QuestionFilterByEnum,
  QuestionReq,
  QuoteSourceField,
  QuoteSourceFieldName,
} from '~app/types';
import { objectToSelectOptions } from '~app/utils/misc';
import { GuidedQuotingColumnsValue } from './GuidedQuotingColumnsValue';

interface GuidedQuotingQuoteFieldsProps {
  formValues: UseFormReturn<GuidedQuotingReq>;
  quoteSourceFields: QuoteSourceField[];
  questions: QuestionReq[];
}

export const GuidedQuotingQuoteFields = ({
  formValues,
  quoteSourceFields,
  questions,
}: GuidedQuotingQuoteFieldsProps) => {
  return (
    <MGrid templateColumns="1fr 1fr 1fr" overflow="hidden" alignItems="center">
      {/* Header */}
      <GridHeaderCell>Field</GridHeaderCell>
      <GridHeaderCell>Populated By</GridHeaderCell>
      <GridHeaderCell>Value</GridHeaderCell>
      {/* Rows */}
      {quoteSourceFields.map(({ quoteField, type }, index) => (
        <GridBodyRow
          key={quoteField}
          index={index}
          formValues={formValues}
          name={quoteField}
          type={type}
          questions={questions}
        />
      ))}
    </MGrid>
  );
};

const GridHeaderCell = ({
  children,
  ...rest
}: { children: ReactNode } & GridItemProps) => {
  return (
    <GridItem
      px={2}
      pb={2}
      borderBottom="1px"
      borderBottomColor="tGray.back"
      {...rest}
    >
      <Heading as="h3" fontWeight={600} fontSize="md">
        {children}
      </Heading>
    </GridItem>
  );
};

const GridBodyCell = ({
  children,
  ...rest
}: { children: ReactNode } & GridItemProps) => {
  return (
    <GridItem
      columnGap={0}
      px={2}
      py={2}
      minH="50px"
      borderBottom="1px"
      borderBottomColor="tGray.back"
      alignContent="center"
      {...rest}
    >
      {children}
    </GridItem>
  );
};

const GridBodyRow = ({
  index,
  formValues,
  name,
  type,
  questions,
}: {
  index: number;
  formValues: UseFormReturn<GuidedQuotingReq>;
  name: QuoteSourceFieldName;
  type: QuestionFilterByEnum;
  questions: QuestionReq[];
}) => {
  const {
    control,
    setValue,
    clearErrors,
    formState: { errors: errors },
  } = formValues;

  return (
    <>
      <GridBodyCell>
        <MText>{GuidedQuotingFieldDisplayText[name]}</MText>
      </GridBodyCell>
      <GridBodyCell>
        <MFormField error={errors.quoteSourceFields?.[index]?.type} isRequired>
          <Controller
            name={`quoteSourceFields.${index}.type`}
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <MFormField isRequired>
                <MCustomSelect
                  items={objectToSelectOptions(
                    GuidedQuotingPopulateByToAvailableTypes[
                      GuidedQuotingFieldDataTypeMapping[name]
                    ] || {},
                  )}
                  {...rest}
                  onChange={(newValue: any) => {
                    onChange(newValue);
                    setValue(`quoteSourceFields.${index}.value`, null);
                  }}
                />
              </MFormField>
            )}
          />
        </MFormField>
      </GridBodyCell>
      <GridBodyCell>
        <MFormField error={errors.quoteSourceFields?.[index]?.value} isRequired>
          <Controller
            name={`quoteSourceFields.${index}.value`}
            control={control}
            render={({ field }) => (
              <GuidedQuotingColumnsValue
                name={name}
                type={type}
                field={field}
                questions={questions}
                clearErrors={() =>
                  clearErrors(`quoteSourceFields.${index}.value`)
                }
              />
            )}
          />
        </MFormField>
      </GridBodyCell>
    </>
  );
};
