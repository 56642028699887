import { z } from 'zod';

export const QuoteTemplateConfigSchema = z.object({
  showBillingSchedule: z.boolean(),
  showCredits: z.boolean(),
  showDiscount: z.boolean(),
  showProration: z.boolean(),
  showPricePerUnit: z.boolean(),
  showProductDescription: z.boolean(),
  showPricing: z.boolean(),
  showRateDescription: z.boolean().optional(),
  showOfferingDescription: z.boolean().optional(), // need to remove when BE will be fixed
  showSigningPage: z.boolean().optional(),
  showUnusedItems: z.boolean().optional(),
  defaultTemplate: z.string().optional(),
});

export type IQuoteTemplateConfigSchema = z.infer<
  typeof QuoteTemplateConfigSchema
>;

export interface IQuoteTemplateConfigRes {
  showBillingSchedule: boolean;
  showCredits: boolean;
  showDiscount: boolean;
  showProration: boolean;
  showPricePerUnit: boolean;
  showProductDescription: boolean;
  showPricing: boolean;
  showPriorContractOnAmendments?: boolean;
  showRateDescription?: boolean;
  showOfferingDescription?: boolean; // need to remove when BE will be fixed
  showSigningPage?: boolean;
  showUnusedItems?: boolean;
  defaultTemplate?: string;
}
