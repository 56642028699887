import {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  MCurrencySelect,
  MCustomNumberInput,
  MDivider,
  MGrid,
  MGridItem,
  MHStack,
  MInput,
  MText,
  MVStack,
} from '~app/components/Monetize';
import { DatePicker } from '~app/components/Monetize/DatePicker/DatePicker';
import BillingFrequencyDropdownSelect from '~app/components/Monetize/Dropdowns/BillingFrequencyDropdownSelect';
import OfferingDropdownMultiSelect from '~app/components/Monetize/Dropdowns/OfferingDropdownMultiSelect';
import ProductDropdownMultiSelect from '~app/components/Monetize/Dropdowns/ProductDropdownMultiSelect';
import {
  ContextProduct,
  GuidedQuotingContext,
  IGuidedQuotingRespSchema,
  IOfferingRes,
  IQuestion,
  Maybe,
  QuestionFilterByEnum,
  QuestionTypesEnum,
} from '~app/types';
import { MLegalEntitySelect } from '../../../../../components/Monetize/MLegalEntitySelect';
import { useCurrencies } from '../../../../../hooks/useCurrencies';
import { useFlags } from '../../../../../services/launchDarkly';
import { sortByProductObjType, sortByProductType } from '../../../../../utils';
import { orderObjectsBy } from '../../../../../utils/misc';
import { ProductQuantityTable } from './ProductQuantityTable';
import { RateQuestionDropdown } from './RateQuestionDropdown';
import { questionFilter, validDateQuestionValue } from './guidedQuote.utils';

interface QuestionFormProps {
  question: IQuestion;
  context: Record<string, any>;
  setContext: Dispatch<SetStateAction<GuidedQuotingContext>>;
  productQuestion: Maybe<IQuestion>;
  offeringQuestion: Maybe<IQuestion>;
  guidedQuoting: IGuidedQuotingRespSchema | undefined;
  accountCurrency?: string;
}

export const QuestionForm = ({
  question,
  context,
  setContext,
  productQuestion,
  offeringQuestion,
  guidedQuoting,
  accountCurrency,
}: QuestionFormProps) => {
  const { salesDemoMedinsightTempGuidedQuotingSpecialConfig } = useFlags();
  const [value, setValue] = useState<any>();
  const [filter, setFilter] = useState<Record<string, any>>();
  const [offeringData, setOfferingData] = useState<any>();
  const { defaultCurrency } = useCurrencies();

  // FIXME: this entire flow is convoluted - the parent should own state management
  // and just pass in the data without ambiguity of what data is used for and where it comes from
  // ideally we can have an array of questions+data with strong types
  const currencyValue = useMemo(
    () =>
      guidedQuoting?.quoteSourceFields?.find(
        ({ quoteField }) => quoteField === 'currency',
      ),
    [guidedQuoting?.quoteSourceFields],
  );
  const currency: string =
    currencyValue?.type === QuestionFilterByEnum.QUESTION
      ? context[currencyValue.value!]?.value
      : currencyValue?.value ?? accountCurrency ?? defaultCurrency ?? 'USD';

  useEffect(() => {
    if (value && question) {
      let transformedValue = value;

      if (
        question.type === QuestionTypesEnum.OFFERING &&
        Array.isArray(value)
      ) {
        // FIXME: can we sort these at the service layer so that everywhere benefits from it?
        const offerings = orderObjectsBy(value as IOfferingRes[], ['name']).map(
          (offering) => ({
            ...offering,
            products: sortByProductType(offering.products),
            offeringProducts: sortByProductObjType(offering.offeringProducts),
          }),
        );
        setOfferingData(offerings);
        transformedValue = offerings.reduce((acc: any, item) => {
          acc[item.id] = {
            name: item.name,
            rate: '',
            products: sortByProductObjType(item.offeringProducts).map(
              ({ isMandatory, product }): ContextProduct => ({
                id: product.id,
                name: product.name,
                type: product.productType,
                isMandatory,
                isSelected:
                  isMandatory ||
                  !!salesDemoMedinsightTempGuidedQuotingSpecialConfig?.preSelectOptionalProducts?.productIds?.includes(
                    product.id,
                  ),
                qty:
                  product.productType === 'USAGE'
                    ? '1'
                    : context[question.id]?.value?.[item.id]?.products?.find(
                        (p: any) => p.id === product.id,
                      )?.qty ?? '',
              }),
            ),
          };
          return acc;
        }, {});
      }

      setContext({
        ...context,
        [question.id]: { value: transformedValue, type: question.type },
      });
    }
  }, [value]);

  useEffect(() => {
    if (context[question.id]) {
      if (question.type == QuestionTypesEnum.OFFERING) {
        setValue(offeringData);
      } else {
        setValue(context[question.id].value);
      }
    } else {
      setValue(null as unknown as string);
    }

    if (question) {
      let newFilter: Record<string, any> = {};
      if (
        question.type === QuestionTypesEnum.OFFERING &&
        productQuestion &&
        Array.isArray(context[productQuestion.id])
      ) {
        newFilter.productId = {
          in: context[productQuestion.id].value.map(
            (product: any) => product.id,
          ),
        };
      }
      const additionalFilter = questionFilter(question, context);
      newFilter = { ...newFilter, ...additionalFilter };
      setFilter(newFilter);
    }
  }, [question]);

  return (
    <MVStack justifyContent="left" alignItems="flex-start" mt={2}>
      {question.type === QuestionTypesEnum.PRODUCT && (
        <>
          <MText fontWeight="semibold" mb={2}>
            {question.questionNumber}. {question.questionText}
          </MText>
          <ProductDropdownMultiSelect
            filters={filter}
            value={value}
            setValue={setValue}
          />
        </>
      )}
      {question.type === QuestionTypesEnum.OFFERING && (
        <>
          <MText fontWeight="semibold" mb={2}>
            {question.questionNumber}. {question.questionText}
          </MText>
          <OfferingDropdownMultiSelect
            question={question}
            context={context}
            filters={filter}
            value={value}
            setValue={setValue}
          />
          {value &&
            context[question.id] &&
            Object.keys(context[question.id].value).length > 0 &&
            Object.values(context[question.id].value).some((offering: any) =>
              offering.products?.some(
                (product: any) => product.type !== 'USAGE',
              ),
            ) && (
              <MVStack width="100%">
                <MGrid
                  templateAreas={`"offr prod qty"`}
                  templateColumns={`200px 1fr 100px`}
                  width="100%"
                  mt={2}
                >
                  <MGridItem
                    area="offr"
                    display="flex"
                    alignItems="center"
                    justifyContent="left"
                  >
                    <MText fontWeight="semibold">Offering Name</MText>
                  </MGridItem>
                  <MGridItem
                    area="prod"
                    display="flex"
                    alignItems="center"
                    justifyContent="left"
                  >
                    <MText fontWeight="semibold">Product Name</MText>
                  </MGridItem>
                  <MGridItem
                    area="qty"
                    display="flex"
                    alignItems="center"
                    justifyContent="left"
                  >
                    <MText fontWeight="semibold">Quantity</MText>
                  </MGridItem>
                </MGrid>
                {Array.isArray(value) &&
                  value.map(({ id: offeringId }: IOfferingRes, index) => (
                    <Fragment key={offeringId}>
                      <ProductQuantityTable
                        questionId={question.id}
                        offeringId={offeringId}
                        context={context}
                        setContext={setContext}
                      />
                      {index !== value.length - 1 && <MDivider />}
                    </Fragment>
                  ))}
              </MVStack>
            )}
        </>
      )}
      {question.type === QuestionTypesEnum.RATE &&
        offeringQuestion &&
        context[offeringQuestion.id] && (
          <>
            <MText fontWeight="semibold" mb={2}>
              {question.questionNumber}. {question.questionText}
            </MText>
            <MVStack width="100%" mt="0 !important">
              <MGrid
                templateAreas={`"offr rate"`}
                templateColumns={`1fr 1fr`}
                width="100%"
                mt={0}
              >
                <MGridItem
                  area="offr"
                  display="flex"
                  alignItems="center"
                  justifyContent="left"
                >
                  <MText fontWeight="semibold">Offering Name</MText>
                </MGridItem>
                <MGridItem
                  area="rate"
                  display="flex"
                  alignItems="center"
                  justifyContent="left"
                >
                  <MText fontWeight="semibold">Rate</MText>
                </MGridItem>
              </MGrid>
              {Object.keys(context[offeringQuestion.id].value).map(
                (offeringId: string) => (
                  <MGrid
                    key={offeringId}
                    templateAreas={`"offr rate"`}
                    templateColumns={`1fr 1fr`}
                    width="100%"
                    mt={2}
                  >
                    <MGridItem
                      area={`offr`}
                      display="flex"
                      alignItems="center"
                      justifyContent="top"
                      overflow="auto"
                    >
                      <MText key={`offering-${offeringId}`}>
                        {context[offeringQuestion.id].value[offeringId].name}
                      </MText>
                    </MGridItem>
                    <MGridItem
                      area={'rate'}
                      display="flex"
                      alignItems="center"
                      justifyContent="top"
                      overflow="auto"
                    >
                      <RateQuestionDropdown
                        filter={filter}
                        value={value ? value[offeringId] ?? null : null}
                        setValue={(newValue) =>
                          setValue({ ...value, [offeringId]: newValue })
                        }
                        currency={currency}
                        offeringId={offeringId}
                        setContext={setContext}
                        offeringQuestionId={offeringQuestion.id}
                      />
                    </MGridItem>
                  </MGrid>
                ),
              )}
            </MVStack>
          </>
        )}
      {question.type === QuestionTypesEnum.DATE &&
        validDateQuestionValue(value) && (
          <>
            <MText fontWeight="semibold" mb={2}>
              {question.questionNumber}. {question.questionText}
            </MText>
            <DatePicker
              value={value}
              onChange={(value: any) => setValue(value)}
              minDate={filter?.min}
              maxDate={filter?.max}
              boxProps={{ mt: '0 !important', width: '100%' }}
            />
          </>
        )}
      {question.type === QuestionTypesEnum.NUMBER && !isNaN(Number(value)) && (
        <>
          <MText fontWeight="semibold" mb={2}>
            {question.questionNumber}. {question.questionText}
          </MText>
          <MHStack width="100%" mt="0 !important">
            <MCustomNumberInput
              width="100%"
              value={value ?? ''}
              onChange={(value: any) => setValue(value)}
              min={filter?.min ?? 1}
              max={filter?.max ?? Number.MAX_SAFE_INTEGER}
            />
            {guidedQuoting?.contractLengthSource === question.id && (
              <MText>months.</MText>
            )}
          </MHStack>
        </>
      )}
      {question.type === QuestionTypesEnum.CURRENCY && (
        <>
          <MText fontWeight="semibold" mb={2}>
            {question.questionNumber}. {question.questionText}
          </MText>
          <MCurrencySelect value={value} onChange={setValue} />
        </>
      )}
      {question.type === QuestionTypesEnum.LEGAL_ENTITY && (
        <>
          <MText fontWeight="semibold" mb={2}>
            {question.questionNumber}. {question.questionText}
          </MText>
          <MLegalEntitySelect value={value} onChange={setValue} />
        </>
      )}
      {question.type === QuestionTypesEnum.BILLING_FREQUENCY && (
        <>
          <MText fontWeight="semibold" mb={2}>
            {question.questionNumber}. {question.questionText}
          </MText>
          <BillingFrequencyDropdownSelect
            filters={filter}
            value={value}
            setValue={setValue}
          />
        </>
      )}
      {question.type === QuestionTypesEnum.TEXT && (
        <>
          <MText fontWeight="semibold" mb={2}>
            {question.questionNumber}. {question.questionText}
          </MText>
          <MInput
            mt="0 !important"
            value={value ?? ''}
            onChange={(e) => setValue(e.target.value)}
          />
        </>
      )}
    </MVStack>
  );
};
