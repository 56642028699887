import { FC } from 'react';
import {
  MBox,
  MCustomSelect,
  QuoteSelectInput,
} from '~app/components/Monetize';
import { useQuoteContext } from '../../Quote/quoteContext';
import { INetTerm, IQuoteRequestSchema } from '~app/types';
import { useFormContext, Controller } from 'react-hook-form';
import { AddPoNumberSectionV2 } from '~app/components/Quotes/AddPoNumberSectionV2';
import { getQuoteStatus } from '~app/utils';
import { useNetTerms, useQuoteSettings } from '~app/hooks';
import { QuoteBasicInfoLine } from '~app/components/Quotes/QuoteFormHeaderLine';

interface QuoteInternalBillingInfoDetailsProps {}

export const QuoteInternalBillingInfoDetails: FC<
  QuoteInternalBillingInfoDetailsProps
> = ({}: QuoteInternalBillingInfoDetailsProps) => {
  const {
    isReadOnly,
    quoteData: { quote, setQuote },
    handleSubmitButtonWithoutDirtyCheck,
    setIsQuoteSaving,
  } = useQuoteContext();

  const {} = useQuoteContext();
  const { quoteSettings } = useQuoteSettings();

  const { control, getValues } = useFormContext<IQuoteRequestSchema>();

  const selectedNetTerm = getValues('netTerms');
  const { activeNetTerms, netTermsById } = useNetTerms();
  // make sure that if inactive net term is selected, it is included in dropdown
  let netTermsOptions: INetTerm[] = activeNetTerms;
  if (
    selectedNetTerm &&
    netTermsById[selectedNetTerm] &&
    !netTermsById[selectedNetTerm].active
  ) {
    netTermsOptions = [...netTermsOptions, netTermsById[selectedNetTerm]];
  }

  if (!quote) {
    return null;
  }
  return (
    <MBox>
      {quoteSettings?.allowPONumberOnQuotes && (
        <QuoteBasicInfoLine title="PO Number">
          <MBox>
            <AddPoNumberSectionV2
              quote={quote}
              setQuote={setQuote}
              setIsQuoteSaving={setIsQuoteSaving}
              buttonProps={{ mr: '-3' }}
            />
          </MBox>
        </QuoteBasicInfoLine>
      )}

      <QuoteBasicInfoLine title="Net Terms">
        <MBox mr={isReadOnly ? 0 : '-2'}>
          <Controller
            name="netTerms"
            control={control}
            render={({ field: { onChange, ...rest } }) => (
              <MCustomSelect
                isDisabled={isReadOnly}
                isReadOnly={isReadOnly}
                MainInputComponent={QuoteSelectInput}
                itemTitle="name"
                itemValue="value"
                items={netTermsOptions}
                popOverProps={{
                  placement: 'bottom-end',
                  matchWidth: false,
                }}
                popOverContentProps={{ minWidth: '10rem' }}
                onChange={(e: any) => {
                  onChange(e);
                  handleSubmitButtonWithoutDirtyCheck();
                }}
                showContentInInput
                {...rest}
              />
            )}
          />
        </MBox>
      </QuoteBasicInfoLine>
    </MBox>
  );
};
