import {
  ApiListResponse,
  GetListApiConfig,
  GetListApiFilter,
  GuidedQuotingStatusEnum,
  IGuidedQuoting,
  IGuidedQuotingRespSchema,
} from '../types';
import { apiGet } from './axios';
import { ApiQueryItem, useGetListData } from './queryUtils';
import { asQueryUtil, composeGetQuery } from './utils';

export const guidedQuotingServiceQueryKeys = {
  base: ['guided-quoting'] as const,
  // Guided Quoting
  guidedQuotingList: () =>
    [...guidedQuotingServiceQueryKeys.base, 'list'] as const,
  guidedQuotingById: (id: string) =>
    [...guidedQuotingServiceQueryKeys.base, id] as const,
  // Questions
  questions: () =>
    [...guidedQuotingServiceQueryKeys.base, 'questions'] as const,
  questionsList: () =>
    [...guidedQuotingServiceQueryKeys.questions(), 'list'] as const,
  questionsById: (id: string) =>
    [...guidedQuotingServiceQueryKeys.questions(), id] as const,
};

const queryKeysGuidedQuoting: Required<Omit<ApiQueryItem, 'upload'>> = {
  list: {
    endpoint: `/api/guidedQuoting`,
    queryKey: guidedQuotingServiceQueryKeys.guidedQuotingList(),
    byIdQueryKey: (id: string) => queryKeysGuidedQuoting.byId.queryKey(id),
  },
  byId: {
    endpoint: (id: string) => `/api/guidedQuoting/${id}`,
    queryKey: (id: string) =>
      guidedQuotingServiceQueryKeys.guidedQuotingById(id),
  },
  create: {
    endpoint: () => `/api/guidedQuoting`,
    invalidateKeys: [guidedQuotingServiceQueryKeys.guidedQuotingList()],
    setDataKey: (id: string) =>
      guidedQuotingServiceQueryKeys.guidedQuotingById(id),
  },
  update: {
    endpoint: (id: string) => `/api/guidedQuoting/${id}`,
    invalidateKeys: [guidedQuotingServiceQueryKeys.guidedQuotingList()],
    setDataKey: (id: string) =>
      guidedQuotingServiceQueryKeys.guidedQuotingById(id),
  },
  delete: {
    endpoint: (id: string) => `/api/guidedQuoting/${id}`,
    invalidateKeys: [guidedQuotingServiceQueryKeys.guidedQuotingList()],
    setDataKey: (id: string) =>
      guidedQuotingServiceQueryKeys.guidedQuotingById(id),
  },
};

const queryKeysQuestions: Required<Omit<ApiQueryItem, 'upload'>> = {
  list: {
    endpoint: `/api/guidedQuoting/questions`,
    queryKey: guidedQuotingServiceQueryKeys.questionsList(),
    byIdQueryKey: (id: string) => queryKeysQuestions.byId.queryKey(id),
  },
  byId: {
    endpoint: (id: string) => `/api/guidedQuoting/questions/${id}`,
    queryKey: (id: string) => guidedQuotingServiceQueryKeys.questionsById(id),
  },
  create: {
    endpoint: () => `/api/guidedQuoting/questions`,
    invalidateKeys: [guidedQuotingServiceQueryKeys.questionsList()],
    setDataKey: (id: string) => guidedQuotingServiceQueryKeys.questionsById(id),
  },
  update: {
    endpoint: (id: string) => `/api/guidedQuoting/questions/${id}`,
    invalidateKeys: [guidedQuotingServiceQueryKeys.questionsList()],
    setDataKey: (id: string) => guidedQuotingServiceQueryKeys.questionsById(id),
  },
  delete: {
    endpoint: (id: string) => `/api/guidedQuoting/questions/${id}`,
    invalidateKeys: [guidedQuotingServiceQueryKeys.questionsList()],
    setDataKey: (id: string) => guidedQuotingServiceQueryKeys.questionsById(id),
  },
};

export const GUIDED_QUOTING_SERVICE_API = asQueryUtil({
  guidedQuoting: queryKeysGuidedQuoting,
  questions: queryKeysQuestions,
});

/**
 * Hook to get the active guided quoting process
 */
export const useActiveGuidedQuotingProcess = () => {
  return useGetListData<IGuidedQuoting, IGuidedQuoting | null>(
    'guidedQuoting',
    {
      config: { page: 0, rows: 1 },
      filters: { status: GuidedQuotingStatusEnum.ACTIVE },
    },
    {
      select: (data) => {
        if (data.content[0] && data.content[0].questions.length > 0) {
          return data.content[0];
        }
        return null;
      },
      refetchOnWindowFocus: false,
    },
  );
};

export const doGetGuidedQuotingList = async (
  config: GetListApiConfig,
  filters?: GetListApiFilter,
) => {
  const params = composeGetQuery(config, filters);
  const res = await apiGet<ApiListResponse<IGuidedQuotingRespSchema>>(
    '/api/guidedQuoting',
    {
      params,
    },
  );
  return res.data;
};
