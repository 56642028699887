import { Heading, useDisclosure } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { addDays } from 'date-fns/addDays';
import { endOfDay } from 'date-fns/endOfDay';
import { formatISO } from 'date-fns/formatISO';
import { fromUnixTime } from 'date-fns/fromUnixTime';
import { getUnixTime } from 'date-fns/getUnixTime';
import { parseISO } from 'date-fns/parseISO';
import { startOfDay } from 'date-fns/startOfDay';
import { ColumnProps } from 'primereact/column';
import { FC, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetUsageRecordsInfiniteLoad } from '~app/api/accountsService';
import { handleApiErrorToast } from '~app/api/axios';
import { useGetById } from '~app/api/queryUtils';
import { UploadIcon } from '~app/assets/icons';
import DataTableActions from '~app/components/Monetize/DataTable/MDataTableActions';
import { DatePicker } from '~app/components/Monetize/DatePicker/DatePicker';
import { ExportTableButton } from '~app/components/Monetize/ExportEntityButton';
import { LoadMore } from '~app/components/Monetize/LoadMore';
import MEmptyDataPlaceholder from '~app/components/Monetize/MEmptyDataPlaceholder';
import { getRateUsageBillingFrequencyDisplay } from '~app/constants/offerings';
import {
  getAccountDetailRoute,
  getSubscriptionOverviewRoute,
} from '~app/constants/routes';
import { useACL } from '~app/services/acl/acl';
import {
  DEFAULT_PAGER,
  FilterType,
  FilterTypeOperator,
  IGetSubscriptionItemSchema,
  IGetSubscriptionSchema,
  IRateResSchema,
  IUsageEvent,
  IUsageEventListRequestSchema,
  IUsageTypeResSchema,
  SubscriptionBillingStatusEnum,
  TDataTablePager,
  UsageEventListRequestSchema,
} from '~app/types';
import { formatNumber } from '~app/utils';
import { replaceUserTimezoneWithUtc, toDateTimeShort } from '~app/utils/dates';
import { textBodyTemplate } from '~app/utils/tableUtils';
import {
  MBox,
  MButton,
  MDataTable,
  MFlex,
  MFormField,
  MPageContainer,
  MPageHeader,
  MPageLoader,
  MText,
} from '~components/Monetize';
import { ManualUsageForm } from './ManualUsageForm';
import { UploadUsageModal } from './usageUpload/UploadUsageModal';

export const SubscriptionUsageOverviewPage: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { canDo } = useACL();

  const accountId = params.accountId || '';
  const subscriptionId = params.subscriptionId || '';
  const productId = params.productId || '';
  const canUpdateUsage = canDo([['billing', 'update']]);

  const {
    isOpen: isManualUsageModalOpen,
    onClose: onCloseUsageUpload,
    onOpen: onOpenUsageUpload,
  } = useDisclosure();

  const {
    isOpen: isUsageFormOpen,
    onOpen: onOpenUsageForm,
    onClose: onCloseUsageForm,
  } = useDisclosure();

  const [earliestAllowedEditDate, setEarliestAllowedEditDate] = useState(() =>
    getUnixTime(addDays(new Date(), -30)),
  );
  const [subscriptionItem, setSubscriptionItem] =
    useState<IGetSubscriptionItemSchema | null>(null);
  const [activeUsageRecord, setActiveUsageRecord] = useState<
    IUsageEvent | undefined
  >(undefined);
  const [usageTypes, setUsageTypes] = useState<IUsageTypeResSchema[]>([]);

  const [pager] = useState<TDataTablePager>(() => ({
    ...DEFAULT_PAGER,
    rows: 100,
    sortField: 'timestamp',
  }));
  const periodDefaultStartDate =
    formatISO(startOfDay(addDays(new Date(), -30))).substring(0, 19) + '.000Z';
  const periodDefaultEndDate =
    formatISO(endOfDay(new Date())).substring(0, 19) + '.000Z';

  const {
    control,
    setValue,
    getValues,
    watch,
    formState: { errors, isValid },
  } = useForm<IUsageEventListRequestSchema>({
    resolver: zodResolver(UsageEventListRequestSchema),
    mode: 'onChange',
    defaultValues: {
      subscriptionId,
      usageTypeIds: [],
      startTime: periodDefaultStartDate,
      endTime: periodDefaultEndDate,
    },
  });

  // Ensure re-render is triggered when usage type changes
  watch('startTime');
  watch('endTime');
  watch('usageTypeIds');

  const { data: subscription, isLoading: isLoadingSubscription } =
    useGetById<IGetSubscriptionSchema>('accountSubscriptions', subscriptionId, {
      enabled: !!subscriptionId,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const subscriptionItemTemp = data?.subscriptionItems.find(
          (item) => item.product.id === productId,
        );
        if (subscriptionItemTemp) {
          setSubscriptionItem(subscriptionItemTemp);

          const startTime = startOfDay(
            parseISO(data.usagePeriodStart ?? periodDefaultStartDate),
          );
          setEarliestAllowedEditDate(getUnixTime(startTime));
          setValue('startTime', formatISO(startTime));

          setValue(
            'endTime',
            formatISO(
              endOfDay(parseISO(data.usagePeriodEnd ?? periodDefaultEndDate)),
            ),
          );
        } else {
          handleApiErrorToast(
            `The provided product does not exist on the subscription.`,
          );
          navigate(getSubscriptionOverviewRoute(accountId, subscriptionId));
          return;
        }

        if (
          subscriptionItemTemp?.product.usageTypes &&
          subscriptionItemTemp?.product.usageTypes.length > 0
        ) {
          setUsageTypes(subscriptionItemTemp?.product.usageTypes);
          setValue(
            'usageTypeIds',
            subscriptionItemTemp?.product.usageTypes.map(
              (usageType) => usageType.id,
            ) || [],
          );
        } else {
          handleApiErrorToast(`The provided product is not a usage product.`);
          navigate(getSubscriptionOverviewRoute(accountId, subscriptionId));
          return;
        }
      },
      onError: (err) => {
        handleApiErrorToast(err);
        navigate(getAccountDetailRoute(accountId));
        return null;
      },
    });

  const isCanceled =
    subscription?.billingStatus === SubscriptionBillingStatusEnum.CANCELED;

  const { data: rate } = useGetById<IRateResSchema>(
    'productCatalogRates',
    subscription?.rateId!,
    { enabled: !!subscription?.rateId!, refetchOnWindowFocus: false },
  );

  const payload = getValues();
  const {
    data: usageRecordsAllPages,
    isLoading: isLoadingUsage,
    isFetchingNextPage,
    fetchNextPage,
    refetch,
  } = useGetUsageRecordsInfiniteLoad(
    {
      ...payload,
      startTime: payload.startTime
        ? replaceUserTimezoneWithUtc(payload.startTime).toISOString()
        : '',
      endTime: payload.endTime
        ? replaceUserTimezoneWithUtc(payload.endTime).toISOString()
        : '',
    },
    pager,
    {
      enabled:
        !!payload.subscriptionId && payload.usageTypeIds.length > 0 && isValid,
      onError: (err) => handleApiErrorToast(err),
    },
  );

  const usageRecords =
    usageRecordsAllPages?.pages.flatMap((page) => page.content) || [];
  const totalElements = usageRecordsAllPages?.pages?.[0].totalElements || 0;

  const filters = useMemo((): FilterType[] => {
    return [
      {
        key: 'startTime',
        value:
          payload.startTime ??
          replaceUserTimezoneWithUtc(payload.startTime).toISOString(),
        operator: FilterTypeOperator.EQUAL,
      },
      {
        key: 'endTime',
        value:
          payload.endTime ??
          replaceUserTimezoneWithUtc(payload.endTime).toISOString(),
        operator: FilterTypeOperator.EQUAL,
      },
      {
        key: 'subscriptionId',
        value: payload.subscriptionId,
        operator: FilterTypeOperator.EQUAL,
      },
      {
        key: 'usageTypeIds',
        value: payload.usageTypeIds.join(','),
        operator: FilterTypeOperator.EQUAL,
      },
      {
        key: 'sort',
        value: 'timestamp:desc',
        operator: FilterTypeOperator.EQUAL,
      },
    ];
  }, [payload.endTime, payload.startTime, payload.subscriptionId]);

  const columns: ColumnProps[] = [
    {
      className: 'overflow-hidden table-cell-lg',
      field: 'id',
      header: 'ID',
      body: textBodyTemplate<IUsageEvent>('id'),
    },
    {
      className: 'overflow-hidden table-cell-lg',
      field: 'usageTypeId',
      header: 'Usage Type ID',
      body: textBodyTemplate<IUsageEvent>('usageTypeId'),
    },
    {
      field: 'timestamp',
      header: 'Timestamp (UTC)',
      sortable: false,
      body: (usageEvent: IUsageEvent) => (
        <MText>
          {toDateTimeShort(fromUnixTime(Number(usageEvent.timestamp)), 'UTC')}
        </MText>
      ),
    },
    {
      field: 'unitsConsumed',
      header: 'Units',
      sortable: false,
      className: 'text-right',
      body: (usageEvent: IUsageEvent) => {
        const usageType = usageTypes.find(
          ({ id }) => id === usageEvent.usageTypeId,
        );
        if (!usageType) {
          return null;
        }
        return (
          <MText textAlign="right">
            {formatNumber(usageEvent.unitsConsumed, {
              minimumFractionDigits: usageType?.decimalPlaces || 0,
              maximumFractionDigits: usageType?.decimalPlaces || 0,
            })}{' '}
            {usageType?.unitName}
          </MText>
        );
      },
    },
    {
      field: 'action',
      header: '',
      sortable: false,
      body: (usageRecord: IUsageEvent) => {
        if (!canUpdateUsage) {
          return null;
        }
        return (
          <MFlex align="center" justify="flex-end">
            <DataTableActions
              actions={[
                {
                  title: 'Edit',
                  enabled:
                    !isCanceled &&
                    usageRecord.timestamp >= earliestAllowedEditDate,
                  action: () => {
                    setActiveUsageRecord(usageRecord);
                    onOpenUsageForm();
                  },
                },
              ]}
            />
          </MFlex>
        );
      },
      style: { width: '15rem' },
    },
  ];

  if (!subscription || !subscriptionItem || !rate || isLoadingSubscription) {
    return <MPageLoader />;
  }

  return (
    <MPageContainer maxHeight="100vh">
      {isManualUsageModalOpen && (
        <UploadUsageModal
          accountId={accountId}
          subscriptionId={subscriptionId}
          onClose={onCloseUsageUpload}
        />
      )}
      <MPageHeader
        hasBackButton
        parentLink={getSubscriptionOverviewRoute(accountId, subscriptionId)}
        parentLinkTitle={`${subscription.offering.name || ''} - ${
          rate.name || ''
        }`}
        backButtonLink={getSubscriptionOverviewRoute(accountId, subscriptionId)}
        title={`${subscriptionItem.product.name} - Training Details`}
        extraSubtitleParts={[
          <MText size="sm">
            Usage Frequency:{' '}
            {getRateUsageBillingFrequencyDisplay(rate.usageBillingFrequency)}
          </MText>,
        ]}
      />
      <MFlex direction="column" w={'100%'} mb="5">
        <MFlex justifyContent="space-between" alignItems="center">
          <Heading size="md" fontSize={18} lineHeight={1}>
            Usage Details
          </Heading>
          <MFlex alignItems="center">
            <MFormField
              error={errors?.startTime}
              label="From"
              isHorizontal
              labelProps={{ mr: 2 }}
            >
              <Controller
                name="startTime"
                control={control}
                render={({ field }) => (
                  <DatePicker includeTime onClose={refetch} {...field} />
                )}
              />
            </MFormField>
            <MFormField
              error={errors?.startTime}
              label="to"
              labelProps={{ fontWeight: 'normal', mx: 2 }}
              isHorizontal
            >
              <Controller
                name="endTime"
                control={control}
                render={({ field }) => (
                  <DatePicker includeTime onClose={refetch} {...field} />
                )}
              />
            </MFormField>
            {canUpdateUsage && !isCanceled && (
              <MButton
                ml={5}
                mr={2}
                variant="secondary"
                onClick={() => onOpenUsageForm()}
              >
                New Usage
              </MButton>
            )}
            <MBox>
              <ExportTableButton
                entity="usage"
                applyInternalFiltersWithoutConfirmation
                internalFilters={filters}
                sortField={pager.sortField}
                sortOrder={pager.sortOrder}
                getFilename={() => `subscriptions-${accountId}.csv`}
              />
            </MBox>
            {canUpdateUsage && !isCanceled && (
              <DataTableActions
                actions={[
                  {
                    title: 'Import Usage',
                    icon: UploadIcon,
                    enabled: true,
                    action: () => onOpenUsageUpload(),
                  },
                ]}
              />
            )}
          </MFlex>
        </MFlex>
      </MFlex>
      {isUsageFormOpen && (
        <ManualUsageForm
          isOpen
          existingUsage={activeUsageRecord}
          onClose={() => {
            onCloseUsageForm();
            setActiveUsageRecord(undefined);
          }}
          subscriptionId={subscription.id}
          periodStartDate={subscription.periodStartDate}
          usageTypes={subscriptionItem.product.usageTypes}
          accountId={accountId}
        />
      )}

      <MDataTable
        value={usageRecords}
        paginator={false}
        className="p-datatable-responsive"
        emptyProps={{
          renderEmptyPlaceholder: () => (
            <MEmptyDataPlaceholder
              mainMessage="There aren't any usage records for this product."
              smallMessage={
                isCanceled
                  ? 'This subscription is canceled; no usage records can be added.'
                  : 'Add new usage records or change the date range.'
              }
              btnLabel={
                canUpdateUsage && !isCanceled ? 'Add New Usage' : undefined
              }
              onClick={onOpenUsageForm}
            />
          ),
        }}
        loading={isLoadingUsage}
        columns={columns}
        loadingContHeight="300px"
      />
      {!!usageRecords.length && (
        <MFlex flexDirection="column" mb={4} minH="70px">
          <LoadMore
            fetchedElementLength={usageRecords.length}
            totalElements={totalElements}
            isLoading={isFetchingNextPage}
            onLoadMore={fetchNextPage}
          />
        </MFlex>
      )}
    </MPageContainer>
  );
};
