import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import {
  MBox,
  MCard,
  MFlex,
  MPageLoader,
  MText,
} from '~app/components/Monetize';
import { QuoteCollaborationPopoverV2 } from '~app/components/Quotes/QuoteCollaborationPopoverV2';
import {
  IQuoteRequestSchema,
  CollaborationAccessEnum,
  QuoteStatusEnum,
} from '~app/types';
import { useQuoteContext } from '../../Quote/quoteContext';
import { QuoteReviewInfoDetails } from './QuoteReviewInfoDetails';
import { QuoteReviewInfoOther } from './QuoteReviewInfoOther';
import {
  QuoteStatusEnumDisplay,
  QuoteStatusTagStyle,
} from '~app/constants/quotes';

interface QuoteReviewInfoProps {
  onOpenContactDrawer: () => void;
}
export const QuoteReviewInfo: FC<QuoteReviewInfoProps> = ({
  onOpenContactDrawer,
}: QuoteReviewInfoProps) => {
  const { quoteData, handleSubmitButtonWithoutDirtyCheck } = useQuoteContext();

  const { quote, setQuote } = quoteData;

  const { setValue } = useFormContext<IQuoteRequestSchema>();

  if (!quote) {
    return <MPageLoader />;
  }

  if (quote?.status === QuoteStatusEnum.DRAFT) {
    return null;
  }

  return (
    <MCard width={'100%'} p={6} borderColor="tGray.lightPurple" mb="8">
      <MFlex
        justifyContent="space-between"
        alignItems="center"
        borderBottomWidth="1px"
        borderBottomColor="tWhite.400"
        pb="3"
        mb="4"
      >
        <MFlex alignItems="center">
          <MText fontWeight="600" fontSize="2xl" mr="2">
            {quote.description}
          </MText>

          <MText
            {...QuoteStatusTagStyle[quote.status]}
            ml=".5rem"
            data-testid="quote-tag-status"
          >
            {QuoteStatusEnumDisplay[quote.status]}
          </MText>
        </MFlex>

        <MBox mr="-3">
          <QuoteCollaborationPopoverV2
            quoteId={quote.id}
            quoteStatus={quote.status}
            collaborationAccess={quote.collaborationAccess}
            accessLevel={quote.accessLevel}
            onChange={(collaborationAccess: CollaborationAccessEnum) => {
              setValue('collaborationAccess', collaborationAccess, {
                shouldDirty: true,
              });
              handleSubmitButtonWithoutDirtyCheck();
            }}
          />
        </MBox>
      </MFlex>
      <MFlex flexDir={['column', 'column', 'column', 'column', 'row']}>
        <MBox flex="1">
          <QuoteReviewInfoDetails />
        </MBox>
        <MBox mx="6" display={['hidden', 'hidden', 'visible']}>
          <MBox w="1px" background="tGray.support" height="100%"></MBox>
        </MBox>
        <MBox flex="1">
          <QuoteReviewInfoOther onOpenContactDrawer={onOpenContactDrawer} />
        </MBox>
      </MFlex>
    </MCard>
  );
};
