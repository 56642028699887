import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  FlexProps,
} from '@chakra-ui/react';
import { FC } from 'react';
import { useNavigate } from 'react-router';
import { IBreadcrumbItem } from '~app/types';
import { MFlex } from './chakra';
import { MBackButton } from './MBackButton';
import { Link } from 'react-router-dom';

interface MBreadcrumbProps {
  items: IBreadcrumbItem[];
  backButtonProps?: {
    onClick?: () => void;
    title?: string;
  };
  containerStyles?: FlexProps;
}

export const MBreadcrumb: FC<MBreadcrumbProps> = ({
  items,
  backButtonProps,
  containerStyles,
}: MBreadcrumbProps) => {
  const navigate = useNavigate();
  return (
    <MFlex alignItems="center" {...containerStyles}>
      {backButtonProps && (
        <MBackButton
          onClick={
            backButtonProps.onClick
              ? backButtonProps.onClick
              : () => navigate(-1)
          }
          backButtonTitle={backButtonProps.title}
        />
      )}

      <Breadcrumb mt="0.5">
        {items.map(({ route, isCurrentPage, label }, index) => (
          <BreadcrumbItem isCurrentPage={isCurrentPage} key={index}>
            <BreadcrumbLink
              as={Link}
              to={route ?? ''}
              color={isCurrentPage ? 'tPurple.base' : 'tIndigo.base'}
              fontSize="md"
              fontWeight="600"
              lineHeight={1.2}
            >
              {label}
            </BreadcrumbLink>
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </MFlex>
  );
};
