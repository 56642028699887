import React, { FunctionComponent, PropsWithChildren } from 'react';
import { MdInfo } from 'react-icons/md';
import { MFlex, MIcon, MText, MTooltip } from '~app/components/Monetize';

const HeaderCellText: FunctionComponent<any> = ({
  children,
  isRequired,
  textProps = {},
  tooltipLabel,
  ...rest
}: PropsWithChildren<any>): React.ReactElement => {
  return (
    <MFlex fontWeight="600" {...rest}>
      <MText {...textProps}>{children}</MText>
      {isRequired && (
        <MText color="tRed.cinnabar" ml="1" {...textProps}>
          *
        </MText>
      )}
      {tooltipLabel && (
        <MTooltip
          label={tooltipLabel}
          placement="bottom-start"
          shouldWrapChildren
          alignSelf="center"
        >
          <MIcon as={MdInfo} w="3" h="3" ml="0.5" cursor="pointer" />
        </MTooltip>
      )}
    </MFlex>
  );
};

export default HeaderCellText;
