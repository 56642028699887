import { ListItem } from '@chakra-ui/react';
import { isBefore } from 'date-fns/isBefore';
import { subMonths } from 'date-fns/subMonths';
import useFlags from 'launchdarkly-react-client-sdk/lib/useFlags';
import { useRecoilState } from 'recoil';
import {
  IBillGroupResp,
  IQuoteItemRespSchema,
  IQuoteRespSchema,
  ProductTypeEnum,
  QuoteItemAmendmentStatusEnum,
} from '~app/types';
import {
  replaceUserTimezoneWithUtc,
  toDateOnly,
  toDateShort,
} from '~app/utils/dates';
import { MText, MUnorderedList } from '../../../../components/Monetize';
import { QuoteBannerItem } from './QuoteBannerItem';
import { bannerDismissedSelector } from './quoteBanner.utils';

export function UsageProductBanner({
  quote,
  billGroup,
}: {
  quote: IQuoteRespSchema;
  billGroup: IBillGroupResp;
}) {
  const { disableInactiveProductBanner } = useFlags();
  const [dismissed, setDismissed] = useRecoilState(
    bannerDismissedSelector([quote.id, 'UsageProductWarningBanner']),
  );
  let bannerTextList = checkUsageDate(quote, billGroup);

  if (disableInactiveProductBanner || dismissed || !bannerTextList?.length) {
    return null;
  }

  const renderBannerContent = () => {
    return bannerTextList.length > 0 ? (
      bannerTextList.length < 2 ? (
        <MText key={bannerTextList[0]}>{bannerTextList[0]}</MText>
      ) : (
        <MUnorderedList>
          {bannerTextList.map((bannerText: string) => (
            <ListItem key={bannerText}>
              <MText>{bannerText}</MText>
            </ListItem>
          ))}
        </MUnorderedList>
      )
    ) : null;
  };

  return (
    <QuoteBannerItem onDismiss={() => setDismissed(true)} type="warning">
      {renderBannerContent()}
    </QuoteBannerItem>
  );
}

/**
 *
 * @param quote
 * @param billGroup
 * @returns text for the banner or null
 */
function checkUsageDate(
  quote: IQuoteRespSchema,
  billGroup: IBillGroupResp,
): string[] {
  if (quote.quoteOfferings.length === 0 || !billGroup.nextInvoiceDate) {
    return [];
  }

  const nextInvoiceDate = String(billGroup.nextInvoiceDate);
  let bannerText: string[] = [];

  const hasUsageProduct = (items: IQuoteItemRespSchema[]) =>
    items.some(({ productType }) => productType === ProductTypeEnum.USAGE);

  const hasAddedUsageProduct = (items: IQuoteItemRespSchema[]) =>
    items.some(
      ({ productType, amendmentStatus }) =>
        productType === ProductTypeEnum.USAGE &&
        amendmentStatus === QuoteItemAmendmentStatusEnum.ADDED,
    );

  const isBeforeNextInvoiceDate = (date: string, nextInvDate: string) => {
    return isBefore(date, nextInvDate);
  };

  const isRemovedFromStartOfContract = (items: IQuoteItemRespSchema[]) => {
    return items.some(
      ({ productType, amendmentStatus }) =>
        productType === ProductTypeEnum.USAGE &&
        amendmentStatus === QuoteItemAmendmentStatusEnum.REMOVED,
    );
  };

  for (const quoteOffering of quote.quoteOfferings) {
    const { items, endDate, startDate } = quoteOffering;

    if (
      (hasUsageProduct(items) &&
        isBeforeNextInvoiceDate(endDate, nextInvoiceDate)) ||
      isRemovedFromStartOfContract(items)
    ) {
      bannerText.push(
        'Removal of a usage product in an already invoiced period will not create any Credit Notes. Only Minimum Commitment products will be Credit Noted. Finance may manually create additional Credit Notes from the Invoice.',
      );
    }

    // Next invoice date can take currrent usage changes, thats why have to check with previous months where the bill is already invoiced
    const nextInvoiceDateMinusOneMonth = subMonths(
      replaceUserTimezoneWithUtc(nextInvoiceDate),
      1,
    );

    if (
      hasAddedUsageProduct(items) &&
      isBeforeNextInvoiceDate(
        startDate,
        toDateOnly(nextInvoiceDateMinusOneMonth),
      )
    ) {
      bannerText.push(
        `Usage products added prior to ${toDateShort(
          nextInvoiceDateMinusOneMonth,
        )} will not be Invoiced as Invoicing has already progressed beyond this period. Finance may create a One-time Invoice manually if required.`,
      );
    }
  }

  return bannerText;
}
