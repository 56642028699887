import { Tooltip } from '@chakra-ui/react';
import { Fragment, FunctionComponent as FC } from 'react';
import { MdClose } from 'react-icons/md';
import {
  MBox,
  MCustomIconButton,
  MDivider,
  MFlex,
  MGrid,
  MText,
} from '~app/components/Monetize';
import { PRODUCT_TYPE_DISPLAY_SHORT } from '~app/constants/products';
import { IProductResSchema, OfferingTypesEnum } from '~app/types';
import { sortByProductType } from '~app/utils';
import BodyCell from './BodyCell';
import HeaderCellText from './HeaderCellText';
import OfferingHeaderCell from './OfferingHeaderCell';
import ProductSearch from './ProductSearch';

export const getRowColor = (index: number) =>
  index % 2 === 0 ? 'tWhite.titanWhite' : 'tWhite.base';

interface ProductsTableProps {
  isValid: Boolean;
  products: IProductResSchema[];
  isEditable: boolean;
  offeringType: OfferingTypesEnum;
  handleSelect: (p: IProductResSchema) => void;
  handleRemove: (p: IProductResSchema, i: number) => void;
  isReadOnly: boolean;
}

const ProductsTable: FC<ProductsTableProps> = ({
  isValid,
  products,
  isEditable,
  offeringType,
  handleSelect,
  handleRemove,
  isReadOnly,
}: ProductsTableProps) => {
  return (
    <>
      <MGrid
        gridTemplateColumns="120px 1fr"
        columnGap={2}
        mb={4}
        pl={8}
        w="full"
        alignItems="center"
      >
        <MText fontWeight="bold" fontSize="md">
          Add Products
        </MText>
        <ProductSearch
          isDisabled={!isEditable || isReadOnly}
          onSelect={handleSelect}
          selected={products}
          offeringType={offeringType}
          mb={4}
        />
      </MGrid>

      <MBox pl={8} pr={2} w="full" data-testid="offering-form-product-list">
        {products.length > 0 && (
          <MGrid
            gridTemplateColumns="0.9fr 0.9fr 2fr  0.1fr"
            w="full"
            alignItems="center"
          >
            <MDivider gridColumn="1 / -1" mb={2} />
            <OfferingHeaderCell pr={4} pb={4} pt={4}>
              <HeaderCellText textProps={{ fontSize: 'md' }}>
                Products
              </HeaderCellText>
            </OfferingHeaderCell>
            <OfferingHeaderCell pr={4} pb={4} pt={4}>
              <HeaderCellText textProps={{ fontSize: 'md' }}>
                Product Type
              </HeaderCellText>
            </OfferingHeaderCell>
            <OfferingHeaderCell gridColumn="span " pb={4} pt={4}>
              <MFlex justifyContent="space-between">
                <HeaderCellText textProps={{ fontSize: 'md' }}>
                  Description
                </HeaderCellText>
              </MFlex>
            </OfferingHeaderCell>

            <MDivider gridColumn="1 / -1" />
            {sortByProductType(products, 'name').map(
              (product: IProductResSchema, index: number) => (
                <Fragment key={product.id}>
                  <BodyCell bgColor={getRowColor(index)}>
                    <MText
                      pl={4}
                      fontSize="sm"
                      color="tPurple.base"
                      fontWeight="regular"
                    >
                      {product.name}
                    </MText>
                  </BodyCell>
                  <BodyCell bgColor={getRowColor(index)}>
                    <MText color="tPurple.base" textTransform="capitalize">
                      {PRODUCT_TYPE_DISPLAY_SHORT[product.productType]}
                    </MText>
                  </BodyCell>
                  <BodyCell
                    bgColor={getRowColor(index)}
                    gridColumn={
                      isEditable && products.length > 0 ? 'span 1' : 'span 2'
                    }
                  >
                    <MText color="tPurple.base">{product.description}</MText>
                  </BodyCell>
                  {!isReadOnly && isEditable && products.length > 0 && (
                    <BodyCell
                      justify="center"
                      bgColor={getRowColor(index)}
                      cursor="pointer"
                    >
                      <Tooltip label="Remove" placement="bottom-end">
                        <MCustomIconButton
                          title="Remove"
                          variant="icon"
                          icon={MdClose}
                          onClick={() => handleRemove(product, index)}
                        />
                      </Tooltip>
                    </BodyCell>
                  )}
                  <MDivider gridColumn="1 / -1" />
                </Fragment>
              ),
            )}
          </MGrid>
        )}
      </MBox>
    </>
  );
};

export default ProductsTable;
