import noop from 'lodash/noop';
import { handleApiErrorToast } from '~app/api/axios';
import {
  useGetQuoteBillingSchedule,
  useGetQuoteBillingScheduleV2,
} from '~app/api/cpqService';
import { IQuoteBillingScheduleRespSchema } from '~app/types';
import { useFlags } from '../services/launchDarkly';

export interface QuoteDataTypes {
  loading: boolean;
  quoteBillingSchedule?: IQuoteBillingScheduleRespSchema | null;
  fetchBillingSchedule: () => void;
  fetchBillingScheduleV2: () => void;
}

const useBillingSchedule = (quoteId: string): QuoteDataTypes => {
  const { allowBillingScheduleV2 } = useFlags();

  const {
    data: quoteBillingSchedule,
    refetch: fetchBillingSchedule,
    isFetching: loading,
  } = useGetQuoteBillingSchedule(quoteId, {
    onError: (error) => handleApiErrorToast(error),
  });

  const {
    data: quoteBillingScheduleV2,
    refetch: fetchBillingScheduleV2,
    isFetching: loadingV2,
  } = useGetQuoteBillingScheduleV2(quoteId, {
    onError: (error) => handleApiErrorToast(error),
  });

  return {
    loading: allowBillingScheduleV2 ? loadingV2 : loading,
    quoteBillingSchedule: allowBillingScheduleV2
      ? quoteBillingScheduleV2
      : quoteBillingSchedule,
    fetchBillingSchedule: quoteId ? fetchBillingSchedule : noop,
    fetchBillingScheduleV2: quoteId ? fetchBillingScheduleV2 : noop,
  };
};

export default useBillingSchedule;
