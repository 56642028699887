import { boolean, string, z } from 'zod';
import { getRequiredMessage } from '~app/utils/messages';
import { CustomFieldRecordSchema } from './customFieldsTypes';
import { BaseResponseSchema } from './miscTypes';
import { UsageTypeResSchema } from './usageTypes';

export enum ProductTypeEnum {
  // ARREARS exists on the BE, but is not used in the FE: you cannot create an ARREARS product (but you can make a rate's subscription timing = ARREARS)
  ADVANCE = 'ADVANCE',
  // SUBSCRIPTION = 'SUBSCRIPTION',   // will eventually replace ADVANCE
  USAGE = 'USAGE',
  ONETIME = 'ONETIME',
  MIN_COMMIT = 'MIN_COMMIT',
  ONETIME_PREPAID_CREDIT = 'ONETIME_PREPAID_CREDIT',
}
export const ProductTypeEnumZ = z.nativeEnum(ProductTypeEnum);

export enum PrePaidCreditConfigTypeEnum {
  ALL_PRODUCTS = 'ALL_PRODUCTS',
  USAGE_PRODUCTS = 'USAGE_PRODUCTS',
  SUBSCRIPTION_PRODUCTS_ONLY = 'SUBSCRIPTION_PRODUCTS_ONLY',
  SPECIFIC_PRODUCTS = 'SPECIFIC_PRODUCTS',
}
export enum ProductStatusEnum {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
export enum RecognitionMethodEnum {
  IMMEDIATE = 'IMMEDIATE',
  EVENLY_MONTHLY_IN_ADVANCE = 'EVENLY_MONTHLY_IN_ADVANCE',
  // THESE DO NOT YET EXIST
  EVENLY_MONTHLY_IN_ARREARS = 'EVENLY_MONTHLY_IN_ARREARS',
  EVENLY_MONTHLY_12_MONTHS = 'EVENLY_MONTHLY_12_MONTHS',
  EVENLY_MONTHLY_3_MONTHS = 'EVENLY_MONTHLY_3_MONTHS',
}
export const ProductStatusEnumZ = z.nativeEnum(ProductStatusEnum);

export const PrePaidCreditConfigTypeEnumZ = z.nativeEnum(
  PrePaidCreditConfigTypeEnum,
);
export const RecognitionMethodEnumZ = z.nativeEnum(RecognitionMethodEnum);

// from POST /api/products
export const ProductSchema = z.object({
  id: z.string().optional(),
  customId: z.string().max(255).nullish(),
  financeId: z.string().nullish(),
  name: z
    .string({ required_error: getRequiredMessage('Name') })
    .min(1)
    .max(80),
  description: z
    .string({ required_error: getRequiredMessage('Description') })
    .min(1)
    .max(80),
  status: ProductStatusEnumZ,
  productType: ProductTypeEnumZ,
  usageTypeIds: z.array(z.string()).nullish(),
  sku: z.string().max(255).nullish(),
  // TODO: we should make this required, but requires all DB entries to be updated
  recognitionMethod: RecognitionMethodEnumZ.nullish(),
  oneTimePrepaidCreditConfig: z
    .object({
      type: PrePaidCreditConfigTypeEnumZ,
    })
    .nullish(),
  customFields: CustomFieldRecordSchema.optional(),
  taxItemCode: z.string().nullish(),
  taxExempt: z.boolean().default(false).nullish(),
});

export type IProductSchema = z.infer<typeof ProductSchema>;
export type IProduct = IProductSchema;

export const ProductResSchema = BaseResponseSchema.extend({
  customId: string().nullable(),
  financeId: string().nullable(),
  name: string(),
  description: string(),
  status: ProductStatusEnumZ,
  productType: ProductTypeEnumZ,
  usageTypes: z.array(UsageTypeResSchema).nullish(),
  // TODO: we should make this required, but requires all DB entries to be updated
  recognitionMethod: RecognitionMethodEnumZ.nullish(),
  sku: string().nullable(),
  locked: boolean(),
  customFields: CustomFieldRecordSchema.optional(),
  taxItemCode: z.string().nullish(),
  taxExempt: z.boolean().default(false).nullish(),
});
export type IProductResSchema = z.infer<typeof ProductResSchema>;

export const ProductSchemaUI = ProductSchema.extend({
  prices: z.array(z.any()).nullish(),
  createDate: z.union([z.string(), z.date()]).nullish(),
  createdBy: string().nullish(),
  modifyDate: string().nullish(),
  lastModifiedBy: string().nullish(),
});
export type IProductSchemaUI = z.infer<typeof ProductSchemaUI>;
