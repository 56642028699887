import * as z from 'zod';
import { getRequiredMessage } from '~app/utils/messages';

export enum CustomFieldStatusEnum {
  ACTIVE = 'ACTIVE',
}
export const CustomFieldStatusEnumZ = z.nativeEnum(CustomFieldStatusEnum);

export enum CustomFieldPermissionsEnum {
  READ = 'READ',
  WRITE = 'WRITE',
}
export const CustomFieldPermissionsEnumZ = z.nativeEnum(
  CustomFieldPermissionsEnum,
);

export enum CustomFieldPermissionsUiEnum {
  HIDDEN = 'HIDDEN',
  EDITABLE = 'EDITABLE',
  READ_ONLY = 'READ_ONLY',
}
export const CustomFieldPermissionsUiEnumZ = z.nativeEnum(
  CustomFieldPermissionsUiEnum,
);

export enum CustomFieldEntityEnum {
  ACCOUNT = 'ACCOUNT',
  BILL_GROUP = 'BILL_GROUP',
  CONTACT = 'CONTACT',
  CONTRACT = 'CONTRACT',
  CREDIT = 'CREDIT',
  CREDIT_NOTE = 'CREDIT_NOTE',
  INVOICE = 'INVOICE',
  OFFERING = 'OFFERING',
  PRODUCT = 'PRODUCT',
  QUOTE = 'QUOTE',
}
export const CustomFieldEntityEnumZ = z.nativeEnum(CustomFieldEntityEnum);

export enum CustomFieldTypeEnum {
  CHECKBOX = 'CHECKBOX',
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  SINGLE_LINE_TEXT = 'SINGLE_LINE_TEXT',
}
export const CustomFieldTypeEnumZ = z.nativeEnum(CustomFieldTypeEnum);

export interface ICustomField {
  id: string;
  createdAt: string;
  name: string;
  status: CustomFieldStatusEnum;
  roleIds: string[];
}

export const CustomFieldReqSchema = z.object({
  entity: CustomFieldEntityEnumZ,
  status: CustomFieldStatusEnumZ,
  key: z
    .string()
    .min(1, getRequiredMessage('API Name'))
    .max(40, 'Must be 40 or fewer characters long.')
    .regex(/^[a-zA-Z0-9_]*$/, 'Must contain only alphanumeric and underscore.'),
  displayLabel: z
    .string()
    .min(1, getRequiredMessage('Label'))
    .max(60, 'Must be 60 or fewer characters long.'),
  description: z
    .string()
    .max(255, 'Must be 255 or fewer characters long.')
    .nullish(),
  tooltip: z.string().nullish(),
  type: CustomFieldTypeEnumZ,
  permissions: z.array(CustomFieldPermissionsEnumZ),
});
export type ICustomFieldReqSchema = z.infer<typeof CustomFieldReqSchema>;

/**
 * UI displays permissions differently than the API
 */
export const CustomFieldReqUiSchema = CustomFieldReqSchema.omit({
  permissions: true,
}).extend({
  permissions: CustomFieldPermissionsUiEnumZ,
});
export type ICustomFieldReqUiSchema = z.infer<typeof CustomFieldReqUiSchema>;

// from GET /rules/rules/{id}
export const CustomFieldResSchema = z.object({
  id: z.string(),
  entity: CustomFieldEntityEnumZ,
  status: CustomFieldStatusEnumZ,
  key: z.string().min(1, getRequiredMessage('API Name')),
  displayLabel: z.string().min(1, getRequiredMessage('Label')),
  description: z.string().nullish(),
  tooltip: z.string().nullish(),
  type: CustomFieldTypeEnumZ,
  permissions: z.array(CustomFieldPermissionsEnumZ),
});

export type ICustomFieldResSchema = z.infer<typeof CustomFieldResSchema>;

export const CustomFieldListResSchema = CustomFieldResSchema.omit({ id: true });
export type CustomFieldListRes = z.infer<typeof CustomFieldListResSchema>;

// Custom field type that exists on each entity that supports custom fields
export const CustomFieldRecordSchema = z.record(
  z.union([z.string(), z.number(), z.boolean()]).nullish(),
);
export type ICustomFieldRecordSchema = z.infer<typeof CustomFieldRecordSchema>;

export type ICustomFieldMode = 'modal' | 'accordion' | 'drawer' | 'page';
