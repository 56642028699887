import { MPageContainer } from '~app/components/Monetize';
import GuidedQuotingForm from './GuidedQuotingForm';

const GuidedQuotingNewPage = () => {
  return (
    <MPageContainer alignItems="stretch">
      <GuidedQuotingForm />
    </MPageContainer>
  );
};

export default GuidedQuotingNewPage;
