import * as Sentry from '@sentry/react';
import { FunctionComponent as FC, useEffect } from 'react';
import { MButton, MFlex, MHeading, MText } from '~components/Monetize';
import { ENVIRONMENT, RELEASE, SENTRY } from '~config';
import { useAuth } from './auth0';

// Don't initialize sentry for localhost
// we were using the enabled flag in the past, but this caused lots of console logs
if (!ENVIRONMENT.IS_LOCAL_DEV && !ENVIRONMENT.IS_PLAYWRIGHT) {
  Sentry.init({
    dsn: SENTRY.dsn,
    integrations: [
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    // Don't capture sessions unless there is an actual error
    replaysSessionSampleRate: 0,
    // Always capture session if there is an error
    replaysOnErrorSampleRate: 1.0,
    environment: ENVIRONMENT.HOST_TYPE,
    attachStacktrace: true,
    release: RELEASE.releaseVersion,
    beforeSend: (event, hint) => {
      // Ensure app crashes caught by error boundary are flagged as unhandled
      const fromGlobalCrash = event.exception?.values?.some(
        (value) => value.type === 'React ErrorBoundary Error',
      );
      if (fromGlobalCrash) {
        event.exception?.values?.forEach((value) => {
          if (value.mechanism?.handled) {
            value.mechanism.handled = false;
          }
        });
      }
      return event;
    },
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
      // http://stackoverflow.com/questions/4113268
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
    ],
    denyUrls: [
      /https?:\/\/((cdn|www)\.)?testrigor\.com/,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
    ],
  });
}

export default Sentry;

const FallbackComponent = () => {
  const handleReturnToPreviousPage = () => {
    window.history.back();
    setTimeout(function () {
      window.location.reload();
    }, 200);
  };

  return (
    <MFlex
      w="100vw"
      h="100vh"
      p={8}
      direction="column"
      justify="center"
      align="center"
    >
      <MHeading as="p" size="lg" mb={3}>
        Sorry, it looks like there was an error!
      </MHeading>
      <MText mb={3} color="gray.700">
        Please reach out to your account team
      </MText>
      <MButton onClick={handleReturnToPreviousPage}>
        Return to previous page
      </MButton>
    </MFlex>
  );
};

// eslint-disable-next-line react/prop-types
export const SentryErrorBoundary: FC<any> = ({ children }) => {
  const { isAuthenticated, userId, user, tenantId, currentTenant } = useAuth();

  if (isAuthenticated) {
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: userId,
        email: user?.email,
      });
    });
  }

  const handleBeforeCapture = (
    scope: Sentry.Scope,
    error: Error | null,
    componentStack: string | null,
  ) => {
    scope.setTag('tenant.id', tenantId);
    currentTenant?.name && scope.setTag('tenant.name', currentTenant.name);
    currentTenant?.testTenant &&
      scope.setTag('tenant.testTenant', currentTenant.testTenant);
    scope.setLevel('fatal');
  };

  return (
    <Sentry.ErrorBoundary
      fallback={FallbackComponent}
      showDialog
      beforeCapture={handleBeforeCapture}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};

/**
 * Set context for Sentry so that no matter where events are logged,
 * we always know which user and which tenant the error originate from.
 */
export const useSentry = () => {
  const { userId, user, tenantId, currentTenant } = useAuth();

  useEffect(() => {
    if (userId) {
      Sentry.setUser({
        id: userId,
        email: user?.email,
      });
    }
  }, [userId, user]);

  useEffect(() => {
    if (tenantId || currentTenant) {
      Sentry.setContext('tenant', {
        id: tenantId,
        name: currentTenant?.name,
        testTenant: currentTenant?.testTenant,
      });
    }
  }, [tenantId, currentTenant]);
};
