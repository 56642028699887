import { FormLabel } from '@chakra-ui/react';
import { FC } from 'react';
import { MBox, MFlex, MText } from '~app/components/Monetize';

interface QuoteBasicInfoLineProps {
  title: string;
  children?: React.ReactElement<any> | string;
  inputId?: string;
  showRequired?: boolean;
}

export const QuoteBasicInfoLine: FC<QuoteBasicInfoLineProps> = ({
  title,
  children,
  inputId,
  showRequired,
}: QuoteBasicInfoLineProps) => {
  return (
    <MFlex
      alignItems="center"
      justifyContent="space-between"
      minHeight="8"
      my="1"
    >
      <FormLabel
        m="0"
        color="tGray.darkPurple"
        mr="6"
        htmlFor={inputId}
        fontWeight="400"
        sx={{ textWrap: 'nowrap' }}
      >
        {title}
        {showRequired && (
          <MText as="span" color="tRed.base" ml="1">
            *
          </MText>
        )}
      </FormLabel>
      <MBox minWidth={0}>{children}</MBox>
    </MFlex>
  );
};
