import { AggregationModelEnum } from '~app/types';
import { FieldMappingSystemType } from '~app/types/fieldMappingTypes';
import { ApiQueryItem } from './queryUtils';
import { asQueryUtil } from './utils';

/**
 * Keys for React Query.
 * There are multiple sub-keys here because this service spans multiple concerns.
 * In the future, we may want to split this service up into multiple services.
 *
 * @link https://tanstack.com/query/v4/docs/guides/query-keys
 * @link https://tkdodo.eu/blog/effective-react-query-keys
 */

/**
 * TABLE SEARCH
 * /api/{entity}/search?query={searchTerm}
 */
const SEARCH_BASE = ['search'] as const;

const TABLE_SEARCH_KEYS = {
  base: SEARCH_BASE,
  accounts: () => [...TABLE_SEARCH_KEYS.base, 'accounts'] as const,
};

export const TABLE_SEARCH = asQueryUtil({
  accountSearch: {
    list: {
      endpoint: `/api/accounts/search`,
      queryKey: [...SEARCH_BASE, 'accounts'] as const,
    },
  },
  productSearch: {
    list: {
      endpoint: `/api/products/search`,
      queryKey: [...SEARCH_BASE, 'products'] as const,
    },
  },
  offeringSearch: {
    list: {
      endpoint: `/api/offerings/search`,
      queryKey: [...SEARCH_BASE, 'offerings'] as const,
    },
  },
});

/**
 * ACCOUNT
 */
export const accountServiceQueryKeys = {
  /** Keys for all account actions and other actions nested under an account */
  accounts: {
    base: ['accounts'] as const,
    accountList: () =>
      [...accountServiceQueryKeys.accounts.base, 'list'] as const,
    accountDetail: (id: string) =>
      [...accountServiceQueryKeys.accounts.base, id] as const,

    accountDetailOverview: (id: string) =>
      [
        ...accountServiceQueryKeys.accounts.accountDetail(id),
        'overview',
      ] as const,
    accountContacts: (accountId: string) =>
      [
        ...accountServiceQueryKeys.accounts.accountDetail(accountId),
        'contacts',
      ] as const,
    accountSubscriptionOverview: (accountId: string) =>
      [
        ...accountServiceQueryKeys.accounts.accountDetailOverview(accountId),
        'subscriptions',
      ] as const,
    invoiceList: (accountId: string) =>
      [
        ...accountServiceQueryKeys.accounts.base,
        accountId,
        'invoices',
        'list',
      ] as const,
    invoicePreview: (accountId: string, billGroupId: string) =>
      [
        ...accountServiceQueryKeys.accounts.base,
        accountId,
        'invoices',
        'preview',
        billGroupId,
      ] as const,
  },
  billGroups: {
    base: ['billGroup'] as const,
    billGroupByAccount: (accountId: string) =>
      [...accountServiceQueryKeys.billGroups.base, accountId, 'list'] as const,
    billGroupById: (id: string) =>
      [...accountServiceQueryKeys.billGroups.base, id] as const,
    billGroupStatsById: (id: string) =>
      [...accountServiceQueryKeys.billGroups.base, id, 'stats'] as const,
  },
  credits: {
    base: ['credit'] as const,
    creditListByAccount: (accountId: string) =>
      [...accountServiceQueryKeys.credits.base, 'list', accountId] as const,
    creditDetail: (id: string) =>
      [...accountServiceQueryKeys.credits.base, id] as const,
    htmlTemplate: (creditId: string) =>
      [...accountServiceQueryKeys.credits.base, creditId, 'html'] as const,
  },
  creditNotes: {
    base: ['creditNotes'] as const,
    creditNoteById: (creditNoteId: string) => [
      ...accountServiceQueryKeys.creditNotes.base,
      creditNoteId,
    ],
    htmlTemplate: (creditNoteId: string) =>
      [
        ...accountServiceQueryKeys.creditNotes.base,
        creditNoteId,
        'html',
      ] as const,
  },
  payments: {
    base: ['payments'] as const,
    paymentByAccount: (accountId: string) =>
      [...accountServiceQueryKeys.payments.base, accountId, 'list'] as const,
    paymentById: (id: string) =>
      [...accountServiceQueryKeys.payments.base, id] as const,
    htmlTemplate: (paymentId: string) =>
      [...accountServiceQueryKeys.payments.base, paymentId, 'html'] as const,
  },
  usage: {
    base: ['usage'] as const,
    usageListBase: () =>
      [...accountServiceQueryKeys.usage.base, 'list'] as const,
    usageList: (params: {
      subscriptionId: string;
      usageTypeIds: string[];
      startTime: string;
      endTime: string;
    }) => [...accountServiceQueryKeys.usage.usageListBase(), params] as const,
  },
  paymentMethods: {
    base: ['paymentMethods'] as const,
    paymentMethod: (id: string) =>
      [...accountServiceQueryKeys.paymentMethods.base, id] as const,
    paymentMethodList: (accountId: string) =>
      [
        ...accountServiceQueryKeys.paymentMethods.base,
        accountId,
        'list',
      ] as const,
  },
  refunds: {
    base: ['refunds'] as const,
    refundListByAccount: (accountId: string) =>
      [...accountServiceQueryKeys.refunds.base, 'list', accountId] as const,
    refundById: (id: string) =>
      [...accountServiceQueryKeys.refunds.base, id] as const,
    htmlTemplate: (id: string) =>
      [...accountServiceQueryKeys.refunds.base, id, 'html'] as const,
  },
};

const queryKeysAccount: Required<
  Omit<ApiQueryItem, 'create' | 'delete' | 'upload'>
> = {
  list: {
    endpoint: `/api/accounts`,
    queryKey: accountServiceQueryKeys.accounts.accountList(),
    // byIdQueryKey - API response for list and detail is not the same
  },
  byId: {
    endpoint: (id: string) => `/api/accounts/${id}`,
    queryKey: (id: string) =>
      accountServiceQueryKeys.accounts.accountDetail(id),
  },
  update: {
    endpoint: (id: string) => `/api/accounts/${id}`,
    invalidateKeys: [
      accountServiceQueryKeys.accounts.accountList(),
      TABLE_SEARCH_KEYS.accounts(),
    ],
    setDataKey: (id: string) =>
      accountServiceQueryKeys.accounts.accountDetail(id),
    skipListUpdate: true,
  },
};

export const ACCOUNT_SERVICE_API = asQueryUtil({
  accounts: queryKeysAccount,
});

/**
 * CONTACT
 */
export const contactServiceQueryKeys = {
  base: ['contacts'] as const,
  contactList: () => [...contactServiceQueryKeys.base, 'list'] as const,
  contactDetail: (id: string) => [...contactServiceQueryKeys.base, id] as const,
};

export const notesServiceQueryKeys = {
  base: ['notes'] as const,
  noteListById: (entityPath: string, id: string) =>
    [...notesServiceQueryKeys.base, 'list', entityPath, id] as const,
};

/**
 * SUBSCRIPTION
 */
export const subscriptionServiceQueryKeys = {
  base: ['subscriptions'] as const,
  subscriptionDetail: (id: string) =>
    [...subscriptionServiceQueryKeys.base, id] as const,
  subscriptionMRRAmount: (accountId: string, subscriptionId: string) =>
    [
      ...accountServiceQueryKeys.accounts.accountDetail(accountId),
      ...subscriptionServiceQueryKeys.subscriptionDetail(subscriptionId),
      'mrrAmount',
    ] as const,
  subscriptionARRAmount: (accountId: string, subscriptionId: string) =>
    [
      ...accountServiceQueryKeys.accounts.accountDetail(accountId),
      ...subscriptionServiceQueryKeys.subscriptionDetail(subscriptionId),
      'arrAmount',
    ] as const,
  subscriptionEstimate: (accountId: string, subscriptionId: string) =>
    [
      ...accountServiceQueryKeys.accounts.accountDetail(accountId),
      ...subscriptionServiceQueryKeys.subscriptionDetail(subscriptionId),
      'estimate',
    ] as const,
  subscriptionTotalSpend: (accountId: string, subscriptionId: string) =>
    [
      ...accountServiceQueryKeys.accounts.accountDetail(accountId),
      ...subscriptionServiceQueryKeys.subscriptionDetail(subscriptionId),
      'totalSpend',
    ] as const,
  subscriptionHistory: (accountId: string, subscriptionId: string) =>
    [
      ...accountServiceQueryKeys.accounts.accountDetail(accountId),
      ...subscriptionServiceQueryKeys.subscriptionDetail(subscriptionId),
      'history',
    ] as const,
  accountSubscriptionList: (accountId: string) =>
    [
      ...accountServiceQueryKeys.accounts.accountDetail(accountId),
      'subscriptions',
      'list',
    ] as const,
  subscriptionScheduledChanges: (id: string) =>
    [
      ...subscriptionServiceQueryKeys.subscriptionDetail(id),
      'scheduledChanges',
    ] as const,
  subscriptionUsageConsumptionBase: () =>
    [...subscriptionServiceQueryKeys.base, 'usageConsumption'] as const,
  subscriptionUsageConsumption: ({
    subscriptionId,
    ...rest
  }: {
    subscriptionId: string;
    usageTypeIds?: string[];
    startTime?: string;
    endTime?: string;
    aggregationModel?: AggregationModelEnum;
  }) =>
    [
      ...subscriptionServiceQueryKeys.subscriptionUsageConsumptionBase(),
      subscriptionId,
      rest,
    ] as const,
};

/**
 * REACT QUERY HELPER
 *
 * Map of helpers for React Query utility functions
 *
 */
const accountCredits: ApiQueryItem<{
  accountId: string;
  billGroupId?: string;
  // Some endpoints don't follow same pattern
  skipNestedEndpoint?: boolean;
}> = {
  list: {
    endpoint: (args) => `/api/accounts/${args?.accountId}/credits`,
    queryKey: (args) =>
      accountServiceQueryKeys.credits.creditListByAccount(args?.accountId),
    byIdQueryKey: (id: string) =>
      accountServiceQueryKeys.credits.creditDetail(id),
  },
  byId: {
    endpoint: (id: string) => `/api/credits/${id}`,
    queryKey: (id: string) => accountServiceQueryKeys.credits.creditDetail(id),
  },
  create: {
    endpoint: (args) =>
      `/api/accounts/${args?.accountId!}/billGroups/${args?.billGroupId!}/credits`,
    invalidateKeys: (id: string, args) => [
      accountServiceQueryKeys.credits.creditListByAccount(args?.accountId!),
    ],
    setDataKey: (id: string) =>
      accountServiceQueryKeys.credits.creditDetail(id),
  },
  update: {
    endpoint: (id, args) =>
      // activate/deactivate endpoints have a different URL pattern from PUT
      args?.skipNestedEndpoint
        ? `/api/credits/${id}` // used for actions (e.x. /api/credits/:creditId/deactivate)
        : `/api/accounts/${args?.accountId!}/billGroups/${args?.billGroupId!}/credits/${id}`,
    invalidateKeys: (id: string, args) => [
      accountServiceQueryKeys.credits.creditListByAccount(args?.accountId!),
    ],
    setDataKey: (id: string) =>
      accountServiceQueryKeys.credits.creditDetail(id),
  },
};

const accountPaymentMethods: ApiQueryItem<{
  accountId: string;
  paymentMethodId?: string;
  skipNestedEndpoint?: boolean;
}> = {
  list: {
    endpoint: (args) => `/api/accounts/${args?.accountId}/paymentMethods`,
    queryKey: (args) =>
      accountServiceQueryKeys.paymentMethods.paymentMethodList(args?.accountId),
    byIdQueryKey: (id: string) =>
      accountServiceQueryKeys.paymentMethods.paymentMethod(id),
  },
  byId: {
    endpoint: (id: string) => `/api/paymentMethods/${id}`,
    queryKey: (id: string) =>
      accountServiceQueryKeys.paymentMethods.paymentMethod(id),
  },
};

const billGroups: ApiQueryItem<{
  accountId: string;
}> = {
  list: {
    endpoint: (args) => `/api/accounts/${args?.accountId}/billGroups`,
    queryKey: (args) =>
      accountServiceQueryKeys.billGroups.billGroupByAccount(args?.accountId),
    byIdQueryKey: (id: string) =>
      accountServiceQueryKeys.billGroups.billGroupById(id),
  },
  byId: {
    endpoint: (id: string) => `/api/billGroups/${id}`,
    queryKey: (id: string) =>
      accountServiceQueryKeys.billGroups.billGroupById(id),
  },
  create: {
    endpoint: (args) => `/api/accounts/${args?.accountId!}/billGroups`,
    invalidateKeys: (id: string, args) => [
      accountServiceQueryKeys.billGroups.billGroupByAccount(args?.accountId!),
    ],
    setDataKey: (id: string) =>
      accountServiceQueryKeys.billGroups.billGroupById(id),
  },
  update: {
    endpoint: (id, args) =>
      `/api/accounts/${args?.accountId!}/billGroups/${id}`,
    invalidateKeys: (id: string, args) => [
      accountServiceQueryKeys.billGroups.billGroupByAccount(args?.accountId!),
    ],
    setDataKey: (id: string) =>
      accountServiceQueryKeys.billGroups.billGroupById(id),
  },
};

const contacts: ApiQueryItem<{ accountId?: string }> = {
  list: {
    endpoint: (args) => `/api/contacts`,
    queryKey: (args) => contactServiceQueryKeys.contactList(),
    byIdQueryKey: (id: string) => contactServiceQueryKeys.contactDetail(id),
  },
  byId: {
    endpoint: (id: string) => `/api/contacts/${id}`,
    queryKey: (id: string) => contactServiceQueryKeys.contactDetail(id),
  },
  create: {
    endpoint: (args) => `/api/contacts`,
    invalidateKeys: (id: string, args) => {
      const output = [contactServiceQueryKeys.contactList()];
      if (args?.accountId) {
        accountServiceQueryKeys.accounts.accountContacts(args.accountId);
      }
      return output;
    },
    setDataKey: (id: string) => contactServiceQueryKeys.contactDetail(id),
  },
  update: {
    endpoint: (id, args) => `/api/contacts/${id}`,
    invalidateKeys: (id: string, args) => {
      const output = [contactServiceQueryKeys.contactList()];
      if (args?.accountId) {
        accountServiceQueryKeys.accounts.accountContacts(args.accountId);
      }
      return output;
    },
    setDataKey: (id: string) => contactServiceQueryKeys.contactDetail(id),
  },
  delete: {
    endpoint: (id, args) => `/api/contacts/${id}`,
    invalidateKeys: (id: string, args) => {
      const output = [contactServiceQueryKeys.contactList()];
      if (args?.accountId) {
        accountServiceQueryKeys.accounts.accountContacts(args.accountId);
      }
      return output;
    },
    setDataKey: (id: string) => contactServiceQueryKeys.contactDetail(id),
  },
};

const accountSubscriptions: Required<
  Omit<ApiQueryItem, 'create' | 'delete' | 'upload'>
> = {
  // if we ever have a need to GET /api/subscriptions (across accounts) this account-scoped endpoint should be moved elsewhere
  list: {
    endpoint: (args) => `/api/accounts/${args?.accountId}/subscriptions`,
    queryKey: (args) => {
      return subscriptionServiceQueryKeys.accountSubscriptionList(
        args.accountId,
      );
    },
    byIdQueryKey: (id: string) =>
      subscriptionServiceQueryKeys.subscriptionDetail(id),
  },
  byId: {
    endpoint: (id: string) => `/api/subscriptions/${id}`,
    queryKey: (id: string) =>
      subscriptionServiceQueryKeys.subscriptionDetail(id),
  },
  update: {
    endpoint: (id, args) =>
      // cancel endpoint has a different URL pattern from regular PUT
      args?.isCancelOrActivate
        ? `/api/billGroups/${args?.billGroupId}/subscriptions/${id}`
        : `/api/subscriptions/${id}`, // not used presently
    invalidateKeys: (subscriptionId: string, args) => {
      return [
        subscriptionServiceQueryKeys.accountSubscriptionList(args.accountId),
        subscriptionServiceQueryKeys.subscriptionDetail(subscriptionId),
      ];
    },
    setDataKey: (id: string) =>
      subscriptionServiceQueryKeys.subscriptionDetail(id),
    skipListUpdate: true,
  },
};

const paymentRefunds: ApiQueryItem = {
  byId: {
    endpoint: (id: string) => `/api/refunds/${id}`,
    queryKey: (id: string) => refundServiceQueryKeys.refundDetail(id),
  },
};

export const QUERY_UTILS_ENTRIES = asQueryUtil({
  accountCredits,
  accountPaymentMethods,
  accountSubscriptions,
  billGroups,
  contacts,
  paymentRefunds,
});

export const fieldMappingServiceQueryKeys = {
  base: ['crmFieldMapping'] as const,
  crmFields: (system: FieldMappingSystemType) =>
    [...fieldMappingServiceQueryKeys.base, 'crmFields', system] as const,
  crmFieldMappings: (system: FieldMappingSystemType) =>
    [
      ...fieldMappingServiceQueryKeys.base,
      'crmFieldMappings',
      'userDefined',
      system,
    ] as const,
  accountingFields: (system: FieldMappingSystemType) =>
    [...fieldMappingServiceQueryKeys.base, 'accountingFields', system] as const,
  accountingFieldMappings: (system: FieldMappingSystemType) =>
    [
      ...fieldMappingServiceQueryKeys.base,
      'accountingFieldMappings',
      'userDefined',
      system,
    ] as const,
};

export const priceUpliftServiceQueryKeys = {
  base: ['priceUplifts'] as const,
  priceUplift: (id: string) =>
    [...priceUpliftServiceQueryKeys.base, id] as const,
};

// REFUND
export const refundServiceQueryKeys = {
  base: ['refunds'] as const,
  refundDetail: (id: string) => [...refundServiceQueryKeys.base, id] as const,
};
