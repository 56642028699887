import { Switch } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { handleApiErrorToast } from '~app/api/axios';
import {
  MAccordion,
  MButton,
  MDivider,
  MFlex,
  MFormField,
  MFormFieldProps,
  MGrid,
  MGridItem,
  MRadio,
  MRadioGroup,
  MStack,
} from '~app/components/Monetize';
import { MSettingAccordionItem } from '~app/components/Monetize/MSettingAccordionItem';
import { useConfirmModal } from '~app/services/confirmModal';
import {
  IInvoiceEmailTimingSchema,
  InvoiceEmailTimingSchema,
} from '~app/types';

export type InvoiceEmailTimingProps = {
  emailTiming: IInvoiceEmailTimingSchema;
  onSubmit: (data: IInvoiceEmailTimingSchema) => Promise<void>;
};

const baseFieldProps: MFormFieldProps = {
  as: MFlex,
  alignItems: 'center',
  justifyContent: 'space-between',
  labelProps: { m: 0, fontWeight: 'normal' },
};

const fieldProps: MFormFieldProps = {
  ...baseFieldProps,
  borderBottom: '1px',
  paddingBottom: 2,
  borderColor: 'tGray.back',
};

export const InvoiceEmailTiming = ({
  emailTiming,
  onSubmit,
}: InvoiceEmailTimingProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailInvoiceTimingAuto, setIsEmailInvoiceTimingAuto] =
    useState(false);
  const { showConfirmModal, setModalLoading, closeModal, modalData } =
    useConfirmModal();

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    reset,
    getValues,
  } = useForm<IInvoiceEmailTimingSchema>({
    resolver: zodResolver(InvoiceEmailTimingSchema),
    mode: 'onChange',
    defaultValues: {
      automatic: true,
      attachInvoicePdf: false,
    },
  });

  useEffect(() => {
    if (emailTiming && !modalData) {
      reset(emailTiming);
    }
  }, [emailTiming, reset, modalData]);

  const handleSubmitEmailTiming = async (data: IInvoiceEmailTimingSchema) => {
    try {
      setIsLoading(true);
      await onSubmit(data);
    } catch (err: any) {
      handleApiErrorToast(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isEmailInvoiceTimingAuto) {
      if (isDirty && isValid) {
        const onYes = async () => {
          setModalLoading(true);
          const values = getValues();
          await handleSubmitEmailTiming(values);
          setModalLoading(false);
          setIsEmailInvoiceTimingAuto(false);
          closeModal();
        };

        showConfirmModal({
          title: `Are you sure?`,
          description:
            'MonetizeNow will email your customers automatically when invoices are created.',
          onYes,

          yesBtnProps: {
            variant: 'primary' as any,
          },
          onNo: () => {
            setIsEmailInvoiceTimingAuto(false);
            closeModal();
          },
          noBtnProps: {
            variant: 'cancel' as any,
          },
        });
      }
    }
  }, [
    isEmailInvoiceTimingAuto,
    isDirty,
    isValid,
    showConfirmModal,
    closeModal,
  ]);

  return (
    <MAccordion allowToggle reduceMotion w="100%">
      <MSettingAccordionItem
        description="Define Invoice sending behavior for newly created Bill Groups."
        label="Invoice Sending"
      >
        <form onSubmit={handleSubmit(handleSubmitEmailTiming)}>
          <MGrid templateColumns="repeat(12, 1fr)" gap={2}>
            <MGridItem colSpan={12}>
              <MFormField error={errors.automatic} {...fieldProps}>
                <Controller
                  name="automatic"
                  control={control}
                  render={({ field: { value, onChange, ...rest } }) => (
                    <MRadioGroup
                      onChange={(val) => {
                        setIsEmailInvoiceTimingAuto(val === 'true');
                        onChange && onChange(val === 'true');
                      }}
                      value={
                        typeof value === 'undefined' ? 'false' : String(value)
                      }
                      {...rest}
                      isDisabled={isLoading}
                    >
                      <MStack direction="column">
                        <MRadio value="true">Automatically</MRadio>
                        <MRadio value="false">Manually</MRadio>
                        );
                      </MStack>
                    </MRadioGroup>
                  )}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={12}>
              <MFormField
                label="Attach invoice as PDF to email"
                error={errors.attachInvoicePdf}
                {...fieldProps}
              >
                <Controller
                  name="attachInvoicePdf"
                  control={control}
                  render={({ field: { value, ...rest } }) => (
                    <Switch
                      isChecked={value}
                      isDisabled={isLoading}
                      {...rest}
                    />
                  )}
                />
              </MFormField>
            </MGridItem>
          </MGrid>
          <MDivider my={4} />
          <MFlex justifyContent="flex-end">
            <MButton
              type="submit"
              variant="primary"
              alignSelf="self-end"
              isDisabled={isLoading || !isDirty || !isValid}
              isLoading={isLoading}
              onClick={handleSubmit(handleSubmitEmailTiming)}
            >
              Save
            </MButton>
          </MFlex>
        </form>
      </MSettingAccordionItem>
    </MAccordion>
  );
};
