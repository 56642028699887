import { zodResolver } from '@hookform/resolvers/zod';
import {
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { SetupIntentResult, Stripe, StripeElements } from '@stripe/stripe-js';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FunctionComponent as FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useRecoilState } from 'recoil';
import { handleApiErrorToast } from '~app/api/axios';
import {
  shareQueryKeys,
  useCreateSharedInvoicePayment,
} from '~app/api/shareService';
import { getInvoiceShareStripePaymentCallbackRoute } from '~app/constants/routes';
import { tempSharedInvoicePaymentState } from '~app/store/global.store';
import { formatCurrency } from '~app/utils';
import {
  MBox,
  MButton,
  MCenterModal,
  MCustomNumberInput,
  MFlex,
  MFormField,
  MGrid,
  MGridItem,
  MInput,
  MPageLoader,
  MStack,
  MText,
} from '~components/Monetize';
import {
  IInvoiceRespSchema,
  ISharedInvoicePaymentReq,
  ISharedInvoicePaymentUI,
  SharedInvoicePaymentSchemaUI,
} from '~types';
import { nullifyEmptyStrings } from '../../../utils/misc';

interface SharedInvoiceFormFieldsProps {
  allowPartialPayment: boolean;
  invoice: IInvoiceRespSchema;
  invoiceSecretId: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isOpen: boolean;
  publicKey: string;
  setupIntent: string;
  tenantId: string;
  onClose: () => void;
  reloadData?: null | (() => void);
}

export const SharedInvoiceFormFields: FC<SharedInvoiceFormFieldsProps> = ({
  allowPartialPayment,
  invoice,
  invoiceSecretId,
  isDisabled,
  isLoading,
  isOpen,
  publicKey,
  tenantId,
  onClose,
}: SharedInvoiceFormFieldsProps) => {
  const queryClient = useQueryClient();
  const elements = useElements();
  const stripe = useStripe();
  const loading = false;
  const [sharedInvoicePayment, setSharedInvoicePayment] = useRecoilState(
    tempSharedInvoicePaymentState,
  );
  const {
    mutateAsync: doCreateSharedInvoicePayment,
    isLoading: isSavePaymentLoading,
  } = useCreateSharedInvoicePayment();

  const {
    mutate: confirmSetupStripePayment,
    isLoading: stripeLoading,
    data: confirmPaymentResp,
  } = useMutation(
    ({ stripe, elements }: { stripe: Stripe; elements: StripeElements }) =>
      stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: getStripeCallbackUrl(),
        },
        redirect: 'if_required',
      }),
  );

  useEffect(() => {
    // this effect does not run if Stripe decides the payment requires a redirect
    // in which case we handle it in StripePaymentCallback
    const createPayment = async (
      amount: number,
      confirmPaymentResp: SetupIntentResult,
    ) => {
      try {
        const billingDetails = getValues('billingDetails');
        const newPayment: ISharedInvoicePaymentReq = {
          amount,
          paymentToken: confirmPaymentResp!.setupIntent!.id,
          paymentMethodName: billingDetails.fullName, // the paymentMethodName property is not returned in the API response
          billingDetails,
        };
        await doCreateSharedInvoicePayment({
          invoiceSecretId,
          tenantId,
          body: nullifyEmptyStrings(newPayment),
        });
        queryClient.invalidateQueries([
          ...shareQueryKeys.invoiceDetail(invoiceSecretId),
        ]);
      } catch (err) {
        handleApiErrorToast(err);
      } finally {
        setSharedInvoicePayment(null);
      }
      onClose();
    };

    if (sharedInvoicePayment && confirmPaymentResp?.setupIntent?.id) {
      createPayment(
        allowPartialPayment && sharedInvoicePayment?.amount
          ? sharedInvoicePayment.amount
          : invoice.amountDue,
        confirmPaymentResp,
      );
    }
  }, [
    allowPartialPayment,
    sharedInvoicePayment,
    confirmPaymentResp,
    invoice.amountDue,
  ]);

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm<ISharedInvoicePaymentUI>({
    resolver: zodResolver(SharedInvoicePaymentSchemaUI),
    mode: 'onChange',
    defaultValues: {
      amount: invoice.amountDue,
      stripePublicKey: publicKey,
    },
  });

  const onSubmit = async (requestData: ISharedInvoicePaymentUI) => {
    if (!stripe || !elements) {
      return;
    }
    // Store in localstorage so we can get data back if Stripe invokes the callback
    setSharedInvoicePayment(requestData);
    confirmSetupStripePayment({ stripe, elements });
  };

  const onError = (errs: any, event: any) => {
    // pass
  };
  const onSubmitForm = handleSubmit(onSubmit, onError);

  const getStripeCallbackUrl = () => {
    const url = getInvoiceShareStripePaymentCallbackRoute(
      tenantId,
      invoiceSecretId,
    );
    return `${window.location.origin}${url}`;
  };

  return (
    <form onSubmit={onSubmitForm}>
      <MCenterModal
        isOpen={isOpen}
        onClose={onClose}
        modalTitle="Pay Invoice"
        size="sm"
        renderFooter={() => (
          <MStack
            spacing={4}
            direction="row"
            align="center"
            justify="right"
            flex={1}
          >
            <MButton onClick={onClose} variant="cancel" minW="auto">
              Cancel
            </MButton>
            <MButton
              variant="primary"
              isLoading={
                loading || stripeLoading || isSavePaymentLoading || isLoading
              }
              onClick={onSubmitForm}
              minW="auto"
            >
              Pay
            </MButton>
          </MStack>
        )}
      >
        <MBox>
          {loading && (
            <MFlex justify="center" my="2">
              <MPageLoader height="auto" />
            </MFlex>
          )}
          <PaymentElement />
          <MGrid templateColumns="repeat(12, 1fr)" gap={4} my={3}>
            <MGridItem colSpan={12}>
              <MFormField
                isDisabled={isDisabled}
                error={errors?.amount}
                label="Amount"
                isRequired
              >
                <Controller
                  name="amount"
                  control={control}
                  render={({ field: { onChange, ...field } }) =>
                    allowPartialPayment ? (
                      <MCustomNumberInput
                        precision={2}
                        isDisabled={isDisabled}
                        onChange={(_, valueAsNumber) => onChange(valueAsNumber)}
                        {...field}
                      />
                    ) : (
                      <MText>
                        {formatCurrency(field.value, {
                          currency: invoice.currency,
                        })}
                      </MText>
                    )
                  }
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={12}>
              <MFormField
                isDisabled={isDisabled}
                error={errors?.billingDetails?.fullName}
                label="Name"
                isRequired
              >
                <Controller
                  name="billingDetails.fullName"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <MInput {...field} />}
                />
              </MFormField>
            </MGridItem>
            <MGridItem colSpan={12}>
              <MFormField
                isDisabled={isDisabled}
                error={errors.billingDetails?.email}
                label="Email"
                isRequired
              >
                <Controller
                  name="billingDetails.email"
                  control={control}
                  defaultValue=""
                  render={({ field }) => <MInput {...field} />}
                />
              </MFormField>
            </MGridItem>
          </MGrid>
        </MBox>
      </MCenterModal>
    </form>
  );
};
