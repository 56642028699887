// REMOVE_AFTER_QUOTE_V2
import { useQueryClient } from '@tanstack/react-query';
import { formatISO } from 'date-fns/formatISO';
import { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { handleApiErrorToast } from '~app/api/axios';
import { useGetById } from '~app/api/queryUtils';
import { MPageLoader } from '~app/components/Monetize';
import { ROUTES } from '~app/constants';
import { useACL } from '~app/services/acl/acl';
import {
  CollaborationAccessLevelEnum,
  IQuoteRequestSchema,
  IQuoteRespSchema,
  IQuoteReviewReq,
  QuoteStatusEnum,
  QuoteTypeEnum,
  getQuoteRequestSchema,
} from '~app/types';
import { sortByProductType } from '~app/utils';
import { getQuoteRequestFromQuoteResponse } from '~app/utils/quotes';
import { useChangeQuoteOwner } from '../../../api/cpqService';
import { INITIAL_FORM_VALUES } from '../../../constants/quotes';
import { useProductOfferings, useQuote } from '../../../hooks';
import { QuoteForm } from './QuoteForm';
import QuoteReviewModal, { useQuoteReviewModalData } from './QuoteReviewModal';
import { ReviewQuote } from './ReviewQuote';
import { QuoteContextProvider, useQuoteState } from './quoteContext';
import { updateQuoteQueryCache } from './quoteUtils';
import { useFlags } from '~app/services/launchDarkly';
import { QuotePageV2 } from '../QuoteV2/QuotePageV2';

/**
 * Wrapper for Quote pages (Review and Edit)
 *
 * 1. Ensures the quote exists and is accessible, otherwise redirects to the quotes list
 * 2. Renders the quote edit or review page based on the quote status
 * 3. Based on permissions, may render the quote in read-only mode
 *
 */
export const QuotePageWrapper: FC = () => {
  const queryClient = useQueryClient();
  const [isInternalView, setIsInternalView] = useState<boolean>(false);
  const [isReadOnly, setIsReadOnly] = useState<boolean>(true);
  const { useQuoteEditV2 } = useFlags();

  const { quoteId = '' } = useParams();
  const navigate = useNavigate();
  const { canDo } = useACL();
  const canUpdateSales = canDo([['sales', 'update']]);

  const { data: quote } = useGetById<IQuoteRespSchema>(
    'cpqServiceQuotes',
    quoteId,
    {
      enabled: !!quoteId,
      retry: false,
      refetchOnWindowFocus: false,
      // Sort quoteItems so components don't have to handle sorting
      select: (data) => {
        return {
          ...data,
          quoteOfferings: data.quoteOfferings.map((offering) => ({
            ...offering,
            items: sortByProductType(offering.items),
          })),
        };
      },
      onSuccess: async (data) => {
        setQuote(data);
        handleInternalView(data);
      },
      onError: (err) => {
        handleApiErrorToast(err);
        navigate(ROUTES.SALES_QUOTES_ROUTE, { replace: true });
      },
    },
  );

  // UseQuote hook for handling quote api calls
  const quoteData = useQuote(quote);
  const {
    updateQuote,
    createQuote,
    transitionQuoteStatus,
    setQuote,
    loading: quoteLoading,
    billingScheduleLoading,
  } = quoteData;

  const changeOwnerData = useChangeQuoteOwner({
    onSuccess: (data) => {
      setQuote(data);
    },
  });
  const { isLoading: changeQuoteOwnerLoading } = changeOwnerData;

  // useProductOfferings hook for handling product catalog offerings api calls
  const productOfferingsData = useProductOfferings(
    quote?.currency,
    quote?.accountId,
    quote?.type === QuoteTypeEnum.AMENDMENT
      ? quote.contractAmendmentDate
      : quote?.contractStartDate,
  );
  const {
    loading: productOfferingsLoading,
    productOfferings,
    productOfferingsObj,
  } = productOfferingsData;

  // useForm hook for handling form states
  const methods = useForm<IQuoteRequestSchema>({
    mode: 'onChange',
    resolver: getQuoteRequestSchema(quote),
    defaultValues: {
      contractStartDate: formatISO(new Date(), { representation: 'date' }),
      ...INITIAL_FORM_VALUES,
    },
    values: quote ? getQuoteRequestFromQuoteResponse(quote) : undefined,
    resetOptions: {
      keepDirtyValues: true,
      keepErrors: true,
    },
  });

  const {
    formState: { isSubmitting },
  } = methods;

  const isLoading =
    isSubmitting ||
    quoteLoading ||
    productOfferingsLoading ||
    billingScheduleLoading ||
    changeQuoteOwnerLoading;

  // Other Quote states
  const quoteStateData = useQuoteState({
    quote,
    productOfferingsObj,
    isLoading: quoteLoading,
  });

  const handleInternalView = ({ accessLevel, status }: IQuoteRespSchema) => {
    const isReadOnly =
      !canUpdateSales || accessLevel === CollaborationAccessLevelEnum.READ;
    const isDraft = status === QuoteStatusEnum.DRAFT;

    setIsReadOnly(isReadOnly || !isDraft);

    const nextIsInternalView = !isReadOnly && isDraft;
    setIsInternalView((prevIsInternalView) =>
      prevIsInternalView === nextIsInternalView
        ? prevIsInternalView
        : nextIsInternalView,
    );
  };

  // handles quote update/create logic on form submit
  const onSubmit = async (data: IQuoteRequestSchema) => {
    const response = quote?.id
      ? await updateQuote(quote.id, data)
      : await createQuote(data);
    if (response) {
      updateQuoteQueryCache(queryClient, response);
    }

    return response;
  };

  const handleReview = async (extraData?: IQuoteReviewReq) => {
    try {
      // Avoid review from being called twice - in some rare cases this was happening nad not sure from where
      if (quoteStateData.isReviewBtnPressed) {
        return;
      }
      quoteStateData.setIsReviewBtnPressed(true);

      if (quote?.id) {
        await onSubmit(methods.getValues()); // adding manual onSubmit so that it could wait for the promise to be resolved
        if (quote.status === QuoteStatusEnum.DRAFT) {
          const res = await transitionQuoteStatus(quote.id, {
            newState: QuoteStatusEnum.REVIEW,
            extraData,
          });
          if (res) {
            setQuote(res);
            setIsInternalView(false);
          }
        }
      }
    } catch (error) {
      handleApiErrorToast(error);
    } finally {
      quoteStateData.setIsReviewBtnPressed(false);
    }
  };

  // Review Quote Modal data
  const reviewQuoteModalData = useQuoteReviewModalData({
    handleReview,
    quoteFirstApprovalUser: quoteData.quoteFirstApprovalUser,
  });

  /**
   * Ensures that the URL is correct based on the state of the quote
   * This usually only changes if the user visits the page directly and the quote changed states
   */
  useEffect(() => {
    // This should not happen unless there is a developer error, adding here as a fallback
    if (!quoteId) {
      handleApiErrorToast(new Error('A quote id is required'));
      navigate(ROUTES.SALES_QUOTES_ROUTE, { replace: true });
    }
  }, [quoteId]);

  useLayoutEffect(() => {
    if (quote) {
      const isReadOnly =
        !canUpdateSales ||
        quote.accessLevel === CollaborationAccessLevelEnum.READ;
      const isDraft = quote.status === QuoteStatusEnum.DRAFT;

      setIsReadOnly(isReadOnly || !isDraft);
    }
  }, [quote]);

  useLayoutEffect(() => {
    if (quote) {
      handleInternalView(quote);
    }
  }, []);

  /**
   * quote?.id !== quoteData?.quote?.id is added to ensure that we don't have data from previous quote
   * spread around the form when the quote is changed
   */
  if (!quote || quoteId !== quote.id || quote?.id !== quoteData?.quote?.id) {
    return <MPageLoader />;
  }

  return (
    <>
      <QuoteContextProvider
        initialQuote={quote}
        isReadOnly={isReadOnly}
        isInternalView={isInternalView}
        quoteFormMethods={methods}
        quoteData={quoteData}
        reviewQuoteModalData={reviewQuoteModalData}
        productOfferingsData={productOfferingsData}
        changeOwnerData={changeOwnerData}
        quoteStateData={quoteStateData}
        isLoading={isLoading}
        onSubmit={onSubmit}
        handleReview={handleReview}
        setIsInternalView={setIsInternalView}
      >
        {!useQuoteEditV2 && (
          <>
            {isInternalView && <QuoteForm />}
            {!isInternalView && <ReviewQuote />}
          </>
        )}
        {useQuoteEditV2 && <QuotePageV2 />}
      </QuoteContextProvider>
      <QuoteReviewModal
        reviewQuoteModalData={reviewQuoteModalData}
        quoteData={quoteData}
        handleReview={handleReview}
      />
    </>
  );
};
