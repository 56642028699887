import { FC } from 'react';
import { FiCheckCircle } from 'react-icons/fi';
import { QuoteBodyAccordion } from '~app/components/Quotes/QuoteBodyAccordion';
import { useQuoteContext } from '../../Quote/quoteContext';
import { ROLES } from '~app/constants';
import { ReviewQuoteActionButton } from '../../Quote/components/reviewQuote/ReviewQuoteActionButton';
import { useAuth } from '~app/services/auth0';
import { hasVariableAmountProduct } from '~app/utils/quotes';
import { MGrid, MBox } from '~app/components/Monetize';
import { QuoteFormTotalRow } from '../../Quote/components/QuoteFormTotalRow';
import { QuoteFormTotalRowAmendV2 } from '../../Quote/components/QuoteFormTotalRowAmendV2';

interface QuoteInternalSubmitProps {
  onOpenManualAcceptanceModal: () => void;
}

export const QuoteInternalSubmit: FC<QuoteInternalSubmitProps> = ({
  onOpenManualAcceptanceModal,
}: QuoteInternalSubmitProps) => {
  const {
    isReadOnly,
    quoteData: { quote, loading: quoteLoading },
    quoteStateData,
    methods,
    reviewQuoteModalData,
    actionRunning,
    isEsignEnabled,
    handleSendQuote,
    handleRecreateQuote,
    useAmendmentV2,
  } = useQuoteContext();

  const { currentTenantUserHasRole } = useAuth();
  const isActionLoading = actionRunning || quoteLoading;
  const hasValidRole = currentTenantUserHasRole(ROLES.QUOTES_ROLES);

  const anyOfferingLoading = Object.values(
    quoteStateData.offeringLoadingState,
  ).some((item) => item.isLoading);

  const anyVariableAmountProducts = hasVariableAmountProduct(quote);

  if (!quote) {
    return null;
  }

  return (
    <QuoteBodyAccordion
      title="Finalize Quote"
      icon={FiCheckCircle}
      hideExpandIcon
      mt="2"
      rightContent={
        <ReviewQuoteActionButton
          isReadOnly={isReadOnly}
          hasValidRole={hasValidRole}
          isActionLoading={isActionLoading}
          isEsignEnabled={isEsignEnabled}
          quote={quote}
          quoteStateData={quoteStateData}
          quoteFormMethods={methods}
          reviewQuoteModalData={reviewQuoteModalData}
          anyOfferingLoading={anyOfferingLoading}
          onAcceptQuote={onOpenManualAcceptanceModal}
          onRecreateQuote={handleRecreateQuote}
          onSendQuote={handleSendQuote}
        />
      }
    >
      <MGrid
        gridTemplateColumns={useAmendmentV2 ? '1fr 2fr' : '8fr 3.2fr'}
        gridTemplateRows="1fr"
        columnGap={4}
        w="full"
      >
        <MBox />

        {useAmendmentV2 ? (
          <QuoteFormTotalRowAmendV2
            anyVariableAmountProducts={anyVariableAmountProducts}
          />
        ) : (
          <QuoteFormTotalRow
            anyVariableAmountProducts={anyVariableAmountProducts}
          />
        )}
      </MGrid>
    </QuoteBodyAccordion>
  );
};
