import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import {
  AggregatedTransactablesResponse,
  ApplyUnapplyRequest,
  AppTrxnType,
  CreditStatusEnum,
  CreditTypeEnum,
  GetListApiConfig,
  GetListApiFilter,
  ICreditAndRebillReqSchema,
  ICreditAndRebillRespSchema,
  ICustomFieldRecordSchema,
  IGetCreditSchema,
  IInvoiceAddressDataSchema,
  IInvoiceRespSchema,
  IInvoiceRevRecognitionScheduleResponse,
  IInvoiceUpdateSchema,
  InvoiceSummaryResp,
  IUpsertCreditSchema,
  MassCreditInvoiceRow,
  MassCreditInvoicesResults,
  MassEmailCustomerInvoicesResults,
  TransactableSourceType,
  UnpaidInvoiceShareLink,
} from '~app/types';
import { IOneTimeInvoiceRequestSchema } from '../types/oneTimeInvoiceTypes';
import { sortByDate } from '../utils/dates';
import { splitArrayToMaxSize } from '../utils/misc';
import { apiGet, apiGetAllList, apiPost, apiPut, isAxiosError } from './axios';
import { ApiQueryItem } from './queryUtils';
import { asQueryUtil } from './utils';

export const invoiceServiceQueryKeys = {
  base: ['invoices'] as const,
  invoiceList: () => [...invoiceServiceQueryKeys.base, 'list'] as const,
  invoiceListAll: () => [...invoiceServiceQueryKeys.base, 'list-all'] as const,
  invoiceDetail: (id: string) => [...invoiceServiceQueryKeys.base, id] as const,
  invoiceRevenueSchedule: (id: string) =>
    [...invoiceServiceQueryKeys.base, id, 'revenueSchedule'] as const,
  invoiceUnpaidInvoices: (id: string) =>
    [...invoiceServiceQueryKeys.base, id, 'unpaidInvoices'] as const,
  creditAndRebill: (id: string) =>
    [...invoiceServiceQueryKeys.base, id, 'creditAndRebill'] as const,
  htmlTemplate: (id: string) =>
    [...invoiceServiceQueryKeys.base, id, 'html'] as const,
};

export const queryKeysInvoice: Required<
  Omit<ApiQueryItem, 'create' | 'delete' | 'upload'>
> = {
  list: {
    endpoint: `/api/invoices`,
    queryKey: invoiceServiceQueryKeys.invoiceList(),
    // byIdQueryKey - API response for list and detail is not the same
  },
  byId: {
    endpoint: (id: string) => `/api/invoices/${id}`,
    queryKey: (id: string) => invoiceServiceQueryKeys.invoiceDetail(id),
  },
  update: {
    endpoint: (id: string) => `/api/invoices/${id}`,
    invalidateKeys: [invoiceServiceQueryKeys.invoiceList()],
    setDataKey: (id: string) => invoiceServiceQueryKeys.invoiceDetail(id),
    skipListUpdate: true,
  },
};

export const INVOICE_SERVICE_API = asQueryUtil({
  invoices: queryKeysInvoice,
});

/**
 * Paginate to fetch all invoices
 * Since volume can be high, ensure to use filters to limit the data
 */
export function useGetAllInvoices<SelectData = InvoiceSummaryResp[]>(
  {
    config,
    filters,
    onProgress,
  }: {
    config?: GetListApiConfig;
    filters?: GetListApiFilter;
    onProgress?: (progress: number) => void;
  } = {},
  options: Partial<
    UseQueryOptions<InvoiceSummaryResp[], unknown, SelectData>
  > = {},
) {
  return useQuery<InvoiceSummaryResp[], unknown, SelectData>(
    [...invoiceServiceQueryKeys.invoiceListAll(), config, filters],
    {
      queryFn: () =>
        apiGetAllList<InvoiceSummaryResp>('/api/invoices', {
          rows: 100,
          config,
          filters,
          onProgress,
        }),
      ...options,
    },
  );
}

export function useInvoiceRevenueSchedule(
  invoiceId: string,
  options: {
    enabled?: boolean;
    onSuccess?: (data: IInvoiceRevRecognitionScheduleResponse) => void;
    onError?: (data: unknown) => void;
  } = {},
) {
  return useQuery(
    [...invoiceServiceQueryKeys.invoiceRevenueSchedule(invoiceId)],
    {
      queryFn: () =>
        apiGet<IInvoiceRevRecognitionScheduleResponse>(
          `/api/revenueRecognition/invoice/${invoiceId}`,
        ).then((res) => res.data),
      ...options,
    },
  );
}

export function useSetAddressOnInvoice(
  options: {
    onError?: (err: unknown) => void;
    onSuccess?: (data: void) => void;
  } = {},
) {
  const { onSuccess, ...restOptions } = options;
  return useMutation<
    IInvoiceAddressDataSchema,
    unknown,
    {
      invoiceId: string | undefined;
      data: IInvoiceAddressDataSchema;
    }
  >(
    ({ invoiceId, data }) =>
      apiPut<any>(`/api/invoices/${invoiceId}/addresses`, data).then(
        (res) => res.data,
      ),
    {
      onSuccess: (response) => {
        onSuccess && onSuccess();
      },
      ...restOptions,
    },
  );
}

export function useUpdateInvoiceDetails(
  options: {
    onError?: (err: unknown) => void;
    onSuccess?: (data: IInvoiceUpdateSchema) => void;
  } = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<
    IInvoiceUpdateSchema,
    unknown,
    {
      invoiceId: string;
      data: IInvoiceUpdateSchema;
    }
  >(
    ({ invoiceId, data }) =>
      apiPut<any>(`/api/invoices/${invoiceId}`, data).then((res) => res.data),
    {
      onSuccess: (response, { invoiceId }) => {
        queryClient.invalidateQueries([
          ...invoiceServiceQueryKeys.invoiceDetail(invoiceId),
        ]);

        onSuccess && onSuccess(response);
      },
      ...restOptions,
    },
  );
}

export function useCreateCreditAndRebill(
  options: {
    onError?: (err: unknown) => void;
    onSuccess?: (data: ICreditAndRebillRespSchema) => void;
  } = {},
) {
  const queryClient = useQueryClient();
  const { onSuccess, ...restOptions } = options;
  return useMutation<
    ICreditAndRebillRespSchema,
    unknown,
    {
      invoiceId: string;
      data: ICreditAndRebillReqSchema;
    }
  >(
    ({ invoiceId, data }) =>
      apiPost<ICreditAndRebillRespSchema>(
        `/api/invoices/${invoiceId}/creditAndRebill`,
        data,
      ).then((res) => res.data),
    {
      onSuccess: (response, { invoiceId }) => {
        queryClient.invalidateQueries([
          ...invoiceServiceQueryKeys.creditAndRebill(invoiceId),
        ]);

        onSuccess && onSuccess(response);
      },
      ...restOptions,
    },
  );
}

export function useCreateOneTimeInvoice(
  options: {
    onError?: (err: unknown) => void;
    onSuccess?: (data: void) => void;
  } = {},
) {
  const { onSuccess, ...restOptions } = options;
  const queryClient = useQueryClient();
  return useMutation<
    IInvoiceRespSchema,
    unknown,
    {
      billGroupId: string;
      invoice: IOneTimeInvoiceRequestSchema;
    }
  >(
    ({ billGroupId, invoice }) =>
      apiPost<any>(
        `/api/billGroups/${billGroupId}/invoices/onetime`,
        invoice,
      ).then((res) => res.data),
    {
      onSuccess: (response) => {
        queryClient.setQueryData(
          invoiceServiceQueryKeys.invoiceDetail(response.id),
          response,
        );
        onSuccess && onSuccess();
      },
      ...restOptions,
    },
  );
}

export function useGetInvoiceUnpaidInvoice(
  invoiceId: string,
  options: {
    enabled?: boolean;
    onError?: (err: unknown) => void;
    onSuccess?: (data: UnpaidInvoiceShareLink[]) => void;
  } = {},
) {
  return useQuery(
    [...invoiceServiceQueryKeys.invoiceUnpaidInvoices(invoiceId)],
    {
      queryFn: () =>
        apiGet<UnpaidInvoiceShareLink[]>(
          `/api/invoices/${invoiceId}/unpaidInvoices`,
        ).then((res) =>
          res.data.sort((a, b) =>
            sortByDate(a.invoice.dueDate, b.invoice.dueDate),
          ),
        ),
      refetchOnWindowFocus: false,
      ...options,
    },
  );
}

/**
 * Email invoices to customers for a list of provided invoices
 * onProgress is called after each invoice is processed so results can be displayed to the user
 */
export function useMassEmailCustomerInvoices(
  options: {
    onProgress?: (props: {
      result: MassEmailCustomerInvoicesResults;
      results: MassEmailCustomerInvoicesResults[];
    }) => void;
    onError?: (err: unknown) => void;
    onSuccess?: (results: MassEmailCustomerInvoicesResults[]) => void;
  } = {},
) {
  const { onProgress, ...restOptions } = options;
  return useMutation<
    MassEmailCustomerInvoicesResults[],
    unknown,
    {
      invoiceIds: string[];
      abortSignal?: AbortSignal;
    }
  >({
    mutationFn: async ({ abortSignal, invoiceIds }) => {
      const results: MassEmailCustomerInvoicesResults[] = [];
      const CONCURRENCY = 5;

      for (const invoiceIdsGroup of splitArrayToMaxSize(
        invoiceIds,
        CONCURRENCY,
      )) {
        await Promise.all(
          invoiceIdsGroup.map(async (invoiceId) => {
            const result: MassEmailCustomerInvoicesResults = {
              progress: Math.floor((results.length / invoiceIds.length) * 100),
              invoiceId,
              success: true,
            };
            if (abortSignal?.aborted) {
              result.success = false;
              result.error = 'Cancelled by user';
              return;
            }
            try {
              await apiPost(`/api/invoices/${invoiceId}/sendBillingEmail`, {
                overrideCcEmails: null,
              });
            } catch (ex) {
              if (isAxiosError(ex) && ex.response?.data.message) {
                result.error = ex.response.data.message;
              } else {
                result.error =
                  ex instanceof Error ? ex.message : JSON.stringify(ex);
              }
              result.success = false;
            }
            result.progress = Math.floor(
              (results.length / invoiceIds.length) * 100,
            );
            results.push(result);
            onProgress?.({ result, results });
          }),
        );
      }
      return results;
    },
    ...restOptions,
  });
}

export function useMassCreditInvoices(
  options: {
    onProgress?: (props: {
      result: MassCreditInvoicesResults;
      results: MassCreditInvoicesResults[];
    }) => void;
    onError?: (err: unknown) => void;
    onSuccess?: (results: MassCreditInvoicesResults[]) => void;
  } = {},
) {
  const { onProgress, ...restOptions } = options;
  return useMutation<
    MassCreditInvoicesResults[],
    unknown,
    {
      name: string;
      reason: string;
      customFields?: ICustomFieldRecordSchema;
      rows: MassCreditInvoiceRow[];
      abortSignal?: AbortSignal;
    }
  >({
    mutationFn: async ({ abortSignal, name, reason, customFields, rows }) => {
      const results: MassCreditInvoicesResults[] = [];
      const CONCURRENCY = 5;

      for (const invoiceIdsGroup of splitArrayToMaxSize(rows, CONCURRENCY)) {
        await Promise.all(
          invoiceIdsGroup.map(async (row) => {
            const result: MassCreditInvoicesResults = {
              progress: Math.floor((results.length / rows.length) * 100),
              invoiceId: row.invoiceId,
              success: true,
            };
            if (abortSignal?.aborted) {
              result.success = false;
              result.error = 'Cancelled by user';
              return;
            }
            try {
              // Create Credit
              const creditPayload: IUpsertCreditSchema = {
                billGroupId: row.billGroupId,
                status: CreditStatusEnum.ACTIVE,
                type: CreditTypeEnum.SERVICE,
                currency: row.currency,
                amount: row.amount,
                refundable: false,
                expirationDate: null,
                reason,
                name,
                customFields,
              };
              const credit = await apiPost<IGetCreditSchema>(
                `/api/accounts/${row.accountId}/billGroups/${row.billGroupId}/credits`,
                creditPayload,
              ).then((res) => res.data);

              result.creditId = credit.id;

              // Apply Credit to Invoice
              const applicationRequest: ApplyUnapplyRequest = {
                applications: [
                  {
                    amount: row.amount,
                    invoiceId: row.invoiceId,
                    type: AppTrxnType.APPLICATION,
                  },
                ],
              };
              result.allocations =
                await apiPost<AggregatedTransactablesResponse>(
                  `/api/applications/${TransactableSourceType.credit}/${credit.id}`,
                  applicationRequest,
                ).then((res) => res.data);
            } catch (ex) {
              if (isAxiosError(ex) && ex.response?.data.message) {
                result.error = ex.response.data.message;
              } else {
                result.error =
                  ex instanceof Error ? ex.message : JSON.stringify(ex);
              }
              result.success = false;
            }
            result.progress = Math.floor((results.length / rows.length) * 100);
            results.push(result);
            onProgress?.({ result, results });
          }),
        );
      }
      return results;
    },
    ...restOptions,
  });
}
